import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EmpProfileForm from "../../../components/Employer/Profile/EmpProfileForm";
import EmpProfileView from "../../../components/Employer/Profile/EmpProfileView";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import UploadImageModel from "../../../components/Common/UploadImageModel/UploadImageModel";
import {
  setLoginUserCountry,
  setLoginUserName,
} from "../../../redux/slices/AuthSlice";
import API from "../../../api";
import APIPvtDB from "../../../apiPvtD";
import Button from "react-bootstrap/Button";
import ProfileSection from "../../../components/Candidate/Profile/ProfileSection";
import FileUploadModel from "../../../components/Common/FileUploadModel/FileUploadModel";
import { COUNTRY_ISO_CODE, USER_TYPE } from "../../../constants";
import "./index.css";

const EmpProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, userCountry } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [editable, setEditable] = useState(false);
  const [companyLogoModel, setCompanyLogoModel] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");

  const [statesCount, setStatesCount] = useState(0);

  /*legel Document*/
  const [legelDocumentAvailable, setLegalDocumentAvailable] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const fileRef = useRef();

  /* Dropdown */
  const [industryOption, setIndustryOption] = useState([]);
  const [companySizeOption, setCompanySizeOption] = useState([]);

  const [baseInfo, setBaseInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryId: null,
    countryName: "",
    state: "",
    stateId: null,
    city: "",
    cityId: null,
    phone: "",
    username: "",
    percentage: null
  });

  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    description: "",
    website: "",
    linkedin: "",
    industry: "",
    industryId: null,
    companySize: "",
    companySizeId: null,
    logo: null,
  });

  const changePasswordHandler = () => {
    navigate("/new-password");
  };

  /* Event Handler*/

  const editHandler = (editValue) => {
    setEditable(editValue);
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const companyLogoModelHandler = (value) => {
    setCompanyLogoModel(value);
  };

  const companyLogoHandler = (file) => {
    setCompanyLogo(file);
  };

  /************/

  const updateLegalDocument = async (file, companyInfo) => {
    const formData = new FormData();
    formData.append("legal_document", file);
    formData.append("company_detail", companyInfo?.description);
    formData.append("first_name", baseInfo?.firstName?.trim());
    formData.append("last_name", baseInfo?.lastName?.trim());
    formData.append("website_url", companyInfo?.website);
    formData.append("id", user?.id ? user?.id : "");
    formData.append("phone", baseInfo?.phone ? baseInfo?.phone : "");
    formData.append(
      "linkedin",
      companyInfo?.linkedin ? companyInfo?.linkedin : ""
    );
    baseInfo?.stateId !== null &&
      formData.append("state_id", baseInfo?.stateId);

    baseInfo?.cityId !== null &&
      formData.append("city_id", baseInfo?.cityId ? baseInfo?.cityId : "");
    formData.append(
      "country_id",
      baseInfo?.countryId ? baseInfo.countryId : ""
    );
    formData.append("company_logo", companyInfo?.company[0]?.logo);

    API.post("/auth/update-employer-profile", formData)
      .then((res) => {
        if (res.data.result === true) {
          toast.success("Document Updated Successfully!", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
      })
      .then(() => {
        setLegalDocumentAvailable(true);
        getUserProfile();
      })
      .catch((error) => {
        toast.error(error?.message, {
          autoClose: 2000,
          closeOnClick: false,
        });
      });
  };
  const handleFileChange = async (event) => {
    const file = event;

    if (file) {
      if (file.size / 1000 <= 2048 && file.type === "application/pdf") {
        updateLegalDocument(file, companyInfo);
      } else {
        if (file.size / 1000 > 2048) {
          toast.error("File size must be less 2048 KB", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        if (file.type !== "application/pdf") {
          toast.error("Only PDF file type is supported", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
      }
    }
  };

  const fileHandler = async (file, selectedImageName) => {
    handleFileChange(file);
  };

  const handlePreviewDocument = (documentURL) => {
    const pdfUrl = documentURL;
    window.open(pdfUrl, "_blank");
  };

  const modelHandler = (toggleModel) => {
    setToggleModal(toggleModel);
  };

  /***********************************/

  const sectionEdit = (type, data) => {
    if (type === "companyInfo") {
      const userData = {
        firstName: baseInfo?.firstName || "",
        lastName: baseInfo?.lastName || "",
        phoneNo: baseInfo?.phone || "",
        countryId: baseInfo?.countryId || null,
        cityId: baseInfo?.cityId || null,
        stateId: baseInfo?.stateId || null,
        companyName: companyInfo?.name || "",
        websiteUrl: companyInfo?.website || "",
        linkedinURL: companyInfo?.linkedin || "",
        companyInfo: data || "",
        industry: companyInfo?.industry, 
        industryId: companyInfo?.industryId,
        companySize: companyInfo?.companySize,
        companySizeId: companyInfo?.companySizeId,
      };
      submitHandler(userData);
    }
  };

  const submitHandler = async (data) => {
    const {
      files,
      countryId,
      stateId,
      cityId,
      phoneNo,
      linkedinURL,
      companyInfo,
      websiteUrl,
      firstName,
      legal_document,
      lastName,
      industryId,
      companySizeId
    } = data;

    try {
      const formData = new FormData();
      formData.append("id", user?.id);
      formData.append("country_id", countryId);
      stateId !== null && formData.append("state_id", stateId);
      cityId !== null && formData.append("city_id", cityId);
      websiteUrl !== "" && formData.append("website_url", websiteUrl);
      phoneNo !== "" && formData.append("phone", phoneNo);
      linkedinURL !== "" && formData.append("linkedin", linkedinURL);
      companyInfo !== "" && formData.append("company_detail", companyInfo);
      formData.append("first_name", firstName?.trim());
      formData.append("last_name", lastName?.trim());
      files !== null &&
        files !== undefined &&
        formData.append("company_logo", files);
      legal_document !== null &&
        files !== legal_document &&
        formData.append("legal_document", legal_document);
      setLoader(true);
      // console.log(user.user_type=== 'company_user');

      let updateUserApiURL = '/auth/update-employer-profile'
      let apiType = API

      if(user?.user_type === USER_TYPE.EMPLOYER){
        industryId !== null && formData.append("industry_id", industryId);    
        companySizeId !== null && formData.append("company_size_id", companySizeId);                
      }
      if (user?.user_type === USER_TYPE.COMPANY_USER) {
        updateUserApiURL = 'auth/update-company-user-profile'
        apiType = APIPvtDB;
        files !== null &&
          files !== undefined &&
          formData.append("avatar", files);
        formData.delete('website_url')
        formData.delete('company_logo')
        formData.delete('legal_document')
      }

      await apiType.post(updateUserApiURL, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;

            if (res?.result === true) {
              getUserProfile();
              editHandler(false);
              setErrorMsg(res?.message?.success);
            }        
            else if((res?.result === false)){
              const errors = res?.message;
              const firstErrorKey = Object?.keys(errors)[0];
              const firstErrorMessage = errors[firstErrorKey][0];
              toast.error(firstErrorMessage, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            setLoader(false);
          }          
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getUserProfile = async () => {
    if (user?.id) {
      try {
        await APIPvtDB.get(`/auth/get-profile`)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              console.log(response?.data);
              let res = response?.data;
              let company = response?.data?.company;
              const { city, state, userCountry } = res;
              const { masked_phone_number, placeholder, states_count } =
                userCountry;

                // Filter the industry and company size
              const industry = res?.industries?.find(ind => ind?.id === company[0]?.industry_id);
              const companySize = res?.company_sizes?.find(size => size?.id === company[0]?.company_size_id);

              dispatch(setLoginUserName(res?.first_name));
              dispatch(
                setLoginUserCountry({
                  id: userCountry?.id,
                  name: userCountry?.name,
                })
              );
              setBaseInfo({
                ...baseInfo,
                email: res?.email,
                phone: res?.phone,
                countryId: res?.userCountry?.id,
                countryName: res?.userCountry?.name,
                state: state ? state?.name : "",
                stateId: state ? state?.id : null,
                city: city ? city?.name : "",
                cityId: city ? city?.id : null,
                username: `${res?.first_name} ${res?.last_name}`,
                firstName: res?.first_name !== null && res?.first_name,
                lastName: res?.last_name !== null && res?.last_name,
                id: res?.id,
                percentage: res?.percentage
              });

              setCompanyInfo({
                name: company[0]?.name,
                description: company[0]?.description,
                website: company[0]?.website,
                linkedin: res?.linkedin,
                logo: user?.user_type === USER_TYPE.COMPANY_USER ? response.data?.avatar : company[0]?.logo,
                industry: industry ? industry.name : '',
                industryId: res?.industry_id,
                companySize: companySize ? companySize.name : '',
                companySizeId: res?.company_size_id,
                company: company,
              });

              
              setMaskedPlaceHolder(placeholder);
              setMaskedPhoneNumber(masked_phone_number);
              setStatesCount(states_count);
              setIndustryOption(res?.industries);
              setCompanySizeOption(res?.company_sizes);

              if (company[0]?.description !== null)
                setLegalDocumentAvailable(true);
              else setLegalDocumentAvailable(false);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const goBack = () => {
    if (editable) {
      editHandler(false);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <section className="profile-edit pt-0">
        <div className="container container-site py-2">
          <Button
            variant="link"
            className="back-to-home add top-0 position-relative"
            onClick={() => goBack()}
          >
            <img src="/image/form-back-arrow.png" alt="" />
          </Button>
        </div>
        <div className="job-heading">
          <div className="container-site">
            <h2>Company profile</h2>
          </div>
        </div>
      </section>
      <section className="candidate-profile-sec">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="profile-card">
              {editable === false && (
                <button
                  className="btn btn-transparent edit-btn-profile"
                  onClick={() => editHandler(true)}
                >
                  <img src="/image/pen.png" alt="" />
                </button>
              )}

              <div className="row">
                <div className="col-md-9">
                  {editable ? (
                    <EmpProfileForm
                      loader={loader}
                      editable={editable}
                      baseInfo={baseInfo}
                      companyInfo={companyInfo}
                      companyLogo={companyLogo}
                      maskedPlaceHolderP={maskedPlaceHolder}
                      maskedPhoneNumberP={maskedPhoneNumber}
                      statesCount={statesCount}
                      industryOption={industryOption}
                      companySizeOption={companySizeOption}
                      editHandler={editHandler}
                      companyLogoModelHandler={companyLogoModelHandler}
                      submitHandler={submitHandler}
                    />
                  ) : (
                    <EmpProfileView
                      getUserProfile={getUserProfile}
                      baseInfo={baseInfo}
                      companyInfo={companyInfo}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  {!editable ? (
                    <div className="d-flex gap-2 flex-wrap pt-5 profile-action-btn">
                      {userCountry?.name !== COUNTRY_ISO_CODE.CA && (
                        <>
                          {!legelDocumentAvailable ? (
                            <>
                              <button
                                className="btn btn-outline-black w-100 p-0"
                                onClick={() => modelHandler(true)}
                                style={{ maxHeight: 50 }}
                              >
                                Upload Status Document
                                {/* <img src="/image/toast-icon.png" alt="toast-icon" /> */}
                              </button>
                            </>
                          ) : (
                            <></>
                          )}

                          {legelDocumentAvailable ? (
                            <>
                              <button
                                className="btn btn-outline-black w-100 p-0"
                                onClick={() =>
                                  handlePreviewDocument(
                                    companyInfo?.company[0].legal_document
                                  )
                                }
                                style={{ maxHeight: 50 }}
                              >
                                View Document
                              </button>

                              <button
                                className="btn btn-outline-black w-100 p-0"
                                onClick={() => modelHandler(true)}
                                style={{ maxHeight: 50 }}
                              >
                                Update Document
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}

                      <button
                        className="btn btn-outline-black w-100 p-0"
                        onClick={() => changePasswordHandler()}
                        style={{ maxHeight: 50 }}
                      >
                        Change Password
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {!editable && (
              <>
                <div className="profile-card">
                  <ProfileSection
                    sectionType="companyInfo"
                    editMethod={sectionEdit}
                    heading="Company Information"
                    description={companyInfo.description}
                    defaultText="This section provides an opportunity to share more about company."
                    tooltip="This section provides an opportunity to share more about company."
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      {companyLogoModel && (
        <UploadImageModel
          companyLogoHandler={companyLogoHandler}
          companyLogoModelHandler={companyLogoModelHandler}
        />
      )}

      {toggleModal && (
        <FileUploadModel
          modelHandler={modelHandler}
          resumeFileHandler={fileHandler}
          title="Upload Document"
        />
      )}

      <WorldConnect />
    </>
  );
};

export default EmpProfile;

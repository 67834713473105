import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setResumeFormMode } from "../../../../redux/slices/verifyResumeSlice";
import WorldConnect from "../../../../components/Common/WorldConnect/WorldConnect";
import { RESUME_MODE } from "../../../../constants";
import "./index.css";

const CanResumeDecision = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resumes } = useSelector((state) => state.auth);

  const onCreateResumeHandler = () => {
    if (resumes?.length > 4) {
      toast.warning(
        "The maximum number of resume allowed in the system is 5. Please delete an existing resume to continue.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    } else {
      dispatch(setResumeFormMode(RESUME_MODE.CREATE));
      navigate("/candidate-resume");
    }
  };

  const onCustomResumeHandler = () => {
    if (resumes?.length > 4) {
      toast.warning(
        "The maximum number of resume allowed in the system is 5. Please delete an existing resume to continue.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    } else {
      navigate("/candidate-resume-job-form");
    }
  };

  return (
    <>
      <section className="journey-sec pt-0">
        <div className="decision-banner px-md-0 px-3">
          <div className="container container-site">
            <div className="row align-items-center">
              <div className="col-md-8 col-12">
                <div className="d-ban-content pe-md-5">
                  <p className="text-pacific fw-700 mb-3">
                    Poly AI Powered Resume Tools
                  </p>
                  <h2 className="mb-2">
                    Build a professional resume for free.
                  </h2>
                  <p>
                    Let our AI Powered Resume Tools refine your existing resume
                    or tailor it to any job description effortlessly. Bid
                    farewell to generic resumes and welcome personalized success
                    - all at no cost.
                  </p>

                  <div className="d-flex flex-wrap gap-3 mt-md-5 mt-3 py-md-0 py-5">
                    <div
                      onClick={() => onCreateResumeHandler()}
                      className="btn btn-lg btn-outline-black"
                    >
                      Create resume
                    </div>
                    <div
                      onClick={() => onCustomResumeHandler()}
                      className="btn btn-lg btn-black"
                    >
                      Customize my resume
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-md-block d-none">
                <div className="d-ban-img">
                  <img
                    src="/image/decision-banner.png"
                    alt="decision-banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WorldConnect />
    </>
  );
};

export default CanResumeDecision;

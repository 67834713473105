import { Fragment } from "react";
import { useSelector } from "react-redux";

const JobAdCard = ({ index, occupiedPosition, job, selectedPosition, onClick }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="avaliable-job-cards-row">
      {occupiedPosition?.some(
        (occupied) =>
          occupied?.position_number === job?.position &&
          occupied?.section_id === job?.section_id
      ) ? (
        <div className={`card-slot-bg-dark-card-job`}>
          <div className="first-row-full">
            <img src="/image/wor-img-two.png" alt="" />
            <p className="job-postion-avalibe-full">Position {index + 1}</p>
            <p className="job-postion-avalibe-salary">
              {job?.country?.currency?.title}{job?.price}
            </p>
          </div>
          <div className="jobh-slot-title-logo-company">
            <p className="job-title-promtion">Job Title</p>
            <div className="com-logo-jd-prom">
              <p className="logo-card-avaliable-full">Logo</p>
              <div className="com-jd">
                <p className="slot-name-card-avalibe">{user?.company_name ? user?.company_name  : "Company Name"}</p>
              </div>
            </div>
          </div>
          <div className="job-slot-salary-company">
            <p className="job-title-salary">Salary range</p>
            <p claclassNamess="slot-name-card-work">Work preference</p>
          </div>
          <p class="full-content-c">
            We are seeking a highly experienced and dynamic Java Developer to
            join our team. As a Senior Java Developer, you will be responsible
            for designing, developing, and maintaining high-quality software
            solutions. Your expertise in Java programming, st...
          </p>
        </div>
      ) : (
        <div
          className={`card-slot-bg-clr-job ${
            job["position"] === selectedPosition?.position &&
            job["section_id"] === selectedPosition?.section_id
              ? "active-boder-clr"
              : ""
          }`}
        >
          <div className="first-row-full">
            <img src="/image/row-card-img.png" alt="" />
            <p className="job-postion-avalibe-full">Position {index + 1}</p>
            <p className="job-postion-avalibe-salary">
              {job?.country?.currency?.title}{job?.price}
            </p>
          </div>
          <div className="jobh-slot-title-logo-company">
            <p className="job-title-promtion">Job Title</p>
            <div className="com-logo-jd-prom">
              <p className="logo-card-avaliable-full">Logo</p>
              <div className="com-jd">
                <p className="slot-name-card-avalibe">{user?.company_name ? user?.company_name  : "Company Name"}</p>
              </div>
            </div>
          </div>
          <div className="job-slot-salary-company">
            <p className="job-title-salary">Salary range</p>
            <p className="slot-name-card-work">Work preference</p>
          </div>
          <p className="full-content-c">
            We are seeking a highly experienced and dynamic Java Developer to
            join our team. As a Senior Java Developer, you will be responsible
            for designing, developing, and maintaining high-quality software
            solutions. Your expertise in Java programming, st...
          </p>
          <div className="radio-crd-btn">
            {" "}
            <input
              type="radio"
              name=""
              id="selct-btn-c"
              checked={
                job["position"] === selectedPosition?.position &&
                job["section_id"] === selectedPosition?.section_id
              }
              onClick={() => {
                onClick(job);
                document.querySelector(".feild-f-admin").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            />{" "}
          </div>
        </div>
      )}
    </div>
  );
};

const CompanySlotCard = ({
  allPosition,
  selectedAllPosition,
  occupiedPosition,
  onCompanyCardAddClick,
  isJobAd,
  showVertical,
  selectionKey,
  disableAll,
}) => {
  const { user } = useSelector((state) => state.auth);
  return !selectionKey ? (
    isJobAd ? (
      allPosition?.map((job, index) => (
        <div className="mb-3" key={job?.id}>
          <JobAdCard
            index={index}
            job={job}
            occupiedPosition={occupiedPosition}
            onClick={onCompanyCardAddClick}
            selectedPosition={selectedAllPosition}
          />
        </div>
      ))
    ) : (
      <div
        className={`avaliable-post-cards-row ${
          showVertical ? " d-flex flex-column" : ""
        }`}
      >
        {allPosition?.map((item, index) =>
          occupiedPosition?.some(
            (occupied) =>
              occupied?.position_number === item?.position &&
              occupied?.section_id === item?.section_id
          ) ? (
            <Fragment key={index}>
              <div className="card-slot-bg-clr active-color-card">
                <div className="first-row-l-c">
                  <div className="logo-postion-promotion">
                    <p className="job-postion-avalibe">
                      {" "}
                      {`Position ${index + 1}`}
                    </p>
                    <p className="logo-card-avaliable">
                      {item?.country?.currency?.title}{item?.price}
                    </p>
                  </div>
                </div>
                <div className="cross-company">
                  <img src="/image/not-selct-b.png" alt="" />
                </div>
                <p className="c-name-card-avalibe">{user?.company_name ? user?.company_name  : "Company Name"}</p>
                <p className="c-content-c">
                  Your company description goes here
                </p>
              </div>
            </Fragment>
          ) : (
            <Fragment key={index}>
              <div
                className={`card-slot-bg-dark-card ${
                  item["position"] === selectedAllPosition?.position &&
                  item["section_id"] === selectedAllPosition?.section_id &&
                  disableAll === false
                    ? "active-boder-clr"
                    : ""
                }`}
              >
                <div className="first-row-l-c">
                  <div className="logo-postion-promotion">
                    <p className="job-postion-avalibe">{`Position ${
                      index + 1
                    }`}</p>
                    {disableAll === false && (
                      <p className="logo-card-avaliable">
                        {item?.country?.currency?.title}{item?.price}
                      </p>
                    )}
                  </div>
                </div>
                <div className="cross-company">
                  <img src="/image/office-building-slot.png" alt="" />
                </div>
                <p className="c-name-card-avalibe">{user?.company_name ? user?.company_name : "Company Name"}</p>
                <p className="c-content-c">
                  Your company description goes here
                </p>
                {disableAll === false && (
                  <div className="radio-crd-btn">
                    {" "}
                    <input
                      type="radio"
                      name=""
                      id="selct-btn-c"
                      onClick={() => {
                        onCompanyCardAddClick(item);
                        document
                          .querySelector(".feild-f-admin")
                          .scrollIntoView({
                            behavior: "smooth",
                          });
                      }}
                      checked={
                        item["position"] === selectedAllPosition?.position &&
                        item["section_id"] === selectedAllPosition?.section_id
                      }
                    />{" "}
                  </div>
                )}
              </div>
            </Fragment>
          )
        )}
      </div>
    )
  ) : (
    <div
      className={`avaliable-post-cards-left ${
        showVertical ? " d-flex flex-column" : ""
      }`}
    >
      {allPosition?.map((item, index) =>
        occupiedPosition?.some(
          (occupied) =>
            occupied?.position_number === item?.position &&
            occupied?.section_id === item?.section_id
        ) ? (
          <Fragment key={index}>
            <div className="card-slot-bg-clr active-color-card-left">
              <div className="first-row-l-c">
                <div className="logo-postion-promotion-left">
                  <p className="logo-card-avaliable">
                    {" "}
                    {item?.country?.currency?.title}{item?.price}
                  </p>
                </div>
              </div>
              <div className="cross-company-left">
                <img src="/image/not-select-prom.png" alt="" />
                <p className="job-postion-avalibe">
                  {" "}
                  {`Position ${index + 1}`}
                </p>
              </div>
              <p className="c-name-card-left">Job Title</p>
              <p className="c-name-card-left">Job Type</p>
              <p className="c-content-c-left">
                We are seeking a highly experienced and dynamic Java Develope.
              </p>
            </div>
          </Fragment>
        ) : (
          <Fragment key={index}>
            <div
              className={`card-slot-bg-dark-card-left ${
                item["position"] === selectedAllPosition?.position &&
                item["section_id"] === selectedAllPosition?.section_id &&
                disableAll === false
                  ? "active-boder-clr"
                  : ""
              }`}
            >
              <div className="first-row-l-c">
                <div className="logo-postion-promotion-left">
                  <p className="logo-card-avaliable">
                    {" "}
                    {item?.country?.currency?.title}{item?.price}
                  </p>
                </div>
              </div>
              <div className="cross-company-left">
                <img src="/image/job-promotion-left.png" alt="" />
                <p className="job-postion-avalibe">{`Position ${
                  index + 1
                }`}</p>
              </div>
              <p className="c-name-card-left">Job Title</p>
              <p className="c-name-card-left">Job Type</p>
              <p className="c-content-c-left">
                We are seeking a highly experienced and dynamic Java Develope.
              </p>

              {disableAll === false && (
                <div className="radio-crd-btn">
                  {" "}
                  <input
                    type="radio"
                    name=""
                    id="selct-btn-c"
                    onClick={() => {
                      onCompanyCardAddClick(item);
                      document.querySelector(".feild-f-admin").scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                    checked={
                      item["position"] === selectedAllPosition?.position &&
                      item["section_id"] === selectedAllPosition?.section_id
                    }
                  />
                </div>
              )}
            </div>
          </Fragment>
        )
      )}
    </div>
  );
};

export default CompanySlotCard;

import React, { useEffect, useState } from 'react'
import ContentModal from '../../../../components/Common/ContentModal/ContentModal'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import OutsideClickHandler from 'react-outside-click-handler';
import API from '../../../../api';
import APIPvtDB from '../../../../apiPvtD';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import ActionModal from '../../../../components/Common/ActionModal/ActionModal';

const StagesWizard = ({data, invokeGetListing, isAdmin=false}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [stagesInt, setStagesInt] = useState([]);
  const [systemUser, setSystemUser] = useState([]);
  const [nonSystemUsers, setNonSystemUsers] = useState([]);
  const [newStages, setNewStages] = useState([]);
  const [newStagesValue, setNewStagesValue] = useState('');
  const [customStage, setCustomStage] = useState(false);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [showMenu, setShowMenu] = useState('');
  const [backupIntData, setBackupIntData] = useState('');

  useEffect(() => {
    if(data) {
      const systemUser = data.filter(item => item.is_system === 1)
      const nonSystemUser = data.filter(item => item.is_system === 0)

      setSystemUser(systemUser)
      setNonSystemUsers(nonSystemUser)
    }
  }, [data])

  const handleStage = (val) => {
    setStagesInt(prevState => {
      const index = prevState.findIndex((item) => item.id === val.id);
      if (index !== -1) {
        const newArray = [...prevState];
        newArray.splice(index, 1);
        return newArray
      } else {
        return [...prevState, val]
      }
    })
  }

  const submitDataHandler = async () => {
    let updatedItems = stagesInt.map(item => ({
      id: Number(item.id),
      label: item.name,
      icon_path: item.icon_path,
    }))

    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('job_id');

    let formData = {
      response: {
        interview_steps: updatedItems
      },
      "job_id": jobId ? jobId : localStorage.getItem('job-id'),
      "user_id": user.id
    }

    try {
      await API.post(`/job-board/save-job-interview-steps`, formData).then((res) => {
        if(res.data.result) {
          toast.success(res?.data.message.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          if(isAdmin) {
            navigate(-1);
          } else {
            navigate('/employer-dashboard');
          }
          localStorage.removeItem('job-id');
        }
      })
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }

  }

  const saveNewStage = async() => {
    if (newStagesValue !== '') {
      let formData = {
        user_id: user.id,
        step_name: newStagesValue
      };
      try {
        await API.post(`/job-board/add-interview-step`, formData).then((res) => {
          if(res.data.result) {
            customStepHandler()
            toast.success(res.data.message.success, {
              autoClose: 2000,
              closeOnClick: false,
            });
            if(invokeGetListing){
              invokeGetListing()
            }
          }
        })
      } catch (error) {
        if (error) {
          toast.error(error.response.data.message.error, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        }
      }
    }
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(stagesInt);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setStagesInt(items);
  };

  const customStepHandler = () => {
    setCustomStage(!customStage);
  }

  const showSubMenu = (data) => {
    setShowMenu(data)
  };

  const editIntStep = (data, e) => {
    e.stopPropagation()
    setBackupIntData(data)
    setCustomStage(!customStage)
  }

  const delIntStep = (data, e) => {
    // e.stopPropagation()
    setBackupIntData(data)
    setDeleteAllModal(!deleteAllModal)
  }

  const closeModal = () => {
    setDeleteAllModal(false);
  };


  const saveUpdatedStage = async() => {
    if (newStagesValue !== '') {
      let formData = {
        user_id: user.id,
        step_name: newStagesValue,
        interview_step_id: backupIntData.id
      };
      try {
        await API.post(`/job-board/update-interview-step`, formData).then((res) => {
          if(res.data.result) {
            customStepHandler()
            toast.success(res.data.message.success, {
              autoClose: 2000,
              closeOnClick: false,
            });
            if(invokeGetListing) {
              invokeGetListing()
              setBackupIntData('')
            }
          }
        })
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    }
  }
  
  const handleDeleteStep = async() => {
    if (backupIntData !== '') {
      let formData = {
        interview_step_id: backupIntData.id
      };
      try {
        await API.post(`/job-board/delete-interview-step`, formData).then((res) => {
          if(res.data.result) {
            toast.success(res.data.message.success, {
              autoClose: 2000,
              closeOnClick: false,
            });
            setDeleteAllModal(false);
            setBackupIntData('')
            if(stagesInt.length > 0){
              handleStage(backupIntData)
            }
            if(invokeGetListing) {
              invokeGetListing()
            }
          }
        })
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    }
  }

  return (
    <>
      <div className='steps-body'>
        <div className='interview-options'>
          <div className="option-box">
            {
              systemUser?.map((val, key) => {
                return (
                  <div className="checkbox" key={key}>
                    <label className="checkbox-wrapper d-block" for={val.id}>
                      <input type="checkbox" className="checkbox-input" id={val.id} onChange={() => handleStage(val)} />
                      <span className="checkbox-tile">
                        <span className="checkbox-icon">
                          <img src={val.icon_path} alt='icon' />
                        </span>
                        <span className="checkbox-label">{val.name}</span>
                      </span>
                    </label>
                  </div>
                )
              })
            }
            <div className="checkbox">
              <div className="checkbox-wrapper d-block" onClick={customStepHandler}>
                <span className="checkbox-tile">
                  <span className="checkbox-icon">
                    <img src='/image/custom-interview-icon.png' alt='icon' />
                  </span>
                  <span className="checkbox-label">Custom step</span>
                </span>
              </div>
            </div>
            {
              nonSystemUsers.length > 0 && (
                <>
                  {nonSystemUsers.map((val, key) => {
                    return (
                      <div className="checkbox" key={key}>
                        <label className="checkbox-wrapper d-block">
                          <input type="checkbox" className="checkbox-input" id={val.id} onChange={() => handleStage(val)} />
                          <span className="checkbox-tile">
                            <div className='dd-listitem'>
                              <button className="btn p-0" onClick={() => showSubMenu(val.id)}>
                                <img src="/image/ellipses.png" alt="éllipses" />
                              </button>
                              <div className={showMenu === val.id  ? "cv-cions active" : "cv-cions"}>
                                <OutsideClickHandler
                                  onOutsideClick={() => {
                                    setShowMenu('');
                                  }}
                                >
                                  <ul>
                                    <li className='flex-between-center' onClick={(e) => {e.preventDefault();editIntStep(val, e)}}>
                                      <span>Edit</span>
                                      <img src='/image/edit-black.png' />
                                    </li>
                                    <li className='flex-between-center' onClick={(e) => {e.preventDefault();delIntStep(val, e)}}>
                                      <span>Delete</span>
                                      <img src='/image/bin.png' />
                                    </li>
                                  </ul>
                                </OutsideClickHandler>
                              </div>
                            </div>
                            <span className="checkbox-icon">
                              <img src={val.icon_path} alt='icon' />
                            </span>
                            <span className="checkbox-label">{val.name}</span>
                          </span>
                        </label>
                      </div>
                    )
                  })
                  }
                </>
              )
            }
          </div>
        </div>
        <div className='right-container'>
          <div className='interview-steps-maker'>
            <div className='steper-header mb-3'>
              <p>Create interview steps</p>
            </div>
            {
              stagesInt.length < 1 ? <p className='bb text-center mt-5'>Currently no stage selected</p> : <div className='task-list'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="cards">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {stagesInt.map((card, index) => (
                          <Draggable key={card.id} draggableId={card.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div key={index} className='process-flow'>
                                  <img src='/image/verified-icon.png' alt='icon' />
                                  <p> Step {index + 1}: {card.name}</p>
                                  <img className='stroke' src='/image/drag-stroke.png' alt='icon' />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            }
          </div>
          <div className='btn-stages'>
            <button className='btn btn-outline ms-2' onClick={()=> isAdmin ? navigate(-1) : navigate('/employer-dashboard')}>Cancel</button>
            <button className='btn btn-black ms-2' onClick={submitDataHandler}>Submit</button>
          </div>
        </div>

      </div>
      {customStage && (
        <ContentModal closeModal={() => setCustomStage(!customStage)}>
          <div>
            <div className="modal-body">
              <h3 className="mt-0"> {backupIntData ? 'Edit Custom Step' : 'Add custom step'}</h3>
              <div className="crea-list-m text-start w-100">
                <div className="label-field">
                  <label for="">Step name </label>
                  <div className="share-list-feild">
                    <div
                      id="dropdown-boxId2"
                      className="company-form-dropdownBox1 custom-tag-input"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-filter-admin">
                        <input
                          type="text "
                          className="form-font-list-share"
                          name="campanyUser"
                          autoComplete='off'
                          defaultValue={backupIntData.name}
                          onChange={(e) => {
                            setNewStagesValue(e.target.value)
                          }}
                          placeholder="Step name"
                        />

                        <img
                          src="/image/admin-d.png"
                          alt=""
                          className="hide-tick"
                        />
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-black" onClick={() => setCustomStage(!customStage)}>
                Cancel
              </button>
              <button className="btn btn-outline-black" onClick={backupIntData ? saveUpdatedStage : saveNewStage}>
                Edit & save
              </button>
            </div>
          </div>
        </ContentModal>
      )}
      {deleteAllModal && (
        <>
          <ActionModal
            closeModal={closeModal}
            modalArt='/image/warning-wishlist.png'
            heading='Double-Check'
            content='Are you sure you want to delete?'>
            <button className="btn btn-black" onClick={() =>setDeleteAllModal(!deleteAllModal) }>No, keep it.</button>
            <button className="btn btn-outline-black" onClick={handleDeleteStep}>Yes, delete it.</button>
          </ActionModal>
        </>
      ) }
    </>
  )
}

export default StagesWizard
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  setLoginModel,
  setPostJobId,
  setPostJobMode,
} from "../../../redux/slices/AuthSlice";
import { ADMIN, CANDIDATE, COUNTRY_ISO_CODE } from "../../../constants";
import "./Banner.css";

const Banner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countryISOCode } = useSelector((state) => state.home);

  const scroll = "search";
  const { isEmailVerify, user } = useSelector(
    (state) => state.auth
  );

  const postJobClickHanlder = async () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(setActiveTab("employer"));
      dispatch(setPostJobMode("add"));
      dispatch(setPostJobId(null));
      navigate("/post-job");
    }
  };

  const onCustomResumeHandler = () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      navigate("/candidate-resume-decision");
    }
  };

  return (
    <>
      <section className="banner-top container-site">
        <div className="banner-row-poly">
          <div className="banner-heading-poly">
            <h1>
              Your search starts <span> here.</span>
            </h1>
            <div className="two-btn-poly">
              {!ADMIN.includes(user?.user_type) && (
                <Link
                  to={`/job-search/${scroll}`}
                >
                  <button className="find-job-poly">Find a job</button>
                </Link>
              )}
              {!CANDIDATE.includes(user?.user_type) && (
                <button
                  className="post-job-poly"
                  onClick={() => postJobClickHanlder()}
                >
                  Post a job
                </button>
              )}
            </div>
            <div class="banner-img">
              <img
                src={
                  countryISOCode === COUNTRY_ISO_CODE.CA
                    ? "/image/banner-poly-CA.png"
                    : "/image/banner-poly-MK.png"
                }
                lt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

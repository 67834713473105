import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ConversationCard from "../../../components/Common/Inbox/ConversationCard/ConversationCard";
import InboxRightSide from "../../../components/Common/Inbox/InboxRightSide/InboxRightSide";
import StartConversationModel from "../../../components/Common/Inbox/StartConversationModel/StartConversationModel";
import AnyFileUploadModel from "../../../components/Common/AnyFileUploadModel/AnyFileUploadModel";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import {
  deleteInboxConversationDataAsync,
  getInboxAllUserAsync,
  getInvitesInboxDataAsync,
  markAsReadInboxDataAsync,
  pinUnpinInboxDataAsync,
  setInboxUser,
} from "../../../redux/slices/InboxSlice";
import { setInboxCount } from "../../../redux/slices/AuthSlice";
import { CONVERSATION_STATUS, EMPLOYER, USER_TYPE } from "../../../constants";
import "./index.css";


const CandEmpInbox = () => {
  const dispatch = useDispatch();
  const { user, inboxCount } = useSelector((state) => state.auth);
  const selectedInboxUser = useSelector(
    (state) => state.Inbox?.selectedInboxUser || {}
  );

  const userTypeEmp = EMPLOYER.includes(user?.user_type);

  const [fileUpload, setFileUpload] = useState(null);
  const [toggleFileModel, setToggleFileModel] = useState(false);
  const [toggleInviteModal, setToggleInviteModal] = useState(false);
  const [mobileChat, setMobileChat] = useState(false);
  const [allMessageUser, setAllMessageUser] = useState([]);

  /* Filter */
  const [candidateNameSearch, setCandidateNameSearch] = useState("");
  const [jobTitleDropDown, setJobTitleDropDown] = useState([]);
  const [conversationStatus, setConversationStatus] = useState("");

  const [selectedJobTitle, setSelectedJobTitle] = useState({
    id: null,
    name: "",
  });

  /****/

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const messageData = {
    senderName: "John",
    avatarUrl: "/image/msg-avatar.png",
    message: `Hi James,
      Hope you're doing great. We are looking for a Senior React Developer and thought you might be interested. If this aligns with your skills and career goals, please let us know. We'd love to discuss this opportunity with you.
      
      Best regards,
      Mathews
    `,
    timeStamp: "08:39pm",
  };

  const modalRef = useRef(null);

  /* Event Handler*/

  const scrollToBottom = () => {
    if (modalRef.current) {
      modalRef.current.scrollTop = modalRef.current.scrollHeight;
    }
  };

  const populateChat = () => {
    const { innerWidth: width } = window;
    if (width < 767) {
      setMobileChat(!mobileChat);
    }
  };

  const onselectMessageUser = (prod) => {
    dispatch(setInboxUser(prod));
  };

  const onSearchChange = (value) => {
    setCandidateNameSearch(value);
  };

  const onJobTitleClick = (item) => {
    const { id, full_title } = item;
    setSelectedJobTitle({
      id: id,
      name: full_title,
    });
  };

  const onJobTitleClean = () => {
    setSelectedJobTitle({
      id: null,
      name: "",
    });
  };

  const inviteModelHandler = (toggleModel) => {
    setToggleInviteModal(toggleModel);
  };

  const fileUploadHandler = (v) => setToggleFileModel(v);

  const pinUnpinClickHandler = async (id) => {
    if (id) {
      dispatch(pinUnpinInboxDataAsync({ conversation_id: id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            getInboxAllUser();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onDeleteInboxConClickHandler = async (prod) => {
    if (selectedInboxUser && prod) {
      dispatch(deleteInboxConversationDataAsync({ conversation_id: prod?.id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            if (selectedInboxUser?.id === prod?.id) {
              dispatch(setInboxUser(null));
            }
            getInboxAllUser();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onMaskReadClickHandler = (prod) => {
    if (prod?.id && prod?.reads?.length === 0) {
      dispatch(markAsReadInboxDataAsync({ conversation_id: prod?.id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            const currentInboxCount = inboxCount;
            if (currentInboxCount > 0) {
              dispatch(setInboxCount(currentInboxCount - 1));
            }
            getInboxAllUser();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const resetFile = () => {
    setFileUpload(null);
  };

  const fileHandler = (files, selectedFileName) => {
    setFileUpload(files);
  };

  const getInvitesData = async () => {
    dispatch(getInvitesInboxDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setJobTitleDropDown(res?.data?.jobs);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInboxAllUser = async () => {
    if (user?.id) {
      try {
        dispatch(
          getInboxAllUserAsync({
            userId: user?.id,
            search: candidateNameSearch,
            job_id: selectedJobTitle?.id,
            status: conversationStatus,
          })
        )
          .then((response) => {
            const res = response?.payload;
            if (res?.success === true && res?.data) {
              setAllMessageUser(res?.data);
              if (
                res?.data?.length > 0 &&
                Object.keys(selectedInboxUser)?.length === 0
              ) {
                dispatch(setInboxUser(res?.data[0]));
                onMaskReadClickHandler(res?.data[0]);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (
      candidateNameSearch !== "" ||
      conversationStatus !== "" ||
      selectedJobTitle?.id !== null
    )
      getInboxAllUser();
    else {
      getInboxAllUser();
    }
  }, [candidateNameSearch, conversationStatus, selectedJobTitle]);

  useEffect(() => {
    if (userTypeEmp) {
      getInvitesData();
    }
  }, []);

  return (
    <>
      <section className="profile-edit pt-0">
        <div className="container container-site py-2">
          <Button
            variant="link"
            className="back-to-home add top-0 position-relative"
            onClick={() => goBack()}
          >
            <img src="/image/form-back-arrow.png" alt="" />
          </Button>
        </div>
        <div className="job-heading">
          <div className="container-site">
            <h2>Inbox</h2>
          </div>
        </div>
      </section>
      <main className="container">
        <div className="chat-wrapper">
          <div
            className={`chat-header chat-grid ${mobileChat ? "d-none" : ""}`}
          >
            <div className="listing-wrap">
              <input
                type="text"
                placeholder="Search"
                className="p-2 rounded"
                style={{ border: "0.5px solid #2B2B2B4D", width: "93%" }}
                onChange={(e) => setCandidateNameSearch(e?.target?.value)}
              />
            </div>
            <div className="msg-wrap btns-group">
              <div className="right-btn-chat">
                <button
                  className={`btn btn-outline-black ${
                    conversationStatus === CONVERSATION_STATUS.RECENT
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    setConversationStatus(CONVERSATION_STATUS.RECENT)
                  }
                >
                  Recent
                </button>
                <button
                  className={`btn btn-outline-black ${
                    conversationStatus === CONVERSATION_STATUS.UNREAD
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    setConversationStatus(CONVERSATION_STATUS.UNREAD)
                  }
                >
                  Unread
                </button>
                <button
                  className={`btn btn-outline-black ${
                    conversationStatus === CONVERSATION_STATUS.PINNED
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    setConversationStatus(CONVERSATION_STATUS.PINNED)
                  }
                >
                  Pinned
                </button>
                {userTypeEmp && (
                  <div className="f-admin feild-f-admin field-f-inbox">
                    <div className={`invite-pople-list`}>
                      <div
                        id="dropdown-boxId2"
                        className="company-form-dropdownBox1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="text-filter-admin">
                          <p className="text-filter-select">
                            {selectedJobTitle?.name === ""
                              ? "Job title"
                              : selectedJobTitle?.name?.length >= 30
                              ? selectedJobTitle?.name?.substr(0, 30) + " ..."
                              : selectedJobTitle?.name}
                          </p>
                          {selectedJobTitle?.name === "" ? (
                            <img src="/image/cand-vactor.png" alt="" />
                          ) : (
                            <div
                              id="dropdown-boxId2"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img
                                src="/image/cross-b.png"
                                alt=""
                                onClick={() => onJobTitleClean()}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {jobTitleDropDown?.length > 0 && (
                        <div className="admin-status-dropdown-frm">
                          <ul className="list-job-title-company">
                            {jobTitleDropDown?.map((j, i) => (
                              <li
                                key={i}
                                className="icon-cursor job-title-size"
                                onClick={() => {
                                  onJobTitleClick(j);
                                }}
                              >
                                {j?.full_title}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {user?.user_type === USER_TYPE.EMPLOYER && (
                <div
                  className="add-pople-icon icon-cursor"
                  onClick={() => inviteModelHandler(true)}
                >
                  <img src="/image/user-plus.png" alt="" />
                </div>
              )}
            </div>
          </div>
          <div className="chat-body chat-grid">
            <div className={`listing-wrap ${mobileChat ? "d-none" : ""}`}>
              <div className="user-list">
                {/* for unread add class unread with list-info */}

                {allMessageUser?.length > 0 ? (
                  allMessageUser?.map((prod, index) => (
                    <ConversationCard
                      prod={prod}
                      populateChat={populateChat}
                      onselectMessageUser={onselectMessageUser}
                      onMaskReadClickHandler={onMaskReadClickHandler}
                      pinUnpinClickHandler={pinUnpinClickHandler} 
                      getInboxAllUser={getInboxAllUser}                     
                      onDeleteInboxConClickHandler={
                        onDeleteInboxConClickHandler
                      }
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            <InboxRightSide
              fileUpload={fileUpload}
              mobileChat={mobileChat}
              populateChat={populateChat}
              modalRef={modalRef}
              messageData={messageData}
              scrollToBottom={scrollToBottom}
              fileUploadHandler={fileUploadHandler}
              resetFile={resetFile}
            />
          </div>
        </div>
        {toggleFileModel && (
          <AnyFileUploadModel
            modelHandler={fileUploadHandler}
            title="Upload document"
            fileHandler={fileHandler}
          />
        )}
      </main>
      <div>
        <WorldConnect />
      </div>
      {toggleInviteModal && (
        <StartConversationModel
          modelHandler={inviteModelHandler}
          getInboxAllUser={() => getInboxAllUser()}
        />
      )}
    </>
  );
};

export default CandEmpInbox;

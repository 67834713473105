import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import API from "../../../api";
import {
  setActiveTab,
  setEmailVerify,
  setLoginModel,
  setLoginUser,
  setLoginUserName,
  setResumeDetail,
  setLoginUserCountry,
  setUserResumes,
  resendVerifyEmailAsync
} from "../../../redux/slices/AuthSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import "./LoginModel.css";
import { ADMIN, CANDIDATE, EMPLOYER, RESUME_MODE } from "../../../constants";
import { getResumeForApplyingOnJob } from "../../../redux/slices/candidateDetailSlice";
import ActionModal from "../ActionModal/ActionModal";
import { toast } from "react-toastify";

const LoginModel = ({ modelCHandler }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openLoginModel } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [locationValidModel, setLocationValidModel] = useState(false);
  const [domainUrl, setDomainUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const getResumes = async (token) => {
    dispatch(
      getResumeForApplyingOnJob({token: token})
    )
      .then(async (response) => {
        const res = response?.payload?.data;
        dispatch(setUserResumes(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { email, password } = data;
      try {
        setLoader(true);
        await API.post(`/auth/login`, {
          email,
          password,
        })
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              const { location_valid, valid_location } = response?.data;
              const user = response?.data?.user;
              const country = user?.country;

              if (location_valid === false) {
                dispatch(setLoginModel(false));
                setDomainUrl(valid_location);
                setLocationValidModel(true);
                setLoader(false);
                return;
              }

              if (res?.result === true) {
                dispatch(setLoginUser(res));
                dispatch(setLoginUserName(user?.first_name));
                await getResumes(res?.access_token);
                dispatch(
                  setLoginUserCountry({
                    id: country?.id,
                    name: country?.name,
                  })
                );

                dispatch(setEmailVerify(true));
                dispatch(
                  setResumeDetail({
                    resumeId: user?.resume_id,
                    resumeType: user?.resume_type,
                  })
                );
                dispatch(setLoginModel(false));
                if (res?.permissions)
                  dispatch(setdefaultPermissions(res?.permissions));
                localStorage.setItem("token", res?.access_token);

                if (
                  res?.user?.user_type &&
                  CANDIDATE.includes(res?.user?.user_type)
                ) {           
                  navigate("/candidate-dashboard");                  
                } else if (
                  res?.user?.user_type &&
                  EMPLOYER.includes(res?.user?.user_type)
                )
                  navigate("/employer-dashboard");
                else if (
                  res?.user?.user_type &&
                  ADMIN.includes(res?.user?.user_type)
                ) {
                  dispatch(
                    setActiveSideBar({
                      id: "2",
                      name: "Department",
                      value: "Department",
                    })
                  );
                  navigate("/admin-department");
                }
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrorMsg(data?.message?.error);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const locationValidModelHandler = (value) => {
    setLocationValidModel(value);
  };

  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
    dispatch(setLoginModel(false));
  };

  const resendVerifyEmailHandler = async () => {
    if (formik?.values?.email === "" || isFormFieldValid("email")) {
      formik.touched["email"] = true;
      isFormFieldValid("email");
      formik?.setFieldError("email", formik?.errors?.email);
      return;
    } else {
      dispatch(
        resendVerifyEmailAsync({
          email: formik?.values?.email,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
          if (response.error) {
            toast.error("User not exist.", {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        });
    }
  };

  return (
    <>
      <section
        className={`model-comment-popup ${openLoginModel === false && "hide-model-popup"
          }`}
      >
        <div className="popup-bg-job">
          <OutsideClickHandler
            onOutsideClick={() => {
              dispatch(setLoginModel(false));
            }}
          >
            <div className="popup-job-sec">
              <div className="model-left-right-job">
                <div className="col-right-model">
                  <div
                    className="close-img-resume"
                    onClick={() => dispatch(setLoginModel(false))}
                  >
                    <img
                      src="/image/cross-btn.png"
                      className="cross-btn"
                      alt=""
                    />
                  </div>
                  <div className="model-logo-job-sec">
                    <img
                      className="logo-img"
                      src="/image/polycareers-model.png"
                      alt=""
                    />
                  </div>
                  <div className="divider divider-mg"></div>
                  <div className="content-f">
                    <h5 className="createAccount-head">
                      <span className="content-f-black">Create an </span>Account{" "}
                      <span className="content-f-black">or</span> Sign in
                    </h5>
                    <p className="login-f-model">
                      Join our community! Create your account to unlock a world of
                      opportunities. By signing up, you agree to our {" "}
                      <Link
                        to="/term-condition"
                        onClick={() => dispatch(setLoginModel(false))}
                      >
                        {" "}
                        <strong>Terms of Service</strong>
                      </Link>{" "}
                      and acknowledge our {" "}
                      <strong className="text-decoration-underline">Cookie Policy.</strong> Don't
                      worry, we respect your inbox - you'll get only the most
                      relevant updates, with the freedom to opt out anytime.
                    </p>
                    <p className="error-msg error-email">{errorMsg}</p>
                  </div>
                  <div className="divider divider-btm"></div>
                  <div className="model-signin">
                    <form onSubmit={formik.handleSubmit}>
                      <div
                        className={`candidate-username ${isFormFieldValid("email") && "error-input"
                          }`}
                      >
                        <div className="mail-log-waring-icon">
                          <div className="email-icon-logo">
                            <img src="/image/j-m-User.svg" alt="" />
                          </div>
                          <input
                            type="text"
                            id="email-j-model"
                            placeholder="Email"
                            name="email"
                            className="form-font-job-m"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {getFormErrorMessage("email")}
                      </div>
                      <div className="candidate-password">
                        <div
                          className={`signup-passowrd-job ${isFormFieldValid("password") && "error-input"
                            }`}
                        >
                          <div className="password-icon-job">
                            <div className="password-icon-logo">
                              <img src="/image/j-m-Password.svg" alt="" />
                            </div>
                            <input
                              id="password-field-job"
                              type={passwordShown ? "text" : "password"}
                              placeholder="Password"
                              name="password"
                              className="form-font-job-m"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <div className="icon-hide">
                              {isFormFieldValid("password") ? (
                                <img src="/image/warning.png" alt="" />
                              ) : (
                                <>
                                  <img
                                    src="/image/hide-password.png"
                                    alt=""
                                    className={
                                      passwordShown && "password-icon-togle"
                                    }
                                    onClick={() => setPasswordShown(true)}
                                  />
                                  <img
                                    src="/image/password.png"
                                    alt=""
                                    className={
                                      !passwordShown && "password-icon-togle"
                                    }
                                    onClick={() => setPasswordShown(false)}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          {getFormErrorMessage("password")}
                        </div>
                        <div className="remember-forget-password remember-password">
                          <input
                            id="rememberme"
                            name="rememberme"
                            value="remember"
                            type="checkbox"
                          />{" "}
                          &nbsp;
                          <span className="remember-login-model">
                            Remember me
                          </span>
                          <Link
                            to="/forgot-password"
                            onClick={() => dispatch(setLoginModel(false))}
                          >
                            {" "}
                            <p className="forget-password forget-mbl">
                              Forgot password?
                            </p>
                          </Link>
                        </div>
                        <div className="heading-below-p">
                          <p className="heading-below-text">{errorMsg}</p>
                        </div>
                        <div className="signup-here-job p-0 mb-0">
                          <p>
                            Don’t have an account?{" "}
                            <Link to="/signup" onClick={() => onSignUpHandler()}>
                              <span>Sign up</span>
                            </Link>
                          </p>
                        </div>
                        <button
                          className="signIn-submit-btn signIn-modal-btn"
                          type="submit"
                        >
                          {loader ? (
                            <span
                              className="spinner-border spinner-border-sm loader-white"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            <p className="signIn-btn-head">Log in</p>
                          )}
                        </button>
                        <div className="signup-here pb-0">
                          <p>
                            Haven't received the confirmation email yet?{" "}
                            <Link href="#" onClick={() => resendVerifyEmailHandler()}>
                              <span>Resend Email</span>
                            </Link>
                          </p>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
      {locationValidModel && (
        <ActionModal
          modalArt="/image/warning-wishlist.png"
          heading="Invalid location"
          content={`Your account does not exist on this domain. Please login to your local domain`}
          closeModal={locationValidModelHandler}
        >
          <div className="d-flex justify-content-center w-100">
            <a
              href={domainUrl === "" ? "#" : domainUrl}
              className="domain-url-login"
            >
              {domainUrl}
            </a>
          </div>
        </ActionModal>
      )}
    </>
  );
};

export default LoginModel;

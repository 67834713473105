import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getColor } from "../../../../utils/progressbarFn";
import {
  setResumeCandidateId,
  setResumeCandidateTab,
} from "../../../../redux/slices/bulkResumeCanDetailSlice";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import { IMPRESSION_TYPES } from "../../../../constants";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

const BulkApplicationTable = ({
  item,
  candidateStatusesList,
  onFvtClick,
  onFirstImpressionClick,
  onCandidateStatusChangeHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showBadCandidate, setShowBadCandidate] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showNeedToThinkCandidate, setShowNeedToThinkCandidate] =
    useState(false);
  const [showGoodCandidate, setShowGoodCandidate] = useState(false);

  /* React Circular Progress Bar*/
  const OverAllMatch =
    item?.overall_match !== undefined && item?.overall_match !== null
      ? item?.overall_match
      : 0;

  const OverAllExp =
    item?.overall_experience !== undefined && item?.overall_experience !== null
      ? item?.overall_experience
      : 0;
  const progressValue = parseInt(OverAllMatch, 10);
  const totalExperience = parseFloat(OverAllExp);

  const handleMouseOver = (impressionId) => {
    switch (impressionId) {
      case IMPRESSION_TYPES.NOT_A_GOOD_FIT:
        setShowBadCandidate(true);
        break;
      case IMPRESSION_TYPES.NEED_TO_THINK:
        setShowNeedToThinkCandidate(true);
        break;
      case IMPRESSION_TYPES.GOOD_CANDIDATE:
        setShowGoodCandidate(true);
        break;
      default:
        break;
    }
  };

  const handleMouseOut = () => {
    setShowBadCandidate(false);
    setShowGoodCandidate(false);
    setShowNeedToThinkCandidate(false);
  };

  return (
    <tr>
      <td className="lr full-name">
        {item?.first_name !== null &&
          `${capitalizeFirstLetter(item?.first_name)}`}
          {" "}
        {item?.last_name !== null && item?.last_name}
      </td>
      <td className="lr experience">
        <span className="d-md-none d-inline">Experience:</span>
        {`${
          Number?.isInteger(totalExperience)
            ? totalExperience?.toString()
            : totalExperience?.toFixed(1)
        } Years`}
      </td>
      <td className="lr cand-status">
        <span className="d-md-none d-block mb-2">Candidate Status:</span>
        <div className="job-fillter-table-data m-0 w-100">
          <div className="j-company-fillter-table-data w-100">
            <div
              id="dropdown-boxId2"
              className="company-form-dropdownBox1-tabeldate"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>
                {item?.candidate_status_id === null
                  ? "Candidate Status"
                  : item?.candidate_status_label}
              </span>
            </div>
            <div className="list-search-dropdown-company">
              <ul className="list-table-title-cand">
                {candidateStatusesList?.slice(1)?.map((candidateStatus) => (
                  <li
                    key={candidateStatus?.id}
                    onClick={() =>
                      onCandidateStatusChangeHandler(
                        item?.candidate_id,
                        candidateStatus?.id,
                        item?.candidate_status_id
                      )
                    }
                  >
                    {candidateStatus?.status}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </td>
      <td className="lr match tb-bulk-resume">
        <div className="prog-bar-cir">
          <div
            style={{
              width: 55,
              height: 55,
              position: "relative",
              display: "inline-block",
            }}
          >
            <CircularProgressbar
              minValue="0"
              strokeWidth={12}
              value={progressValue}
              text={`${progressValue}%`}
              counterClockwise={false}
              styles={buildStyles({
                strokeLinecap: "round",
                pathTransitionDuration: 0.5,
                pathColor: getColor(progressValue),
                textColor: getColor(progressValue),
                textSize: "24px",
                trailColor: "#d6d6d6",
              })}
            />
          </div>

          {/* <div className="progress-table">
              <div className="progress-bar-outer">
                <div className="progress-bar-inner">
                  <div className="progress-value">100%</div>
                </div>
              </div>
            </div> */}
        </div>
      </td>
      <td className="lr impression">
        <div className="cv-cions-review p-0">
          <img
            className={"icon-cursor"}
            src={
              item?.impression === IMPRESSION_TYPES.NOT_A_GOOD_FIT
                ? "/image/bad-cv.png"
                : "/image/bad-cv-icon.png"
            }
            alt=""
            onClick={() =>
              onFirstImpressionClick(
                item?.candidate_id,
                IMPRESSION_TYPES.NOT_A_GOOD_FIT
              )
            }
            onMouseOver={() => handleMouseOver(IMPRESSION_TYPES.NOT_A_GOOD_FIT)}
            onMouseOut={() => handleMouseOut()}
          />

          <img
            className={"icon-cursor"}
            src={
              item?.impression === IMPRESSION_TYPES.NEED_TO_THINK
                ? "/image/think-need.png"
                : "/image/normal.cv.png"
            }
            alt=""
            onClick={() =>
              onFirstImpressionClick(
                item?.candidate_id,
                IMPRESSION_TYPES.NEED_TO_THINK
              )
            }
            onMouseOver={() => handleMouseOver(IMPRESSION_TYPES.NEED_TO_THINK)}
            onMouseOut={() => handleMouseOut()}
          />
          <img
            className={"icon-cursor"}
            src={
              item?.impression === IMPRESSION_TYPES.GOOD_CANDIDATE
                ? "/image/good-cv.png"
                : "/image/good-cv-icon.png"
            }
            alt=""
            onClick={() =>
              onFirstImpressionClick(
                item?.candidate_id,
                IMPRESSION_TYPES.GOOD_CANDIDATE
              )
            }
            onMouseOver={() => handleMouseOver(IMPRESSION_TYPES.GOOD_CANDIDATE)}
            onMouseOut={() => handleMouseOut()}
          />

          <img
            className="icon-cursor"
            src={
              parseInt(item?.is_favorite) === 0
                ? "/image/cv-favt.png"
                : "/image/favourite-fillicon.png"
            }
            alt=""
            onClick={() => onFvtClick(item?.candidate_id, item?.is_favorite)}
          />
          {showGoodCandidate === true ? (
            <div className="good-cv-box">
              <p>Good Candidate</p>
              <img src="/image/review-good-cv.png" alt="" />
            </div>
          ) : null}

          {showNeedToThinkCandidate === true ? (
            <div className="thinkneed-cv-box">
              <p>Need to Think</p>
              <img src="/image/think-cv-need.png" alt="" />
            </div>
          ) : null}

          {showBadCandidate ? (
            <div className="badreview-cv-box">
              <p>Not a Good Fit</p>
              <img src="/image/bad-cv-review.png" alt="" />
            </div>
          ) : null}
        </div>
      </td>
      <td className="lr date-app tb-bulk-resume">
        <p className="d-md-none d-inline mr-1">Applied date:</p>
        {getCurrentFormattedDate(item?.date_applied)}
      </td>
      <td className="lr action">
        <td>
          {" "}
          <div className="four-resume-img">
            <div className="img-text-tooltip">
              <img
                src="/image/resume-bulk-admin.png"
                alt=""
                className="show-tooltip icon-cursor"
                onClick={() => {
                  if (item?.candidate_id !== null) {
                    dispatch(setResumeCandidateId(item?.candidate_id));
                    dispatch(setResumeCandidateTab("resume"));
                    navigate("/bulk-resume-ai-analysis");
                  }
                }}
              />
              <div className="tooltip-jd-resume">Resume</div>
            </div>
            <div className="img-text-tooltip">
              <img
                src="/image/ai-admin.png"
                alt=""
                className="show-tooltip2 icon-cursor"
                onClick={() => {
                  if (item?.candidate_id !== null) {
                    dispatch(setResumeCandidateId(item?.candidate_id));
                    dispatch(setResumeCandidateTab("candidate-evaluation"));
                    navigate("/bulk-resume-ai-analysis");
                  }
                }}
              />
              <div className="tooltip-request-analysis-res">AI analysis</div>
            </div>
            {/* <div className="img-text-tooltip">
              <img
                src="/image/sm-notes.png"
                alt=""
                className="show-tooltip2 icon-cursor"
                onClick={() => {
                  if (item?.candidate_id !== null) {
                    dispatch(setResumeCandidateId(item?.candidate_id));
                    dispatch(setResumeCandidateTab("notes"));
                    navigate("/bulk-resume-ai-analysis");
                  }
                }}
              />
              <div className="tooltip-request-analysis-res">Notes</div>
            </div> */}
          </div>
        </td>
      </td>
    </tr>
  );
};

export default BulkApplicationTable;

import React from 'react'
import { useSelector } from 'react-redux';

const PricingCardDemo = ({ item, index }) => {
  const { user } = useSelector(
    (state) => state.auth
  );
  return (
    <div className="price-card card-demo">
      {/* <div className="price-card-header">
        <p className="p-0">Save 1.500 MKD</p>
      </div> */}
      <div className="price-card-body">
        <div>
          <h3>{item.title}</h3>
          <div className="card-para mb-3">
            <p>{item.pricingDesc}</p>
          </div>
          <h2><span>$</span>{item.pricing}<small>{item.priveVal}</small></h2>
          {/* <p className='pric-tit'><small>{item.pricingTitle}</small></p> */}
        </div>
        {/* item.buttonContent */}
        <button className="btn btn-primary" style={{textTransform:"none"}}>{(user && index === 0) ? 'Current Plan' : (user && index === 3 || index === 2 ) ? "Go to system setting"  : 'Select'}</button>
        {/* <p>This includes:</p> */}
        <ul className='m-0'>
          <p>{item.pricingHeading}</p>
          {item.pricingPoint.map((itm, ind) => {
            console.log(itm.pricingHeading);
            return (
              <li key={ind}>{itm}</li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default PricingCardDemo
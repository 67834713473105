import { useNavigate, Link, useLocation } from "react-router-dom";
import ContentModal from "../../Common/ContentModal/ContentModal";
import ConfettiModal from "../../Common/ConfettiModal/ConfettiModal";
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import API from "../../../api";
import APIPvtDB from "../../../apiPvtD";


const BaseInfo = ({
  totalApplicants,
  jobListingsCount,
  draftJobsCount,
  favoriteCount
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, inboxCount } = useSelector((state) => state.auth);
  const [buyJobs, setBuyJobs] = useState(false)
  const [availableJobs, setAvailableJobs] = useState("")
  const [productPrice, setProductPrice] = useState("")
  const [jobPurchased, setJobPurchased] = useState(false);
  const [buyJobsQuantity, setBuyJobsQuantity] = useState(1);
  const [jobPurchasedMore, setJobPurchasedMore] = useState("");


  const initializeCheckout = async () => {
    try {
      const postData = {
        user_id: user?.id,
        product_name: "purchase_job",
        unit_amount: productPrice?.product?.price,
        quantity: buyJobsQuantity
      };
      await API.post(`/job-board/purchase-job`, postData)
        .then((response) => {
          const url = response.data.checkout_url;
          const newWindow = window.open(url, '_self', 'noopener,noreferrer');
          if (newWindow) {
            newWindow.opener = null; // Prevent new tab from having access to current tab
            newWindow.location.href = url; // Navigate the new tab to the specified URL
          } else {
            // Fallback if opening in new tab fails
            navigate(url); // Navigate within the current tab if new tab failed to open
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }


  const onFavouritListClick = () => {
    if (favoriteCount > 0) navigate("/employer-Favourit-list");
  };

  const handleJobModel = () => {
    setBuyJobs(!buyJobs)
  }

  const getProductPrice = async () => {
    try {
      await API.get(`/job-board/get-product-price?category=single-job-post`).then((res) => {
        if (res?.data.result === true) {
          setProductPrice(res.data)
        }
      })
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  }

  const getAvailableJobPost = async () => {
    try {
      await APIPvtDB.get(`/available-job-posts`).then((res) => {
        if (res?.data.result === true) {
          setAvailableJobs(res.data)
        }
      })
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  }

  const onPostJob = () => {
    navigate("/post-job");
  }

  const handleInbox = () => {
    navigate("/employer-inbox");
  }

  const handleJobConfirmationModel = () => {
    setJobPurchased(!jobPurchased)
    navigate("/employer-dashboard");
  }


  const handlePurchasedJobs = () => {
    initializeCheckout();
  }

  useEffect(() => {
    getAvailableJobPost();
    getProductPrice();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('paymentStatus');
    setJobPurchasedMore(paramValue)
    if (paramValue) {
      setJobPurchased(true)
    }
  }, [])

  return (
    <section class="candidate-dashbord-sec">
      <div class="container container-site">
        <div class="space-inner-row">
          <div class="employee-job-dasboard">
            <a href="#new_applicants" class="anchor-cards-job-1 card-1">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/new-applicant.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">New applicants</p>
                  <p class="job-applied-candidate-dashboard">
                    {" "}
                    {totalApplicants}
                  </p>
                </div>
              </div>
            </a>
            <a href="#job_listing" class="anchor-cards-job-2 card-2">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/user-list.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">Job listing</p>
                  <p class="job-applied-candidate-dashboard">
                    {" "}
                    {jobListingsCount}
                  </p>
                </div>
              </div>
            </a>
            <a href="#draft_jobs" class="anchor-cards-job-3 card-3">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/draft-job.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">Draft jobs</p>
                  <p class="job-applied-candidate-dashboard"> {draftJobsCount}</p>
                </div>
              </div>
            </a>
            <a
              class="anchor-cards-job-4 card-4 icon-cursor"
              onClick={() => onFavouritListClick()}
            >
              <div class="job-applied-col job-employee-cards">
                <img src="/image/user-register.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">
                    {" "}
                    Favourite candidates{" "}
                  </p>
                  <p class="job-applied-candidate-dashboard">{favoriteCount}</p>
                </div>
              </div>
            </a>
            <a
              class="anchor-cards-job-5 card-5 icon-cursor"
              onClick={() => onPostJob()}
            >
              <div class="job-applied-col job-employee-cards">
                <img src="/image/post-job.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">
                    {" "}
                    Post a job{" "}
                  </p>
                  <p class="job-desc-candidate-dashboard">
                    You have <strong>{availableJobs?.availableJobPosts || 0}</strong> job posts left
                  </p>
                </div>
              </div>
            </a>
            <a
              class="anchor-cards-job-6 card-6 icon-cursor"
              onClick={() => handleInbox()}
            >
              <div class="job-applied-col job-employee-cards">
                <img src="/image/index-emp.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">
                    {" "}
                    Inbox{" "}
                  </p>
                  <p class="job-applied-candidate-dashboard">{inboxCount && inboxCount}</p>
                </div>
              </div>
            </a>
            <button className="btn btn-black btn-lg" onClick={handleJobModel}>Buy more job posts +</button>

          </div>
          <div className="text-right mb-4 mr-4">
          </div>
        </div>
      </div>

      {
        buyJobs && (
          <ContentModal size='lg' closeModal={handleJobModel}>
            <div className="modal-body job-purchase">
              <h3>Building your dream team is just a few clicks away.</h3>
              <p>Add more job posts for ${productPrice?.product?.price} CAD each?</p>
              <div className="job-buy-option">
                <Form.Select className="qty-db" defaultValue="1" aria-label="Default select example"
                  onChange={(e) => setBuyJobsQuantity(e.target.value)}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </Form.Select>
                <button className="btn btn-black" onClick={handlePurchasedJobs}>Confirm</button>
              </div>
            </div>
          </ContentModal>
        )
      }

      {
        jobPurchased && (
          <ContentModal closeModal={handleJobConfirmationModel}>
            <ConfettiModal closeModal={handleJobConfirmationModel}
              title={jobPurchasedMore === 'paid' ? <p>You've successfully subscribed.</p> :
                <p>You've successfully purchased new jobs.</p>
              }
            />
          </ContentModal>
        )
      }
    </section>
  );
};

export default BaseInfo;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useQuery } from "../../../utils/useQuery";
import {
  getCanJobQuesationAsync,
  saveCanSolutionAsync,
} from "../../../redux/slices/CanQASlice";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import "./index.css";

const CanQuesationAnswer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();

  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [jobTitle, setJobTitle] = useState("");
  const [jobId, setJobId] = useState("");

  const searchParams = new URLSearchParams(location.search);
  const currentJobId = query.get("currentJobId");

  const goBack = () => {
    navigate(-1);
  };

  const handleAnswerChange = (question, selectedAnswer) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers?.findIndex(
        (ans) => ans?.question_id === question?.id
      );

      if (existingAnswerIndex > -1) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].selected_answer = selectedAnswer;
        return updatedAnswers;
      } else {
        return [
          ...prevAnswers,
          {
            question_id: question?.question_id,
            question_text: question?.question_text,
            question_type_id: question?.question_type_id,
            ideal_answer: question?.ideal_answer,
            is_required: question?.is_required,
            selected_answer: selectedAnswer,
          },
        ];
      }
    });
  };

  const getSelectedAnswer = (questionId) => {
    const answer = answers?.find((ans) => ans?.question_id === questionId);
    return answer ? answer?.selected_answer : "";
  };

  const handleSubmit = async () => {
    // Filter out the required questions
    const requiredQuestions = questions?.filter((q) => q?.is_required === 1);

    // Check if all required questions are answered
    const isFormValid = requiredQuestions?.every((q) =>
      answers.some((ans) => ans?.question_id === q?.id && ans?.selected_answer)
    );

    if (!isFormValid) {
      toast.error("Please answer all required questions.", {
        autoClose: 2000,
        closeOnClick: false,
      });
      return;
    }

    // Check if the user has answered any questions
    if (answers?.length === 0) {
      toast.error(
        "Please take a minute to answer the pre-screening questions.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    }

    try {
      // Proceed with submission
      setMsg("");
      setShowMsg(false);
      setSubmitLoader(true);

      dispatch(
        saveCanSolutionAsync({
          job_id: jobId,
          candidate_id: user?.id,
          screening_questions: answers,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            setMsg(res?.message?.success);
            setShowMsg(true);
            // Use a direct function to navigate after the timeout
            setTimeout(() => {
              navigate("/candidate-dashboard");
            }, 5000);
          } else {
            setMsg("");
            setShowMsg(false);
            toast.error(res?.message?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
          setSubmitLoader(false); 
        })
        .catch((error) => {
          setMsg("");
          setShowMsg(false);
          setSubmitLoader(false); 
          console.log(error);
        });
    } catch (error) {
      setMsg("");
      setShowMsg(false);
      setSubmitLoader(false); 
      console.log(error);
    }
  };

  const getJobQuesation = async () => {
    if (user?.id) {
      try {
        setLoader(true);
        dispatch(
          getCanJobQuesationAsync({
            currentJobId: currentJobId,
          })
        )
          .then((response) => {
            const res = response?.payload;
            if (res?.result === true) {
              setJobId(res?.job?.id);
              setJobTitle(res?.job?.full_title);
              setQuestions(res?.questions);
              setMsg("");
              setShowMsg(false);
            } else if (res?.result === false) {
              setMsg(res?.message?.info);
              setShowMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            setMsg("");
            setShowMsg(false);
            setLoader(false);
            console.log(error);
          });
      } catch (error) {
        setMsg("");
        setShowMsg(false);
        setLoader(false);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (currentJobId) {
      getJobQuesation();
    }
  }, [currentJobId]);

  if (loader) {
    return (
      <>
        <section className="profile-edit pt-0">
          <div className="container container-site py-2">
            <Button
              variant="link"
              className="back-to-home add top-0 position-relative"
              onClick={() => goBack()}
            >
              <img src="/image/form-back-arrow.png" alt="" />
            </Button>
          </div>
          <div className="job-heading">
            <div className="container-site">
              <h2>Pre-screening questions</h2>
            </div>
          </div>
        </section>
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
        <WorldConnect />
      </>
    );
  }

  return (
    <>
      <section className="profile-edit pt-0">
        <div className="container container-site py-2">
          <Button
            variant="link"
            className="back-to-home add top-0 position-relative"
            onClick={() => goBack()}
          >
            <img src="/image/form-back-arrow.png" alt="" />
          </Button>
        </div>
        <div className="job-heading">
          <div className="container-site">
            <h2>Pre-screening questions</h2>
          </div>
        </div>
      </section>
      <section className="notification-sec">
        <div className="container container-site">
          <div className="question-frm-bg">
            {showMsg ? (
              <div className="question-ans-done">
                <img src="/image/question-done.png" alt="" />
                <p className="msg-end-form">{msg}</p>
              </div>
            ) : (
              <>
                <div className="ques-ans-row-f">
                  <h2>{jobTitle ? jobTitle : ""}</h2>
                  <p>
                    Please take a minute to complete the pre-screening
                    questions.
                  </p>
                </div>
                {questions?.length > 0 && (
                  <div className="question-asnwere-form">
                    {questions?.map((question, i) => (
                      <div className="question-answere-frm-row">
                        <p className="ques-num">
                          Question <span className="q1">{i + 1}</span>
                          <span className="q2">/{questions?.length}</span>
                        </p>
                        {question?.is_required === 1 && (
                          <div className="right-must-ans">Must answer</div>
                        )}
                        <p className="question-heading-f">
                          {question?.question_text}
                        </p>
                        {question?.response_type === "radio" ? (
                          <>
                            {question?.answer_options?.map((ans, idx) => {
                              return (
                                <>
                                  <input
                                    type="radio"
                                    className="radio-input yes-bg"
                                    name={`question_${ans?.id}`}
                                    onChange={() =>
                                      handleAnswerChange(
                                        question,
                                        ans?.option_text
                                      )
                                    }
                                    checked={
                                      getSelectedAnswer(question?.id) ===
                                      ans?.option_text
                                    }
                                  />{" "}
                                  <label className="radio-label-qa">
                                    {ans?.option_text}
                                  </label>
                                  <br />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <p className="select-answer">
                              Please select your answer:
                            </p>
                            <div className="slect-work-experince">
                              <div className="slect-experince-filter">
                                <div
                                  id="dropdown-boxId2"
                                  className="question-form-dropdownBox1"
                                  data-toggle="dropdown"
                                  aria-haspopup="false"
                                  aria-expanded="false"
                                >
                                  <div className="text-select-icon">
                                    <p>
                                      {" "}
                                      {getSelectedAnswer(question?.id) ||
                                        "Choose"}
                                    </p>
                                    <img src="/image/admin-bulk.png" alt="" />
                                  </div>
                                </div>
                                <div className="no-of-year-experince">
                                  <ul className="list-job-title-company">
                                    {question?.answer_options?.map((ans) => (
                                      <li
                                        key={ans?.id}
                                        value={ans?.option_text}
                                        onClick={() =>
                                          handleAnswerChange(
                                            question,
                                            ans?.option_text
                                          )
                                        }
                                      >
                                        {ans?.option_text}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    <div className="question-answere-frm-btn">
                      <button
                        className="ques-ans-btn"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Submit{" "}
                        {submitLoader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
      <WorldConnect />
    </>
  );
};

export default CanQuesationAnswer;

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setPromotionFormMode,
  setSelectedEditProID,
} from "../../../../redux/slices/companyPromotionSlice";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import { USER_TYPE } from "../../../../constants";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

const PromotionReqTable = ({
  index,
  item,
  selectedListingId,
  selectedListingIdHandler,
  deleteModelHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { defaultPermissions } = useSelector((state) => state.adminDashboard);

  const onProEditHandler = (id) => {
    dispatch(setPromotionFormMode("edit"));
    dispatch(setSelectedEditProID(id));
    navigate("/admin-promotion-slot");
  };

  return (
    <tr>
      <td>{item?.type?.id === 1 ? "Promoted companies": capitalizeFirstLetter(item?.page?.name)}</td>
      {user?.user_type === USER_TYPE.SUPER_ADMIN && (
        <td className="">
          {" "}
          <input
            className="icon-cursor"
            type="checkbox"
            name=""
            id="company-promotion"
            checked={selectedListingId.includes(item?.id) ? true : false}
            onClick={() => selectedListingIdHandler(item?.id)}
          />
          {item?.company?.name}
        </td>
      )}

      {/* <td className=""> {item?.type?.name}</td> */}
      <td>{`Position ${item?.position_number}`}</td>
      <td
        className={` ${
          item?.status?.id === 5 ||
          (item?.status?.id === 4 && "clr-chnage-text")
        }`}
      >
        {item?.status?.name}
      </td>
      <td>{item?.time_frame?.name}</td>
      <td>{getCurrentFormattedDate(item?.start_date)}</td>
      <td>{getCurrentFormattedDate(item?.end_date)}</td>
      <td>
        {" "}
        {item?.promotable_type.includes("Company") ? (
          <div className="dash-td-por" />
        ) : (
          item?.promotable?.name
        )}
      </td>
      {/* <td>{item?.country?.currency?.title}{" "}{item?.total_amount}</td> */}
      <td>{item?.created_by?.first_name}</td>
      {user?.user_type === USER_TYPE.SUPER_ADMIN ? (
        <td>
          <img src="/image/action-promotion.png" alt="" className="icon" />
        </td>
      ) : (
        <td>
          <div className="four-pormotion-img ">
            {defaultPermissions?.promotions_edit === 1 && (
              <div className="img-text-tooltip">
                <img
                  src="/image/role-edit.png"
                  alt=""
                  className="show-tooltip2 icon-cursor"
                  onClick={() => onProEditHandler(item?.id)}
                />
                <div className="tooltip-edit-role">Edit</div>
              </div>
            )}
            {defaultPermissions?.promotions_delete === 1 && (
              <div className="img-text-tooltip">
                <img
                  src="/image/role-delet.png"
                  alt=""
                  className="show-tooltip3 icon-cursor"
                  onClick={() => deleteModelHandler(true, item?.id)}
                />
                <div className="tooltip-delete-role icon-cursor">Delete</div>
              </div>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default PromotionReqTable;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PostJobForm from "./PostJobForm/PostJobForm";
import API from "../../../api";
import PostJobDecisionModel from "../../Common/PostJobDecisionModel/PostJobDecisionModel";
import PostJobZoeModel from "../../Common/PostJobZoeModel/PostJobZoeModel";
import PostJobProgressModel from "../../Common/PostJobProgressModel/PostJobProgressModel";
import ContentModal from "../../Common/ContentModal/ContentModal";
import {
  closeDivModal,
  closeZoeModal,
} from "../../../redux/slices/JobPostZoeModalSlice";
import { JOB_POST_DECISION } from "../../../constants";
import "./PostJobForm/PostJobFrom.css";

const PostJobContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, mode, postJobId, userCountry } = useSelector(
    (state) => state.auth
  );
  const { isJobDecisionModalOpen, progressModel, jobDecision } = useSelector(
    (state) => state.jobDecision
  );

  const { isModalOpen, isDivModelOpen } = useSelector(
    (state) => state.jobPostZoe
  );

  const [loader, setLoader] = useState(false);

  const [handleNoButtonPostNow, setHandleNoButtonPostNow] = useState();
  const [handleYesButtonPostNow, setHandleYesButtonPostNow] = useState(false);

  const handleNoButtonClick = async () => {
    await handleNoButtonPostNow();
  };

  const handleYesButtonClick = async (value) => {
    console.log(value, ' 321231231');
    setHandleYesButtonPostNow(value);
  };

  const postAJobHandler = async (data, selectSkillTags, selectCities, goToPage = '/employer-dashboard') => {
    const {
      jobTitle,
      jobTitleId,
      jobPositionId,
      currencyId,
      salaryStart,
      salaryEnd,
      salaryRange,
      jobTypeId,
      JobDes,
      JobDesId,
      rules,
      skill,
      mustSkill,
      niceToHave,
      benefits,
      workingHours,
      interviewPro,
      recNotes,
      reportToId,
      workMethodologyId,
      stateId,
      status,
    } = data;

    try {
      let jobDecisionValue = "";
      if (jobDecision === JOB_POST_DECISION.SYSTEM_GEN)
        jobDecisionValue = "system_desc";
      else if (jobDecision === JOB_POST_DECISION.COPY_PASTE)
        jobDecisionValue = "self_desc";
      else if (jobDecision === JOB_POST_DECISION.ZOE)
        jobDecisionValue = "zoe_desc";

      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("country_id", userCountry?.id);
      formData.append("company_id", user?.company_id);
      formData.append("name", jobTitle);
      jobTitleId !== null && formData.append("job_title_id", jobTitleId);
      jobPositionId !== null &&
        formData.append("career_level_id", jobPositionId);
      currencyId !== null && formData.append("currency_id", currencyId);
      salaryStart !== "" && formData.append("salary_from", salaryStart);
      salaryEnd !== "" && formData.append("salary_to", salaryEnd);
      salaryRange !== "" && formData.append("salary_range", salaryRange);
      formData.append("job_types[]", jobTypeId);
      formData.append("description", JobDes);
      JobDesId !== null && formData.append("job_desc_id", JobDesId);

      formData.append("responsibilities", rules);
      formData.append("skills", skill);
      formData.append("must_skills", mustSkill);
      niceToHave !== null && formData.append("nice_to_have", niceToHave);
      formData.append("benefits", benefits);
      formData.append("working_hrs", workingHours);
      formData.append("interview_process", interviewPro);
      formData.append("recruiter_notes", recNotes);
      reportToId !== null && formData.append("report_to_id", reportToId);
      workMethodologyId !== null &&
        formData.append("work_methodology_id", workMethodologyId);
      stateId !== null &&
        formData.append("state_id", stateId);
      formData.append("status", status);
      jobDecisionValue !== "" &&
        formData.append("desc_generation_method", jobDecisionValue);

      selectSkillTags !== null &&
        Object.keys(selectSkillTags).forEach((key) => {
          formData.append("tags[]", selectSkillTags[key]);
        });
      selectCities !== null &&
        Object.keys(selectCities).forEach((key) => {
          formData.append("cities[]", selectCities[key]);
        });

      setLoader(true);
      const Url =
        mode === "add"
          ? `/job-board/post-a-job`
          : `/job-board/post-a-job/update/${postJobId}`;
      await API.post(Url, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            console.log(res)
            if (res?.success === true) {
              toast.success(res?.message, {
                autoClose: 2000,
                closeOnClick: false,
              });
              navigate(goToPage);
              localStorage.setItem('job-id',res.data.id)
            } 
            else {
              toast.error(res?.message?.error, {
                autoClose: 2000,
                closeOnClick: false,
              });
              toast.error(res?.message, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            const firstErrorMessage = data?.message[0] ? data?.message[0] : data?.message;
            
          

            toast.error(firstErrorMessage, {
              autoClose: 2000,
              closeOnClick: false,
            });
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(closeZoeModal());
    dispatch(closeDivModal(false));
  }, []);

  return (
    <>
      <section className="candiate-signup">
        <div>
          <div>
            <div className="position-relative">                       
                  <PostJobForm
                    loader={loader}
                    handleYesButtonPostNow={handleYesButtonPostNow}
                    postAJobHandler={postAJobHandler}
                    setHandleNoButtonPostNow={setHandleNoButtonPostNow}
                    handleYesButtonClick={handleYesButtonClick}
                  />       
            </div>
          </div>
        </div>
      </section>

      {isJobDecisionModalOpen && <PostJobDecisionModel />}
      {isModalOpen && (
        <ContentModal size='lg'
          closeModal={handleNoButtonClick}>
          <PostJobZoeModel
            handleNoButton={handleNoButtonClick}
            handleYesButtonClick={handleYesButtonClick}
          />
        </ContentModal>
      )}
      {isDivModelOpen && (
        <ContentModal size='lg'
          closeModal={handleNoButtonClick}>
          <PostJobProgressModel />
        </ContentModal>
      )}
      {progressModel && (
        <ContentModal size='lg'
          closeModal={handleNoButtonClick}>
          <PostJobProgressModel />
        </ContentModal>
      )}
    </>
  );
};

export default PostJobContainer;

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  setLoginModel,
  setPostJobId,
  setPostJobMode,
} from "../../../redux/slices/AuthSlice";
import ContactUsModal from "../../Common/COntactusModal/ContactusModal";
import { CANDIDATE } from "../../../constants";
import "./HowItWorkSection.css";
import ActionModal from "../../Common/ActionModal/ActionModal";


const HowItWorkSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [contactModal, setContactModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  const showSubmitModal = () => {
    setContactModal(false)
    setSubmitSuccess(true)
  }

  const postJobClickHanlder = async () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(setActiveTab("employer"));
      dispatch(setPostJobMode("add"));
      dispatch(setPostJobId(null));
      navigate("/post-job");
    }
  };

  return (
    <>
      <section className="logo-dev-poly">
        <div className="container container-site">
          <div className="two-heading-row-poly">
            <h2>How it works.</h2>
            <p className="poly-row-test-job">
              Employers post your first job, easy as 1-2-3.
            </p>
          </div>
        </div>
      </section>
      <section className="three-steps-poly">
        <div className="container container-site">
          <div className="three-rows-poly-sec">
            <div className="row-onestep-poly">
              <div className="col-left-tex-poly">
                <p className="step-heading-poly">Step 1:</p>
                <p className="step-content-poly">
                  Create your job posting. Upload your own or use our AI-powered
                  tool to build a new one. Create as many as you want!{" "}
                </p>
              </div>
              <div className="col-right-img-poly">
                <img src="/image/step-1.png" alt="" />
              </div>
            </div>
            <div className="row-twostep-poly">
              <div className="col-right-img-poly">
                <img src="/image/steps-2.png" alt="" />
              </div>
              <div className="col-left-tex-poly">
                <p className="step-heading-poly">Step 2:</p>
                <p className="step-content-poly">
                  Post your job. Your first 5 job postings are free! No strings,
                  no credit card, just free postings.
                </p>
              </div>
            </div>
            <div className="row-onestep-poly">
              <div className="col-left-tex-poly">
                <p className="step-heading-poly">Step 3:</p>
                <p className="step-content-poly">
                  Review your list! Candidates are ranked and you can review why
                  we think they're a good fit. Consider both qualified
                  candidates, and also those with great potential.
                </p>
              </div>
              <div className="col-right-img-poly">
                <img src="/image/step-3.png" alt="" />
              </div>
            </div>
            <div className="two-btn-post-steps">
              {!CANDIDATE.includes(user?.user_type) && (
                <button
                  className="post-btn-poly"
                  onClick={() => postJobClickHanlder()}
                >
                  Post now
                </button>
              )}
              <button
                className="contact-btn-poly"
                onClick={() => {
                  setContactModal(true);
                }}
              >
                Contact us
              </button>
            </div>
          </div>
        </div>
      </section>

      {contactModal && <ContactUsModal setContactModal={setContactModal} showSubmitModal={showSubmitModal} />}
      {submitSuccess &&
        <ActionModal
          heading="Thank you!"
          content="Your message has been received we will get back to you shortly"
          closeModal={() => setSubmitSuccess(!submitSuccess)}
          modalArt="/image/success-modal.png"
        ></ActionModal>
      }
    </>
  );
};

export default HowItWorkSection;

import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Button, Form } from "react-bootstrap";
import ActionModal from "../../ActionModal/ActionModal";
import { EMPLOYER, IS_IMAGE, IS_PDF, IS_Doc } from "../../../../constants";
import "./MessageCard.css";

const MessageCard = ({
  item,
  myMsg,
  onDeleteInboxHandler,
  onEditInboxHandler,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const [edit, setEdit] = useState(false);
  const [deleteChat, setDeleteChat] = useState(false);
  const [deleteChatData, setDeleteChatData] = useState(null);

  const [inputValue, setInputValue] = useState(false);

  const dateTime = (data) => {
    const date = new Date(data);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  if (item?.comments === null && item?.file_path === null) return <></>;

  const isImage = IS_IMAGE.includes(item?.file_mime_type);
  const IsPdf = IS_PDF.includes(item?.file_mime_type);
  const IsDoc = IS_Doc.includes(item?.file_mime_type);

  const showSubMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const closeModal = () => {
    setDeleteChat(!deleteChat);
  };

  const dialogDelete = (data) => {
    closeModal();
    setDeleteChatData(data);
  };

  const deleteComment = async () => {
    onDeleteInboxHandler(item);
    closeModal();
    setDeleteChatData(null);
    setShowMenu(false);
  };

  const editComment = (item) => {
    setEdit(true);
    setInputValue(item?.comments);
  };

  const updateComment = async () => {
    onEditInboxHandler(item, inputValue);
    setInputValue("");
    setEdit(false);
    showSubMenu();
  };

  return (
    <>
      {!edit ? (
        <div className={`message-container ${myMsg && "message-right"}`}>
          {item?.file_path && isImage ? (
            <></>
          ) : (
            <img
              className={`${myMsg ? "right-avatar" : "avatar"}`}
              src={
                item?.user?.avatar_link
                  ? item?.user?.avatar_link
                  : item?.user?.companyy?.logo
                  ? item?.user?.companyy?.logo
                  : "/image/client-img.png"
              }
              alt={`avatar`}
            />
          )}
          <div className="message-content-container">
            <div className="message-content">
              {myMsg && (
                <div className={`pin-chat ${!myMsg && "pin-chat-right"}`}>
                  <div className={`dd-listitem-inbox-msg`}>
                    {true && (
                      <>
                        <button className="btn p-0" onClick={showSubMenu}>
                          <img
                            src="/image/ellipses.png"
                            alt="éllipses"
                            className="ep-msg-inbox"
                          />
                        </button>
                        <div
                          className={showMenu ? "cv-cions active" : "cv-cions"}
                        >
                          <OutsideClickHandler
                            onOutsideClick={() => {
                              setShowMenu(false);
                            }}
                          >
                            <ul>
                              {!item?.file_path && (
                                <li onClick={() => editComment(item)}>
                                  {" "}
                                  Edit <img src="/image/edit-ep-inbox.png" />
                                </li>
                              )}
                              <li onClick={() => dialogDelete(item)}>
                                {" "}
                                Delete
                                <img src="/image/delete-ep-inbox.png" />
                              </li>
                            </ul>
                          </OutsideClickHandler>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {item?.file_path ? (
                <>
                  {isImage ? (
                    <a href="#">
                      <img
                        src={item?.file_path}
                        alt={item?.file_original_name}
                        className="inbox-img"
                      />
                    </a>
                  ) : IsPdf ? (
                    <a href={item?.file_path} target="_blank">
                      <img
                        src="/image/inbox-pdf.png"
                        className="inbox-file-img"
                      />
                      <p className="message-text">{item?.file_original_name}</p>
                    </a>
                  ) : IsDoc ? (
                    <a href={item?.file_path} target="_blank">
                      <img
                        src="/image/inbox-doc.png"
                        className="inbox-file-img"
                      />
                      <p className="message-text">{item?.file_original_name}</p>
                    </a>
                  ) : null}
                </>
              ) : (
                <p className="message-text">{item?.comments}</p>
              )}
            </div>
            <div className="time-stamp">
              {item?.created_at && dateTime(item?.created_at)}
            </div>
          </div>
        </div>
      ) : (
        <>
          <Form
            className="d-flex justify-content-between w-100 flex-column bg-white p-3"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group className="w-100" controlId="exampleForm.ControlInput1">
              <Form.Control
                className="w-100 note-edit-input"
                placeholder="Type your note here..."
                size="lg"
                value={inputValue}
                onChange={handleInputChange}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            <div className="d-flex mt-3 justify-content-end">
              <Button
                className="me-3 px-3 py-2 edit-btn"
                variant="dark"
                onClick={() => {
                  setEdit(false);
                  showSubMenu();
                }}
              >
                Cancel
              </Button>
              <Button
                className="border-muted px-3 py-2 edit-btn"
                variant="outline-dark"
                onClick={() => updateComment()}
              >
                Save
              </Button>
            </div>
          </Form>
        </>
      )}

      {deleteChat && (
        <ActionModal
          closeModal={closeModal}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content="Are you sure you want to delete?"
        >
          <button className="btn btn-black" onClick={() => closeModal()}>
            No, keep it.
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => deleteComment()}
          >
            Yes, delete it.
          </button>
        </ActionModal>
      )}
    </>
  );
};

export default MessageCard;

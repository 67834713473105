import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import CanJourneyBox from "../../../components/Candidate/CandidateJourney/CanJourneyBox/CanJourneyBox";
import ShowRows from "../../../components/Common/Admin/ShowRows/ShowRows";
import Pagination from "../../../components/Job/JobSearch/Pagination/Pagination";
import JobNoRecordFound from "../../../components/Common/JobNoRecordFound/JobNoRecordFound";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import { getCanJourneyDataAsync } from "../../../redux/slices/canJourneySlice";
import "./index.css";

const CanJourney = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const { selectedJobTitileDD } = useSelector((state) => state.canJourney);

  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [allJourney, setAllJourney] = useState([]);

  const [loader, setLoader] = useState(false);
  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  /* filter value */
  const [searchField, setSearchField] = useState("");
  const [companyName, setCompanyName] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [byDate, setByDate] = useState([]);

  /*Filter Selected Value*/
  const [selectedCompanyName, setSelectedCompanyName] = useState({
    id: null,
    label: "",
  });

  const [selectedJobTitile, setSelectedJobTitile] = useState({
    id: selectedJobTitileDD?.jobTitleId,
    label: selectedJobTitileDD?.jobTitleLabel,
  });

  const [selectedByDate, setSelectedByDate] = useState({
    id: null,
    label: "",
  });

  const goBack = () => {
    navigate(-1);
  };

  const onCurrentPageHandler = (value) => {
    setCurrentPage(value);
  };

  /*  on Change Event */

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  };

  const onSearchFieldChange = (value) => {
    setSearchField(value);
  };

  const onCompanyNameClick = (id, name) => {
    setSelectedCompanyName({
      id: id,
      label: name,
    });
  };

  const onCompanyNameClean = () => {
    setSelectedCompanyName({
      id: null,
      label: "",
    });
  };

  const onJobTitleClick = (id, full_title) => {
    setSelectedJobTitile({
      id: id,
      label: full_title,
    });
  };

  const onJobTitleClean = () => {
    setSelectedJobTitile({
      id: null,
      label: "",
    });
  };

  const onByDateClick = (key, label) => {
    setSelectedByDate({
      id: key,
      label: label,
    });
  };

  const onByDateClean = () => {
    setSelectedByDate({
      id: null,
      label: "",
    });
  };

  const getCanJourneyData = async () => {
    if (user?.id) {
      try {
        setMsg("");
        dispatch(
          getCanJourneyDataAsync({
            company_id: selectedCompanyName?.id,
            job_id: selectedJobTitile?.id,
            date_applied: selectedByDate?.id,
            search: searchField,
            page: currentPage,
            per_page: perPage,
          })
        )
          .then((response) => {
            const res = response?.payload;

            if (res?.success === true) {
              const {
                company_names,
                job_titles,
                dates,
                candidate_journey,
                current_page,
                last_page,
                total,
              } = res?.data;

              setCompanyName(company_names);
              setJobTitles(job_titles);
              setByDate(dates);
              setCurrentPage(current_page);
              setEndPage(last_page);
              setTotal(total);
              setAllJourney([]);

              if (candidate_journey?.length > 0) {
                setAllJourney(candidate_journey);
              }              
              if (
                selectedCompanyName?.id === null &&
                selectedJobTitile?.id === null &&
                selectedByDate?.id === null &&
                searchField === "" &&
                candidate_journey?.length === 0
              ) {
                setShowMsg(true);
                setMsg(
                  "Please check again for updates regarding your application status."
                );
              } else if (
                candidate_journey?.length === 0 &&
                (selectedCompanyName?.id !== null ||
                  selectedJobTitile?.id !== null ||
                  selectedByDate?.id !== null ||
                  searchField !== "")
              ) {
                setMsg("Please make another selection. No record found.");
                setShowMsg(true);
              } else {
                setMsg("");
                setShowMsg(false);
              }
            }
          })
          .catch((error) => {
            setMsg("");
            console.log(error);
          });
      } catch (error) {
        setMsg("");
        console.error(error);
      }
    }
  };

  useEffect(() => {
    getCanJourneyData();
  }, [
    selectedCompanyName,
    selectedJobTitile,
    selectedByDate,
    searchField,
    currentPage,
    perPage
  ]);

  if (loader) {
    return (
      <>
        <section className="profile-edit pt-0">
          <div className="container container-site py-2">
            <Button
              variant="link"
              className="back-to-home add top-0 position-relative"
              onClick={() => goBack()}
            >
              <img src="/image/form-back-arrow.png" alt="" />
            </Button>
          </div>
          <div className="job-heading">
            <div className="container-site">
              <h2>Candidate journey page</h2>
            </div>
          </div>
        </section>
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
        <WorldConnect />
      </>
    );
  }

  return (
    <>
      <section className="profile-edit pt-0">
        <div className="container container-site py-2">
          <Button
            variant="link"
            className="back-to-home add top-0 position-relative"
            onClick={() => goBack()}
          >
            <img src="/image/form-back-arrow.png" alt="" />
          </Button>
        </div>
        <div className="job-heading">
          <div className="container-site">
            <h2>Candidate journey page</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="divider"></div>
            <div className="can-journey-container">
              <div className="j-fillter-only-table">
                <div className="job-fillter-company">
                  <div className="j-company-fillter job-listing-mbl">
                    <div
                      id="dropdown-boxId2"
                      className="company-form-dropdownBox1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-filter-icon">
                        <p>
                          {" "}
                          {selectedCompanyName?.label === ""
                            ? "Company name"
                            : selectedCompanyName?.label?.length >= 18
                            ? selectedCompanyName?.label?.substring(0, 18) +
                              " ..."
                            : selectedCompanyName?.label}
                        </p>
                        {selectedCompanyName?.label === "" ? (
                          <img src="/image/company--detail-filter.png" alt="" />
                        ) : (
                          <div
                            id="dropdown-boxId2"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src="/image/cross-b.png"
                              alt=""
                              onClick={() => onCompanyNameClean()}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {companyName?.length > 0 && (
                      <div className="list-search-dropdown-company">
                        <ul className="list-job-title-company">
                          {companyName?.map((company) => (
                            <li
                              key={company?.id}
                              className="icon-cursor job-title-size"
                              onClick={() =>
                                onCompanyNameClick(company?.hashed_id, company?.name)
                              }
                            >
                              {company?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="job-fillter-company">
                  <div className="j-company-fillter job-listing-mbl">
                    <div
                      id="dropdown-boxId2"
                      className="company-form-dropdownBox1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-filter-icon">
                        <p>
                          {" "}
                          {selectedJobTitile?.label === ""
                            ? "Job title"
                            : selectedJobTitile?.label?.length >= 18
                            ? selectedJobTitile?.label?.substr(0, 18) + " ..."
                            : selectedJobTitile?.label}
                        </p>
                        {selectedJobTitile?.label === "" ? (
                          <img src="/image/company--detail-filter.png" alt="" />
                        ) : (
                          <div
                            id="dropdown-boxId2"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src="/image/cross-b.png"
                              alt=""
                              onClick={() => onJobTitleClean()}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {jobTitles?.length > 0 && (
                      <div className="list-search-dropdown-company">
                        <ul className="list-job-title-company">
                          {jobTitles?.map((job, i) => (
                            <li
                              key={i}
                              className="icon-cursor job-title-size"
                              onClick={() => {
                                onJobTitleClick(job?.hashed_id, job?.full_title);
                              }}
                            >
                              {job?.full_title}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="job-fillter-company">
                  <div className="j-company-fillter job-listing-mbl">
                    <div
                      id="dropdown-boxId2"
                      className="company-form-dropdownBox1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-filter-icon">
                        <p>
                          {" "}
                          {selectedByDate?.label === ""
                            ? "By date"
                            : selectedByDate?.label?.length >= 18
                            ? selectedByDate?.label?.substr(0, 18) + " ..."
                            : selectedByDate?.label}
                        </p>
                        {selectedByDate?.label === "" ? (
                          <img src="/image/company--detail-filter.png" alt="" />
                        ) : (
                          <div
                            id="dropdown-boxId2"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src="/image/cross-b.png"
                              alt=""
                              onClick={() => onByDateClean()}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {byDate?.length > 0 && (
                      <div className="list-search-dropdown-company">
                        <ul className="list-job-title-company">
                          {byDate?.map((date, i) => (
                            <li
                              key={i}
                              className="icon-cursor job-title-size"
                              onClick={() => {
                                onByDateClick(date?.key, date?.label);
                              }}
                            >
                              {date?.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="can-journey-search-container">
                <div className="filter-search-box">
                  <img src="../image/job-search.png" alt="" />
                  <input
                    type="text"
                    name=""
                    id="jon-search-box"
                    placeholder="Search"
                    className="form-font-f"
                    onChange={(e) => {
                      onSearchFieldChange(e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {showMsg === false && allJourney?.length > 0 && (
            <>
              <div className="divider"></div>
              <div className="can-journey-show-row">
                <ShowRows
                  perPage={perPage}
                  onChangePerPageHandler={onChangePerPageHandler}
                />
              </div>
              {allJourney.map((data, i) => (
                <CanJourneyBox data={data} index={i} key={i} />
              ))}

              <div className="pagination-can-journey">
                <Pagination
                  currentPage={currentPage}
                  totalPages={endPage}
                  onCurrentPageHandler={onCurrentPageHandler}
                />
              </div>
            </>
          )}

          {showMsg === true && msg !== "" && (
            <>
              <JobNoRecordFound msg={msg} />
              <div className="divider"></div>
            </>
          )}
        </div>
      </section>
      <WorldConnect />
    </>
  );
};

export default CanJourney;

import { useState } from "react";
import { Link } from "react-router-dom";
import { CANDIDATE_STATUSES, EmptyRecord, IMPRESSION_TYPES } from "../../../../constants";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getColor } from "../../../../utils/progressbarFn";
import "react-circular-progressbar/dist/styles.css";
import "../../../Employer/CandidateDetail/CanDetailHead/CanDetailHead.css";
import { formatSalary } from "../../../../utils/formatSalary";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

const BulkAIAnaylsisHead = ({
  showEmoji,
  showCandidateStatus,
  showSalaryExpectation,
  showWorkPreference,
  showTechTag,
  showSendMsg,
  candidateData,
  candidateDetailData,
  jobDetailData,
  onFvtClick,
  onFirstImpressionClick,
}) => {
  /* React Circular Progress Bar*/
  const OverAllMatch =
    candidateDetailData?.overall_match !== undefined &&
    candidateDetailData?.overall_match !== null
      ? candidateDetailData?.overall_match
      : 0;
  const progressValue = parseInt(OverAllMatch, 10);
  const totalExperience = parseFloat(candidateDetailData?.overall_experience);
  const [showWarning, setShowWarning] = useState(false);
  const [showBadCandidate, setShowBadCandidate] = useState(false);
  const [showNeedToThinkCandidate, setShowNeedToThinkCandidate] =
    useState(false);
  const [showGoodCandidate, setShowGoodCandidate] = useState(false);
  const [showCandiateDetail, setShowCandiateDetail] = useState(false);

  const handleMouseOver = (impressionId) => {
    if (candidateDetailData?.status_id === CANDIDATE_STATUSES.NEW_APPLICANT) {
      setShowWarning(true);
      return;
    }
    switch (impressionId) {
      case IMPRESSION_TYPES.NOT_A_GOOD_FIT:
        setShowBadCandidate(true);
        break;
      case IMPRESSION_TYPES.NEED_TO_THINK:
        setShowNeedToThinkCandidate(true);
        break;
      case IMPRESSION_TYPES.GOOD_CANDIDATE:
        setShowGoodCandidate(true);
        break;
      default:
        break;
    }
  };

  const handleMouseOut = () => {
    setShowWarning(false);
    setShowBadCandidate(false);
    setShowGoodCandidate(false);
    setShowNeedToThinkCandidate(false);
  };

  return (
    <>
      <div>
        <div className="jour-left-right">
          <div className="left-col-jour">
            <img
              src={
                candidateData?.avatarLink != null
                  ? candidateData?.avatarLink
                  : candidateData?.avatarLink != undefined
                  ? candidateData?.avatarLink
                  : "image/left-jour.png"
              }
              alt=""
            />
          </div>
          <div className="right-col-jour">
            <div className="inner-left-jour">
              <div className="name-cand-jour-porgres">
                <p className="name-can-jou">
                  {!EmptyRecord.includes(candidateDetailData?.first_name)  &&
                    `${capitalizeFirstLetter(candidateDetailData?.first_name)}`}
                    {` `}
                  {!EmptyRecord.includes(candidateDetailData?.last_name) !== false &&
                    candidateDetailData?.last_name}
                </p>
                <div className="info-below-mbl">
                  <ul className="info-list-jour-mbl">
                    {(candidateDetailData?.city_name !== null ||
                      candidateDetailData?.country_name !== null) && (
                      <>
                        <li>
                          {candidateDetailData?.city_name !== null
                            ? `${candidateDetailData?.city_name}`
                            : ""}{" "}
                          {candidateDetailData?.country_name !== null
                            ? ` ${candidateDetailData?.country_name}`
                            : ""}
                        </li>
                      </>
                    )}
                    {candidateData?.education !== null && (
                      <li>{candidateData?.education}</li>
                    )}
                    {candidateData?.email !== "" && (
                      <li>
                        <a>{candidateData?.email} </a>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="progress-icon-box">
                  <div className="progress-percent-jour cus-progress-percent-jour">
                    {" "}
                    <div
                      style={{
                        width: 55,
                        height: 55,
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <CircularProgressbar
                        minValue="0"
                        strokeWidth={12}
                        value={progressValue}
                        text={`${progressValue}%`}
                        counterClockwise={false}
                        styles={buildStyles({
                          strokeLinecap: "round",
                          pathTransitionDuration: 0.5,
                          pathColor: getColor(progressValue),
                          textColor: getColor(progressValue),
                          textSize: "24px",
                          trailColor: "#d6d6d6",
                        })}
                      />
                    </div>
                  </div>
                  {showEmoji && (
                    <div className="list-icons cv-icons-list">
                      <img
                        className={showWarning === true ? null : "icon-cursor"}
                        src={
                          candidateDetailData?.impression_id ===
                          IMPRESSION_TYPES.NOT_A_GOOD_FIT
                            ? "/image/bad-cv.png"
                            : "/image/bad-cv-icon.png"
                        }
                        alt=""
                        onClick={() =>
                          onFirstImpressionClick(
                            candidateDetailData?.account_id,
                            jobDetailData?.id,
                            IMPRESSION_TYPES.NOT_A_GOOD_FIT,
                            candidateDetailData?.status_id
                          )
                        }
                        onMouseOver={() =>
                          handleMouseOver(IMPRESSION_TYPES.NOT_A_GOOD_FIT)
                        }
                        onMouseOut={() => handleMouseOut()}
                      />

                      <img
                        className={showWarning === true ? null : "icon-cursor"}
                        src={
                          candidateDetailData?.impression_id ===
                          IMPRESSION_TYPES.NEED_TO_THINK
                            ? "/image/think-need.png"
                            : "/image/normal.cv.png"
                        }
                        alt=""
                        onClick={() =>
                          onFirstImpressionClick(
                            candidateDetailData?.account_id,
                            jobDetailData?.id,
                            IMPRESSION_TYPES.NEED_TO_THINK,
                            candidateDetailData?.status_id
                          )
                        }
                        onMouseOver={() =>
                          handleMouseOver(IMPRESSION_TYPES.NEED_TO_THINK)
                        }
                        onMouseOut={() => handleMouseOut()}
                      />
                      <img
                        className={showWarning === true ? null : "icon-cursor"}
                        src={
                          candidateDetailData?.impression_id ===
                          IMPRESSION_TYPES.GOOD_CANDIDATE
                            ? "/image/good-cv.png"
                            : "/image/good-cv-icon.png"
                        }
                        alt=""
                        onClick={() =>
                          onFirstImpressionClick(
                            candidateDetailData?.account_id,
                            jobDetailData?.id,
                            IMPRESSION_TYPES.GOOD_CANDIDATE,
                            candidateDetailData?.status_id
                          )
                        }
                        onMouseOver={() =>
                          handleMouseOver(IMPRESSION_TYPES.GOOD_CANDIDATE)
                        }
                        onMouseOut={() => handleMouseOut()}
                      />

                      <div className="img-table-tooltip">
                        <img
                          src={
                            parseInt(candidateData?.isFavorite) === 0
                              ? "/image/cv-favt.png"
                              : "/image/favourite-fillicon.png"
                          }
                          alt=""
                          className="show-tooltip icon-cursor"
                          onClick={() =>
                            onFvtClick(
                              candidateDetailData?.account_id,
                              jobDetailData?.id
                            )
                          }
                        />
                      </div>

                      {showGoodCandidate === true ? (
                        <div className="good-cv-box-j">
                          <p>Good Candidate</p>
                          <img src="/image/review-good-cv.png" alt="" />
                        </div>
                      ) : null}

                      {showNeedToThinkCandidate === true ? (
                        <div className="thinkneed-cv-box-j">
                          <p>Need to Think</p>
                          <img src="/image/think-cv-need.png" alt="" />
                        </div>
                      ) : null}

                      {showBadCandidate ? (
                        <div className="badreview-cv-box-j">
                          <p>Not a Good Fit</p>
                          <img src="/image/bad-cv-review.png" alt="" />
                        </div>
                      ) : null}

                      {showWarning === true ? (
                        <div className="attention-box-resume">
                          <div className="attendtion-heading">
                            <div className="icon-atten">
                              <img src="/image/attention.png" alt="" />
                            </div>
                            <div className="attention-text-table">
                              <p className="text-atten-box-table">
                                Please Update Status First
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
              <div className="text-list-tile-mbl">Candidate Details</div>
              <div className="info-below-jour">
                {/* <ul className="info-list-jour"> showCandiateDetail */}
                {/* ${showCandiateDetail ? 'showDetail' : ''} */}
                <ul className={`info-list-jour`}>
                  {showCandidateStatus &&
                    candidateDetailData?.status !== "" && (
                      <li>
                        <span>Candidate status:</span>{" "}
                        {candidateDetailData?.status}
                      </li>
                    )}
                  {candidateData?.currentJobTitle !== "" && (
                    <li>
                      <span>Current job title:</span>{" "}
                      {candidateData?.currentJobTitle}
                    </li>
                  )}
                  {candidateData?.currentCompany !== "" && (
                    <li>
                      <span>Current employer:</span>{" "}
                      {candidateData?.currentCompany}
                    </li>
                  )}
                  {candidateData?.email !== "" && (
                    <li className="hide-li-mbl">
                      <span>Email:</span> {candidateData?.email}
                    </li>
                  )}
                  {!EmptyRecord.includes(candidateDetailData?.overall_experience) && (
                    <li>
                      <span>Overall experience:</span>{" "}
                      {`${
                        Number?.isInteger(totalExperience)
                          ? totalExperience?.toString()
                          : totalExperience?.toFixed(1)
                      } Years`}{" "}
                    </li>
                  )}
                  {candidateDetailData?.show_expected_salary === 1 &&
                    candidateDetailData?.expected_salary != null && (
                      <li>
                        {/* expected_salary */}
                        <span>Salary expectation:</span>
                        {candidateData?.currency}{" "}
                        {
                          // formatSalary(
                          //   candidateDetailData?.expected_salary,
                          //   candidateData?.currency,
                          //   candidateDetailData.salary_range,
                          //   ''
                          // )
                          formatSalary(
                            candidateDetailData?.expected_salary,
                            candidateData?.currency,
                            candidateDetailData.salary_range,
                            candidateDetailData.salary_range
                          )
                        }
                      </li>
                    )}

                  {candidateData?.education !== null && (
                    <li className="hide-li-mbl">
                      <span>Education:</span> {candidateData?.education}
                    </li>
                  )}
                  {}
                  {candidateData?.address !== "" && (
                    <>
                      <li className="hide-li-mbl">
                        <span>Location:</span>{" "}
                        {candidateData?.address !== ""
                          ? `${candidateData?.address}`
                          : ""}
                      </li>
                    </>
                  )}

                  {showWorkPreference &&
                    candidateData?.workPreferences?.length > 0 && (
                      <li className="d-md-none d-block">
                        <span>Work preference:</span>
                        <div className="tags-jour mt-2">
                          {candidateData?.workPreferences?.map(
                            (prod, index) => (
                              <button className="tag-jour-btn">
                                {prod?.name}
                              </button>
                            )
                          )}
                        </div>
                      </li>
                    )}
                  {showTechTag && candidateData?.techTag?.length > 0 && (
                    <li className="d-md-none d-block">
                      <span>Tech Tags:</span>
                      <div className="tags-jour mt-2">
                        {candidateData?.techTag?.map((prod, index) => (
                          <button className="tag-jour-btn">{prod?.name}</button>
                        ))}
                      </div>
                    </li>
                  )}
                </ul>
                {/* <div className="d-md-none d-block candidate-detail-btn text-right">
                  <p onClick={() => setShowCandiateDetail(!showCandiateDetail)}>
                    {showCandiateDetail ? 'View less' : 'View more'}
                  </p>
                </div> */}
              </div>
            </div>
            <div className="inner-right-jour">
              <div className="icon-row-profile-jour">
                {/* <div className="four-icon-link-jour">
                  <img src="/image/what-jour.png" alt="" />
                  <img src="/image/sheudle-jour.png" alt="" />
                  <img src="/image/test-jour.png" alt="" />
                  <img src="/image/offer-jour.png" alt="" />
                </div> */}
              </div>
              <div className="link-profile-btn-jour d-md-flex d-none">
                {candidateData?.linkedinURL !== "" && (
                  <a href={candidateData?.linkedinURL} target="_blank">
                    <button className="link-profile-jour" type="text">
                      <img src="/image/icon-linkedin.png" alt="" />
                      LinkedIn Profile
                    </button>
                  </a>
                )}

                {showSendMsg && (
                  <a>
                    <button className="link-mail-jour" type="submit">
                      <img src="/image/mail-jour.png" alt="" />
                      Send a message
                    </button>
                  </a>
                )}

                {showWorkPreference &&
                  candidateData?.workPreferences?.length > 0 && (
                    <div className="tags-wrok">
                      <p className="work-jour-heading">Work preference: </p>
                      <div className="tags-jour">
                        {candidateData?.workPreferences?.map((prod, index) => (
                          <button className="tag-jour-btn">{prod?.name}</button>
                        ))}
                      </div>
                    </div>
                  )}

                {showTechTag && candidateData?.techTag?.length > 0 && (
                  <div className="tags-wrok">
                    <p className="work-jour-heading">Tech tags: </p>
                    <div className="tags-jour">
                      {candidateData?.techTag?.map((prod, index) => (
                        <button className="tag-jour-btn">{prod?.name}</button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {candidateDetailData?.recommendation !== null && (
        <div className="journey-row">
          <div className="jour-left-right">
            <div className="left-col-jour-secd"></div>
            <div className="summary-col-jour">
              <p className="summary-jour">Analytical Summary</p>
              <p className="summary-jour-content">
                {candidateDetailData?.recommendation}
              </p>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default BulkAIAnaylsisHead;

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APIPvt from "../../../apiPvt";
import "./index.css";
import {
  setActiveSideBar,
  setFormMode,
} from "../../../redux/slices/AdminDashboardSlice";

const AdminDetailUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedCompanyUser } = useSelector((state) => state.adminDashboard);
  const [user, setUser] = useState([]);
  const [selectModule, setSelectModule] = useState([]);

  const onEditProfileClick = () => {
    dispatch(setFormMode("edit"));
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Users",
        value: "Create User",
      })
    );
    navigate("/admin-system-user");
  };

  const getEditUserData = async () => {
    if (selectedCompanyUser !== null) {
      try {
        await APIPvt.get(`/user/${selectedCompanyUser}`)
          .then(async (response) => {
            if (response?.status === 200 || response?.success === true) {
              let res = response?.data?.data;
              let user = response?.data?.data?.user;
              setUser(user);
              if (user?.modules?.length > 0) setSelectModule(user?.modules);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getEditUserData();
  }, [selectedCompanyUser]);

  return (
    <>
      <div className="department-role-space bg-clr-admin">
        <div className="page-title-heading">
          <h2>User profile</h2>

          <button
            className="edit-form-user"
            type="submit"
            onClick={() => {
              onEditProfileClick();
            }}
          >
            Edit profile <img src="/image/comment-edit.png" alt="" />
          </button>
        </div>

        <div className="department-form-box">
          <form action="">
            <div className="first-part-f">
              <h3>Personal information</h3>
              <p>Add user personal details here</p>
            </div>
            <div className="admin-from-step space-b-f">
              <div className="f-admin-fill feild-f-admin">
                <label for="">First name *</label>
                <br />
                <p className="form-fill-user">{user?.first_name}</p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">Last name *</label>
                <br />
                <p className="form-fill-user">{user?.last_name}</p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">Country *</label>
                <br />
                <p className="form-fill-user">{user?.country?.name}</p>
              </div>
            </div>
            <div className="admin-from-step space-b-f">
              <div className="f-admin-fill feild-f-admin-addres">
                <label for="">Address</label>
                <br />
                <p className="form-fill-user">{user?.address}</p>
              </div>
              <div className="f-admin-fill feild-f-admin-city">
                <label for="">City *</label>
                <br />
                <p className="form-fill-user"> {user?.city?.name}</p>
              </div>
            </div>
            <div className="admin-from-step space-b-f">
              <div className="f-admin-fill feild-f-admin">
                <label for="">State/Province </label>
                <br />
                <p className="form-fill-user">{user?.state?.name} </p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">Postal code *</label>
                <p className="form-fill-user">{user?.postal_code}</p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">Phone number *</label>
                <p className="form-fill-user">
                  {user?.phone !== null ? user?.phone : ""}
                </p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">Email address *</label>
                <br />
                <p className="form-fill-user">{user?.email}</p>
              </div>
            </div>
          </form>
        </div>

        <div className="department-form-box second-box-admin">
          <form action="">
            <div className="first-part-f">
              <h3>User detail</h3>
              <p>Add user detail here</p>
            </div>
            <div className="admin-from-step space-b-f">
              <div className="f-admin-fill feild-f-admin">
                <label for="">Department *</label>
                <br />
                <p className="form-fill-user">{user?.role?.department?.name}</p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">Role *</label>
                <br />
                <p className="form-fill-user">{user?.role?.title}</p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">User type *</label>
                <p className="form-fill-user">{user?.job_type?.name}</p>
              </div>
            </div>
            <div className="admin-from-step space-b-f">
              <div className="f-admin-fill feild-f-admin error-input-admin">
                <label for="">Job type *</label>
                <p className="form-fill-user">{user?.user_type_admin?.name}</p>
              </div>
              <div className="f-admin-fill feild-f-admin tags-admin error-input-admin tags-list-user-c">
                <label for="">Module access *</label>
                {selectModule?.length > 0 && (
                  <ul className="tags-titles-list">
                    {selectModule?.map((item, index) => (
                      <li>{item?.name}</li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="f-admin feild-f-admin"></div>
            </div>
          </form>
        </div>

        <div className="department-form-box">
          <form action="">
            <div className="first-part-f">
              <h3>User credential</h3>
              <p>Add user credentail detail here</p>
            </div>
            <div className="admin-from-step space-b-f">
              <div className="f-admin-fill feild-f-admin">
                <label for="">User name *</label>
                <br />
                <p className="form-fill-user">{user?.username}</p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">Status *</label>
                <p
                  className={` ${user?.status === 1
                      ? "form-fill-user-active"
                      : "form-fill-user-in-active"
                    }`}
                >
                  {user?.status === 1 ? "Active" : "Inactive"}
                </p>
              </div>
              <div className="f-admin feild-f-admin"></div>
            </div>
            <div className="admin-from-step space-b-f">
              <div className="f-admin-fill feild-f-admin">
                <label for="">Password * </label>
                <p className="form-fill-user">**********</p>
              </div>
              <div className="f-admin-fill feild-f-admin">
                <label for="">Confirm password *</label>
                <p className="form-fill-user">**********</p>
              </div>
              <div className="f-admin feild-f-admin"></div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminDetailUser;

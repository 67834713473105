import { Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { setResumeFormMode } from "../../../../redux/slices/verifyResumeSlice";
import "./index.css";
import api from "../../../../api";
import APIPvtDB from "../../../../apiPvtD";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import {
  handlePreviewResume,
  handleWordResume,
} from "../../../../utils/downloadResumes";
import { RESUME_MODE, PARSING_STATUS } from "../../../../constants";
import { setResumeUrl } from "../../../../redux/slices/candidateDashboardSlice";
import {
  setResumeDetail,
  setUserResumes,
} from "../../../../redux/slices/AuthSlice";
import { toast } from "react-toastify";
import ActionModal from "../../../../components/Common/ActionModal/ActionModal";
import { getResumeForApplyingOnJob } from "../../../../redux/slices/candidateDetailSlice";
import WorldConnect from "../../../../components/Common/WorldConnect/WorldConnect";
import { getProgressTxtColor } from "../../../../utils/progressbarFn";

export const MyResumes = () => {
  const [count, setCount] = useState(10);
  const [showMenu, setShowMenu] = useState({});
  const [resumes, setResumes] = useState([]);
  const [loadingResumes, setLoadingResumes] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [deletingResume, setDeletingResume] = useState(false);
  const [defaultingResume, setDefaultingResume] = useState(false);
  const [defaultResponse, setDefaultResponse] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const countHandler = () => {
    if (count < resumes?.length) setCount((prevCount) => prevCount + 10);
  };

  const showSubMenu = (index) => {
    setShowMenu((prevShowMenu) => ({
      ...prevShowMenu,
      [index]: !prevShowMenu[index],
    }));
  };

  const getResumes = async (token) => {
      dispatch(
        getResumeForApplyingOnJob({token: token})
      )
        .then(async (response) => {
          const res = response?.payload?.data;
          dispatch(setUserResumes(res));
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const onDeleteMyResumeHandler = (resume) => {
    const { is_default, id } = resume;

    if (
      resume?.parsing_status?.toLowerCase() === PARSING_STATUS.PENDING ||
      resume?.parsing_status?.toLowerCase() === PARSING_STATUS.PROCESS
    ) {
      return;
    }

    if (is_default === 1) {
      toast.warning(
        "Please set another document as the default resume before deleting this one.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
    } else {
      setShowDeleteConfirmationModal(id);
    }
  };

  const getUserResumes = async () => {
    setLoadingResumes(true);
    try {
      await APIPvtDB
        .get(`/get-candidate-resumes-profile`)
        .then(async (response) => {
          if (response?.status === 200) {
            let res = response?.data?.data;
            setResumes(res);
            setDefaultResponse(response);
          }
          setLoadingResumes(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingResumes(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingResumes(false);
    }
  };

  const onEditResumeHandler = (resume, parsing_status) => {
    if (
      parsing_status?.toLowerCase() === PARSING_STATUS.PENDING ||
      parsing_status?.toLowerCase() === PARSING_STATUS.PROCESS
    ) {
      return;
    } else {
      dispatch(setResumeFormMode(RESUME_MODE.UPDATE));
      dispatch(setResumeUrl(resume?.resume_link));
      dispatch(
        setResumeDetail({
          resumeId: resume?.id,
          resumeType: user?.resume_type,
        })
      );
      navigate("/candidate-resume");
    }
  };

  const onDeleteCustomResume = async (customResumeId) => {
    if (user?.id) {
      try {
        setDeletingResume(customResumeId);
        const formData = new FormData();
        formData.append("resume_id", customResumeId);
        await api
          .post(`/auth/delete-resume`, formData)
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              
              const token = localStorage.getItem("token");

              if (res?.result === true) {
                const updatedCustomResume = resumes?.filter(
                  (item, index) => item?.id !== customResumeId
                );
                setResumes(updatedCustomResume);
                await getResumes(token);
              }
            }
            setDeletingResume(false);
            setShowDeleteConfirmationModal(false);
          })
          .catch((error) => {
            setDeletingResume(false);
            setShowDeleteConfirmationModal(false);
          });
      } catch (error) {
        console.log(error);
        setDeletingResume(false);
        setShowDeleteConfirmationModal(false);
      }
    }
  };

  useEffect(() => {
    if (user?.id) {
      getUserResumes();
    }
  }, [user?.id]);

  const handleResumeDefaultChange = async (index, resume) => {
    const {id, name, parsing_status } = resume;
    if (user?.id) {

      if (
        parsing_status?.toLowerCase() === PARSING_STATUS.PENDING ||
        parsing_status?.toLowerCase() === PARSING_STATUS.PROCESS ||
        parsing_status?.toLowerCase() === PARSING_STATUS.FAILED     
      ) {
        return;
      }   
      try {
        setDefaultingResume(id);
        const formData = new FormData();
        formData.append("resume_id", id);
        formData.append("candidate_id", user?.id);
        await api
          .post(`/set-candidate-default-resume`, formData)
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              const token = localStorage.getItem("token");
              if (res?.success === true) {
                const updatedResumes = resumes?.map((resume, i) => {
                  if (i === index) {
                    return {
                      ...resume,
                      is_default: resume?.is_default === 1 ? 0 : 1,
                    };
                  }
                  return {
                    ...resume,
                    is_default: 0,
                  };
                });
                setResumes(updatedResumes);
                await getResumes(token);
                toast.success(`${res?.message}`, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              }
            }
            setDefaultingResume(false);
          })
          .catch((error) => {
            setDefaultingResume(false);
          });
      } catch (error) {
        console.log(error);
        setDefaultingResume(false);
      }
    }
  };

  return (
    <div>
      <div className="container container-site py-md-2 py-1">
        <Button
          variant="link"
          className="back-to-home add position-relative top-0"
          onClick={() => navigate(-1)}
        >
          <img src="/image/form-back-arrow.png" alt="" />
        </Button>
      </div>
      {loadingResumes ? (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>
          <section className="journey-sec pt-0">
            <div className="position-relative">
              <div className="job-heading">
                <div className="container container-site position-relative">
                  <h2> My resumes</h2>
                </div>
              </div>
            </div>
            <div className="container container-site px-0">
              <section className="resume-verivication-form">
                <div className="container container-site">
                  <div className="listing-resume">
                    <div className="listing-resume-header">
                      <div className="rr file-name">File name</div>
                      <div className="rr post-url">Job post URL</div>
                      <div className="rr res-status">Status</div>
                      <div className="rr res-msg">Message</div>
                      <div className="rr res-date">Created date</div>
                      <div className="rr no-defult">Default</div>
                      <div className="rr action-res">Action</div>
                    </div>
                    <div className="listing-resume-mb">
                      {resumes?.length > 0 &&
                        resumes?.slice(0, count)?.map((resume, index) => (
                          <div className="listing-resume-body">
                            <div className="rr file-name">
                              {" "}
                              {resume?.resume_display_name || "---"}
                            </div>
                            <div className="rr post-url">
                              <p className="d-flex align-items-center justify-content-between gap-3">
                                <strong className="d-md-none mr-1 post-w-url">
                                  Job post URL:
                                </strong>
                                {resume?.job_url ? (
                                  <a href={resume?.job_url} target="_blank">
                                    {resume?.job_url?.length >= 25
                                      ? `${resume?.job_url?.substr(0, 25)}...`
                                      : resume?.job_url}
                                  </a>
                                ) : (
                                  "---"
                                )}
                              </p>
                            </div>
                            <div className="rr res-status">
                              <p className="d-flex align-items-center justify-content-between gap-3">
                                <strong className="d-md-none mr-1">
                                  Status:
                                </strong>
                                {resume?.parsing_status !== null && (
                                  <div
                                    className={` ${getProgressTxtColor(
                                      resume?.parsing_status?.toLowerCase()
                                    )}`}
                                  >
                                    {resume?.parsing_status}
                                  </div>
                                )}
                              </p>
                            </div>
                            <div className="rr res-msg">
                              <p className="d-flex align-items-center justify-content-between gap-3">
                                <strong className="d-md-none mr-1">
                                  Message:
                                </strong>
                                {resume?.parsing_message !== null &&
                                  resume?.parsing_message}
                              </p>
                            </div>
                            <div className="rr res-date">
                              {getCurrentFormattedDate(resume?.updated_at)}
                            </div>
                            <div className="rr no-defult">
                              <p className="d-flex align-items-center justify-content-between">
                                <strong className="d-md-none mr-1">
                                  Default resume:
                                </strong>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={resume?.is_default === 1}
                                    onChange={() =>
                                      handleResumeDefaultChange(
                                        index,
                                        resume
                                      )
                                    }
                                  />
                                  <span className="slider"></span>
                                </label>
                              </p>
                              <p className="d-md-none d-flex align-items-center justify-content-end date-sapce-res">
                                <span className="d-md-none mr-1">
                                  Created Date:
                                </span>
                                {getCurrentFormattedDate(resume?.updated_at)}
                              </p>
                            </div>
                            <OutsideClickHandler
                              onOutsideClick={() => setShowMenu({})}
                            >
                              <div className="rr action-res">
                                <button
                                  className="btn d-md-none d-inline"
                                  onClick={() => showSubMenu(index)}
                                >
                                  <img
                                    src="/image/black-dot-crl.png"
                                    alt="éllipses"
                                  />
                                </button>
                                <div
                                  className={
                                    showMenu[index]
                                      ? "cv-cions-res active"
                                      : "cv-cions-res"
                                  }
                                >
                                  <div
                                    className={`img-table-tooltip ${
                                      (resume?.parsing_status?.toLowerCase() ===
                                        PARSING_STATUS.PENDING ||
                                        resume?.parsing_status?.toLowerCase() ===
                                          PARSING_STATUS.PROCESS) &&
                                      "icon-disable"
                                    }`}
                                    onClick={() =>
                                      handlePreviewResume(resume?.parsed_resume)
                                    }
                                  >
                                    <img
                                      src="/image/PDF.png"
                                      alt=""
                                      className="show-tooltip"
                                    />
                                    <div className="tooltip-table">Pdf</div>
                                  </div>
                                  <div
                                    className={`img-table-tooltip ${
                                      (resume?.parsing_status?.toLowerCase() ===
                                        PARSING_STATUS.PENDING ||
                                        resume?.parsing_status?.toLowerCase() ===
                                          PARSING_STATUS.PROCESS) &&
                                      "icon-disable"
                                    }`}
                                    onClick={() =>
                                      handlePreviewResume(
                                        resume?.resume_link_word
                                      )
                                    }
                                  >
                                    <img
                                      src="/image/word-logo.png"
                                      alt=""
                                      className="show-tooltip"
                                    />
                                    <div className="tooltip-table">MS Word</div>
                                  </div>
                                  <div
                                    className={`img-table-tooltip ${
                                      (resume?.parsing_status?.toLowerCase() ===
                                        PARSING_STATUS.PENDING ||
                                        resume?.parsing_status?.toLowerCase() ===
                                          PARSING_STATUS.PROCESS) &&
                                      "icon-disable"
                                    }`}
                                    onClick={() => {
                                      onEditResumeHandler(
                                        resume,
                                        resume?.parsing_status
                                      );
                                    }}
                                  >
                                    <img
                                      src="/image/edit-dash.png"
                                      alt=""
                                      className="show-tooltip"
                                    />
                                    <div className="tooltip-table">Edit</div>
                                  </div>
                                  <div
                                    className={`img-table-tooltip ${
                                      (resume?.parsing_status?.toLowerCase() ===
                                        PARSING_STATUS.PENDING ||
                                        resume?.parsing_status?.toLowerCase() ===
                                          PARSING_STATUS.PROCESS) &&
                                      "icon-disable"
                                    }`}
                                    onClick={() =>
                                      onDeleteMyResumeHandler(resume)
                                    }
                                  >
                                    <img
                                      src="/image/delete-dash-m.png"
                                      alt=""
                                      className="show-tooltip"
                                    />
                                    <div className="tooltip-table">Remove</div>
                                  </div>
                                </div>
                              </div>
                            </OutsideClickHandler>
                          </div>
                        ))}
                    </div>
                {
                 defaultResponse?.data?.success === false && (<div className='no-interview text-center my-2'><p>{defaultResponse?.data?.message}</p></div>)
                   }
                    <div className="divider"></div>
                  </div>
                  
                </div>


                {resumes?.length > 10 && (
                  <div className="see-more-job" onClick={() => countHandler()}>
                    <Link href="#">
                      <button>See more</button>
                    </Link>
                  </div>
                )}
              </section>
            </div>
          </section>
        </>
      )}
      <WorldConnect />
      {showDeleteConfirmationModal && (
        <ActionModal
          heading="Double-Check"
          closeModal={() => setShowDeleteConfirmationModal(false)}
          modalArt="/image/warning-wishlist.png"
          content="Are you sure you want to delete this resume?"
        >
          <div className="d-flex justify-content-center w-100">
            <Button
              onClick={() => setShowDeleteConfirmationModal(false)}
              size="lg"
              variant="dark"
              className="modal-btn me-3"
            >
              No
            </Button>
            <Button
              onClick={() => onDeleteCustomResume(showDeleteConfirmationModal)}
              size="lg"
              variant="outline-dark"
              className="modal-btn"
              disabled={deletingResume}
            >
              {deletingResume ? <Spinner /> : "Yes"}
            </Button>
          </div>
        </ActionModal>
      )}
    </div>
  );
};

import { useEffect } from "react";
import { CAN_JOURNEY_STATUS } from "../../../../constants";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";

const CanJourneyBox = ({ data, index }) => {
  const currentStep = data?.job?.interview_steps?.find(
    (step) =>
      step?.candidate_journey?.stage_status === CAN_JOURNEY_STATUS.PROGRESS
  );

  const getStepClass = (status) => {
    let baseClass = "step-circle";
    if (status === CAN_JOURNEY_STATUS.PROGRESS) {
      baseClass += " active-circle";
    }
    if (status === CAN_JOURNEY_STATUS.COMPLETED) {
      baseClass += " active active-circle";
    }
    return baseClass;
  };

  useEffect(() => {
    const slider = document.querySelector(`#slider-${index}`);
    const nextBtn = document.getElementById(`nextBtn-${index}`);
    const prevBtn = document.getElementById(`prevBtn-${index}`);
    let currentIndex = 0;

    if (slider && nextBtn && prevBtn) {
      const itemWidth = slider.querySelector("li").offsetWidth + 20; // width + gap
      const totalItems = slider.querySelectorAll("li").length;
      const visibleItems = Math.floor(
        slider.parentElement.offsetWidth / itemWidth
      );

      const updateSliderPosition = () => {
        const moveBy = currentIndex * itemWidth;
        slider.style.transform = `translateX(-${moveBy}px)`;
      };

      const checkButtons = () => {
        prevBtn.disabled = currentIndex === 0;
        nextBtn.disabled = currentIndex >= totalItems - visibleItems;
      };

      nextBtn.addEventListener("click", () => {
        if (currentIndex < totalItems - visibleItems) {
          currentIndex++;
          updateSliderPosition();
        }
        checkButtons();
      });

      prevBtn.addEventListener("click", () => {
        if (currentIndex > 0) {
          currentIndex--;
          updateSliderPosition();
        }
        checkButtons();
      });

      checkButtons();
    }
  }, [data, index]);

  return (
    <div className="candidate-journy-row">
      <div className="job-name-statue">
        <div className="apply-date">
          <p className="job-title-journy">
            {data?.job?.full_title ? data?.job?.full_title : ""}
          </p>
          <p className="job-title-journy">
            {data?.job?.company?.name ? data?.job?.company?.name : ""}
          </p>
          <p className="apply-date">
            Applied date: {getCurrentFormattedDate(data?.created_at)}
          </p>
        </div>

        {currentStep && (
          <div className="job-statue">
            Current status:{" "}
            <span>{currentStep?.interview_step_name || ""}</span>
          </div>
        )}
      </div>
      <div className="slider-btn-cand">
        <button id={`prevBtn-${index}`} className="btn-slider-can">
          {"<"}
        </button>{" "}
        <button id={`nextBtn-${index}`} className="btn-slider-can active">
          {">"}
        </button>
      </div>

      {data?.job && data?.job?.interview_steps?.length > 0 && (
        <div className="step-interview-journey">
          <ul id={`slider-${index}`} className="interview-journey">
            {data?.job?.interview_steps?.map((prod, i) => (
              <li
                key={i}
                className={getStepClass(prod?.candidate_journey?.stage_status)}
              >
                {prod?.interview_step_name ? prod?.interview_step_name : ""}
                <p>
                  {prod?.candidate_journey?.created_at
                    ? getCurrentFormattedDate(
                        prod?.candidate_journey?.created_at
                      )
                    : "Date to be scheduled soon."}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CanJourneyBox;

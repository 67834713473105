import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import "./EmpProfileView.css";
import { useEffect, useRef, useState } from "react";
import APIPvt from "../../../apiPvt";
import API from "../../../api";
import { toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getColor } from "../../../utils/progressbarFn";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../../constants";

const EmpProfileView = ({ baseInfo, companyInfo, getUserProfile }) => {
  const navigate = useNavigate();

  const [legelDocumentAvailable, setLegalDocumentAvailable] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const { user } = useSelector((state) => state.auth);
  const fileRef = useRef();

  const progressValue = parseInt(baseInfo?.percentage, 10);
  const safeProgressValue = isNaN(progressValue) ? 0 : progressValue;


  const changePasswordHandler = () => {
    navigate("/new-password");
  };

  useEffect(() => {
    if (companyInfo?.company?.length > 0) {
      if (companyInfo?.company[0].legal_document) {
        console.log(companyInfo?.company[0].legal_document);
        setLegalDocumentAvailable(true);
      } else {
        console.log(companyInfo?.company[0].legal_document);
        setLegalDocumentAvailable(false);
      }
    }
  }, [companyInfo]);

  const updateLegalDocument = async (file, companyInfo) => {
    const formData = new FormData();
    formData.append("legal_document", file);
    formData.append("company_detail", companyInfo?.description);
    formData.append("first_name", baseInfo?.firstName?.trim());
    formData.append("last_name", baseInfo?.lastName?.trim());
    formData.append("website_url", companyInfo?.website);
    formData.append("id", baseInfo?.id ? baseInfo?.id : "");
    formData.append("phone", baseInfo?.phone ? baseInfo?.phone : "");
    formData.append(
      "linkedin",
      companyInfo?.linkedin ? companyInfo?.linkedin : ""
    );
    formData.append(
      "city_id",
      companyInfo?.city_id ? companyInfo?.city_id : ""
    );
    formData.append(
      "country_id",
      baseInfo?.countryId ? baseInfo.countryId : ""
    );
    formData.append("company_logo", companyInfo?.company[0]?.logo);

    API.post("/auth/update-employer-profile", formData)
      .then((res) => {
        if (res.data.result === true) {
          toast.success("Document Updated Successfully!", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
      })
      .then(() => {
        setLegalDocumentAvailable(true);
        getUserProfile();
      })
      .catch((error) => {
        toast.error(error?.message, {
          autoClose: 2000,
          closeOnClick: false,
        });
      });
  };
  const handleFileChange = async (event) => {
    const file = event;

    if (file) {
      if (file.size / 1000 <= 2048 && file.type === "application/pdf") {
        updateLegalDocument(file, companyInfo);
      } else {
        if (file.size / 1000 > 2048) {
          toast.error("File size must be less 2048 KB", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        if (file.type !== "application/pdf") {
          toast.error("Only PDF file type is supported", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
      }
    }
  };

  const resumeFileHandler = async (file, selectedImageName) => {
    handleFileChange(file);
  };

  const handlePreviewDocument = (documentURL) => {
    const pdfUrl = documentURL;
    window.open(pdfUrl, "_blank");
  };

  const modelHandler = (toggleModel) => {
    setToggleModal(toggleModel);
  };


  let defaultAvatar = "/image/avtar-company.png";
  user?.user_type === USER_TYPE.COMPANY_USER &&
    (defaultAvatar = "/image/company-default-avatar.png");

  return (
    <>
      <div className="profile-sec-view">
        <div className="profile-row">
          {companyInfo?.logo === null || companyInfo?.logo === undefined ? (
            <div className="candidtae-profile-image-avatar candidate-viewP-mbl">
              <img src={defaultAvatar} alt="" />
              <div className="addProfile-icon-view-mbl d-none">
                <img src="/image/addProfile.png" alt="" />
              </div>
            </div>
          ) : (
            <div className="candidtae-profile-image-avatar candidate-viewP-mbl">
              <img src={companyInfo?.logo} alt="" />
              <div className="addProfile-icon-view-mbl d-none">
                <img src="/image/addProfile.png" alt="" />
              </div>
            </div>
          )}
          <div className="candidate-information">
            <div className="candidate-profile-date-after">
              <div className="d-flex align-items-center">
              <h5>
                {capitalizeFirstLetter(companyInfo?.name)}           
              </h5>
              {" "}
              <div className="prog-bar-cir ml-5">
                <div
                  style={{
                    width: 50,
                    height: 50,
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <CircularProgressbar
                    minValue="0"
                    strokeWidth={12}
                    value={safeProgressValue}
                    text={`${safeProgressValue}%`}
                    counterClockwise={false}
                    styles={buildStyles({
                      strokeLinecap: "round",
                      pathTransitionDuration: 0.5,
                      pathColor: getColor(safeProgressValue),
                      textColor: getColor(safeProgressValue),
                      textSize: "24px",
                      trailColor: "#d6d6d6",
                    })}
                  />
          </div>
        </div>
            </div>              
              {baseInfo?.firstName !== "" && baseInfo?.lastName !== "" && (
                <div className="candiate-info-heading">
                  <Link href="#">
                    <p className="candidate-info-text userName-mbl">
                      {baseInfo?.username}
                    </p>
                  </Link>
                </div>
              )}

              <div className="candidate-header-col">
                <div className="candidate-information">
                  {baseInfo?.countryName && (
                    <div className="candiate-info-heading">
                      <Link href="#">
                        <img
                          src="/image/profile-location.png"
                          className="location-icon"
                          alt=""
                        />
                        <p className="candidate-info-text location-name-local userName-mb ml-0">
                          {baseInfo?.city !== "" ? baseInfo?.city + ", " : ""}
                          {baseInfo?.state !== "" ? baseInfo?.state + ", " : ""}
                          {baseInfo?.countryName !== ""
                            ? baseInfo?.countryName
                            : ""}
                        </p>
                      </Link>
                    </div>
                  )}
                  <div className="candiate-info-heading">
                    <a>
                      <img
                        src="../image/profile-phone.png"
                        className="profile-icon"
                        alt=""
                      />
                      {baseInfo?.phone ? (
                        <p className="candidate-info-text">{baseInfo?.phone}</p>
                      ) : (
                        <p className="candidate-info-text opacity-50">
                          Phone no
                        </p>
                      )}
                    </a>
                  </div>

                  <div className="candiate-info-heading">
                    <a>
                      <img
                        src="/image/building-profile.png"
                        className="profile-icon"
                        alt=""
                      />
                      {companyInfo?.industry !== "" ? (
                        <p className="candidate-info-text">
                          {companyInfo?.industry}
                        </p>
                      ) : (
                        <p className="candidate-info-text opacity-50">
                          Company type
                        </p>
                      )}
                    </a>
                  </div>

                  {baseInfo?.email && (
                    <div className="candiate-info-heading">
                      <a href={baseInfo?.email}>
                        <img
                          src="/image/profile-email.png"
                          className="email-icon"
                          alt=""
                        />
                        <p className="candidate-info-text">{baseInfo?.email}</p>
                      </a>
                    </div>
                  )}
                </div>
                <div className="candidate-information">
                  <div className="candidate-profile-date-after">
                    <div className="candiate-info-heading">
                      <a href={companyInfo?.website}>
                        <img
                          src="/image/profile-website.png"
                          className="profile-icon"
                          alt=""
                        />
                        {companyInfo?.website ? (
                          <p className="candidate-info-text">
                            {companyInfo?.website}
                          </p>
                        ) : (
                          <p className="candidate-info-text opacity-50">
                            Company website link
                          </p>
                        )}
                      </a>
                    </div>
                    <div className="candiate-info-heading">
                      <a>
                        <img
                          src="/image/team-profile.png"
                          className="profile-icon"
                          alt=""
                        />
                        {companyInfo?.companySize !== "" ? (
                          <p className="candidate-info-text">
                            {companyInfo?.companySize}
                          </p>
                        ) : (
                          <p className="candidate-info-text opacity-50">
                            Company size
                          </p>
                        )}
                      </a>
                    </div>

                    <div className="candiate-info-heading">
                      <a href={companyInfo?.linkedin} target="_blank">
                        <img
                          src="/image/profile-linked.png"
                          className="profile-icon"
                          alt=""
                        />
                        {companyInfo?.linkedin ? (
                          <p className="candidate-info-text">
                            {companyInfo?.linkedin}
                          </p>
                        ) : (
                          <p className="candidate-info-text opacity-50">
                            LinkedIn ID
                          </p>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpProfileView;

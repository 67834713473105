import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import API from "../../../../api";
import { resendVerifyEmailAsync } from "../../../../redux/slices/AuthSlice";
import './CandidateForm.css';

const CandidateForm = ({
  loader,
  errorMsgHandler,
  CandidateSignUpHandler,
}) => {
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      termServices: false,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First name should be an alphabet")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last name should be an alphabet")
        .required("Please enter last name"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
      password: Yup.string().test((value) => {
        const errors = [];
        if (!value || value.length < 8) {
          errors.push("Password must be at least 8 characters in length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use a minimum of 1 numeric value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use at least 1 upper case letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use at least 1 lower case letter (abcd)");
        }
        if (!/[!#%$*]/.test(value)) {
          errors.push("Use at least one special character (!# % $ *)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please re-enter confirm password")
        .oneOf([Yup.ref("password")], "Your passwords do not match"),      
      termServices: Yup.boolean()
        .oneOf([true], "Please select the terms and conditions")
        .required("Please select the terms and conditions"),     
    }),
    onSubmit: async (data, { resetForm, setErrors }) => {
      const { email } = data;
      if (passwordErrors?.length > 0) return;     
      try {
        await API.post("/auth/check-email-unique", {
          email: email,
        })
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              if (response?.data?.result === true) {
                    CandidateSignUpHandler(data);
              } else {
                let errorMsg = res?.message?.email[0];
                setErrors({ email: errorMsg });   
                errorMsgHandler("");      
              }
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrors({ email: data?.message?.error });
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const resendVerifyEmailHandler = async () => {
    if (formik?.values?.email === "" || isFormFieldValid("email")) {
      formik.touched["email"] = true;
      isFormFieldValid("email");
      formik?.setFieldError("email", formik?.errors?.email);
      return;
    }
    else {
      dispatch(
        resendVerifyEmailAsync({
          email: formik?.values?.email,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        });
    }
  };


  return (
    <div className="candidate-signup-form-feild">
      <form onSubmit={formik.handleSubmit}>
        <div className="candidate-username-signup-form f-feild ">
          <div
            className={`first-name ${isFormFieldValid("firstName") && "error-input"
              }`}
          >
            <label for="fname">First name*</label>
            <br />
            <input
              className="form-font-f"
              type="text"
              placeholder="First name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("firstName")}
          </div>

          <div
            className={`last-name ${isFormFieldValid("lastName") && "error-input"
              }`}
          >
            <label for="lname">Last name*</label>
            <br />
            <input
              className="form-font-f"
              type="text"
              placeholder="Last name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("lastName")}
          </div>
        </div>

        <div
          className={`signup-email f-feild ${isFormFieldValid("email") && "error-input"
            }`}
        >
          <label for="femail">E-mail*</label>
          <br />
          <input
            className="form-font-f"
            type="email"
            name="email"
            id=""
            placeholder="Enter valid email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {getFormErrorMessage("email")}
        </div>

        <div
          className={`signup-passowrd f-feild ${isPasswordFieldValid(passwordErrors) && "error-input"
            }`}
        >
          <label for="fpassword">Password*</label>
          <br />
          <div class="password-icon bg-pass-f">
            <input
              className="form-font-f"
              id="password-field"
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="Enter valid password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="icon-hide">
              {isPasswordFieldValid(passwordErrors) ? (
                <img src="/image/warning.png" alt="" />
              ) : (
                <>
                  <img
                    src="/image/hide-password.png"
                    alt=""
                    className={passwordShown && "password-icon-togle"}
                    onClick={() => setPasswordShown(true)}
                  />
                  <img
                    src="/image/password.png"
                    alt=""
                    className={!passwordShown && "password-icon-togle"}
                    onClick={() => setPasswordShown(false)}
                  />
                </>
              )}
            </div>
          </div>
          {getPasswordErrorMessage(passwordErrors)}
        </div>

        <div
          className={`signup-passowrd f-feild ${isFormFieldValid("confirmPassword") && "error-input"
            }`}
        >
          <label for="fpassword">Confirm password*</label>
          <br />
          <div class="password-icon bg-pass-f">
            <input
              className="form-font-f"
              id="password-field"
              type={confirmPasswordShown ? "text" : "password"}
              name="confirmPassword"
              placeholder="Re-type password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="icon-hide">
              {isFormFieldValid("confirmPassword") ? (
                <img src="/image/warning.png" alt="" />
              ) : (
                <>
                  <img
                    src="/image/hide-password.png"
                    alt=""
                    className={confirmPasswordShown && "password-icon-togle"}
                    onClick={() => setConfirmPasswordShown(true)}
                  />
                  <img
                    src="/image/password.png"
                    alt=""
                    className={!confirmPasswordShown && "password-icon-togle"}
                    onClick={() => setConfirmPasswordShown(false)}
                  />
                </>
              )}
            </div>
          </div>
          {getFormErrorMessage("confirmPassword")}
        </div>
        <div className="signup-check f-feild">
          {isFormFieldValid("termServices") && (
            <div className="attention-box">
              <div className="attendtion-heading">
                <div className="icon-atten">
                  <img src="/image/attention.png" alt="" />
                </div>
                <div className="attention-text">
                  <p className="text-atten-box">
                    {formik?.errors?.termServices}
                  </p>
                </div>
              </div>
            </div>
          )}
          <input
            type="checkbox"
            name="termServices"
            checked={formik?.values?.termServices}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />{" "}
          &nbsp;Yes, I understand and agree to the terms of service
        </div>
        <div className="candidate-from-signup-create">
          <button type="submit" className="form-submit">
            {loader ? (
              <>
                {/* {"Your account is being created "} */}
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Get started"
            )}
          </button>
          <div className="signup-resendmail">
            <p>
              Haven't received the confirmation email yet?{" "}
              <Link href="#" onClick={() => resendVerifyEmailHandler()}>
                <span>Resend Email</span>
              </Link>
            </p>
          </div>
          <br />
        </div>
      </form>
    </div>
  );
};

export default CandidateForm;

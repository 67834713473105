import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatSalary } from "../../../utils/formatSalary";
import getDays from "../../../utils/getDays";
import {
  setActiveTab,
  setLoginModel,
  setPostJobId,
  setPostJobMode,
} from "../../../redux/slices/AuthSlice";
import {
  jobAppliedAsync,
  setJobAppliedId,
} from "../../../redux/slices/candidateDashboardSlice";
import { setPostJobEditForm } from "../../../redux/slices/employerDashboardSlice";
import AddToWishlist from "../../Common/JobRecord/AddToWishlist";
import { ADMIN } from "../../../constants";
import "./JobHead.css";
import Button from "react-bootstrap/Button";
import NFormatter from "../../../utils/NFormatter";
import { ApplyJobSteps } from "../../Common/JobRecord/ApplyJobSteps/Index";
import ActionModal from "../../Common/ActionModal/ActionModal";

const initialValuesForSubmitData = {
  id: null,
  profile: false,
  salary: false,
};

const JobHead = ({ hideQuesationBtn, jobDetail, qaStatus }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isEmailVerify, user, resumes, userCountry } = useSelector(
    (state) => state.auth
  );
  let jobDesDetail = Object.assign({}, jobDetail[0]);

  const [toggleApplied, setToggleApplied] = useState(false);
  const [toggleEmptyResumesModal, setToggleEmptyResumesModal] = useState(false);
  const [showApplyJobModal, setShowApplyJobModal] = useState(false);
  const [applyingToJob, setApplyingToJob] = useState(false);
  const [submitData, setSubmitData] = useState(initialValuesForSubmitData);

  const toggleEmptyResumesHandler = (value) => {
    setToggleEmptyResumesModal(value);
    if (value === true) {
      setTimeout(() => {
        setToggleEmptyResumesModal(false);
      }, 5000); 
  }
};
  const applyNowHandler = () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } 
    else if(resumes?.length === 0){
      toggleEmptyResumesHandler(true);
    }
    else {
      setShowApplyJobModal(true);
    }
  };

  const handleSubmit = (resume_id, include_profile, show_expected_salary) => {
    setApplyingToJob(true);
    dispatch(
      jobAppliedAsync({
        userId: user?.id,
        jobId: jobDesDetail?.id,
        resume_id,
        include_profile,
        show_expected_salary,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          dispatch(setJobAppliedId(jobDesDetail?.id));
          setToggleApplied(true);
          setShowApplyJobModal(false);
          setSubmitData(initialValuesForSubmitData);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        setApplyingToJob(false);
      })
      .catch((error) => {
        console.log(error);
        setApplyingToJob(false);
      });
  };

  const wishListHandler = () => {
    if (isEmailVerify === false) dispatch(setLoginModel(true));
  };

  const editJobClickHanlder = (id) => {
    dispatch(setActiveTab("employer"));
    dispatch(setPostJobMode("edit"));
    dispatch(setPostJobEditForm("listing"));
    dispatch(setPostJobId(id));
    navigate("/post-job");
  };

  const QuesationHandler = () => {
    if (jobDesDetail?.id) navigate(`/job-quesation/${jobDesDetail?.id}`);
  };

  const onDetailJobHandler = () => {
  };

  const onDetailCompanyHandler = (hashed_id) => {
    if(hashed_id){
      navigate(`/company-detail/?currentCompanyId=${hashed_id}`);
    }
  };

  console.log(jobDesDetail);

  return (
    <div className="position-relative">
      <div className="container container-site py-2">
        <Button
          variant="link"
          className="back-to-home add resp-mob position-relative top-0 mb-0"
          onClick={() => navigate(-1)}
        >
          <img src="/image/form-back-arrow.png" alt="" />
        </Button>
      </div>{" "}
      <section className="job-details-sec mt-0">
        <div
          className="container container-site"
          style={{ position: "relative" }}
        >
          <div className="space-inner-row">
            <div className="j-compny-btn">
              <div className="j-compny-btn-sub ">
                <div className="j-company-logo">
                  <img src={jobDesDetail?.company?.logo ? jobDesDetail?.company?.logo: "/image/avtar-company.png" } alt="" />
                </div>
                <div className="j-company-dev j-company-dev-mbl">
                  <h1 className="company-job-title">
                    {" "}
                    {jobDesDetail?.full_title ? jobDesDetail?.full_title : jobDesDetail?.name}
                  </h1>
                  <p className="j-sub-title">
                    {" "}
                    <a
                      onClick={() =>
                        onDetailCompanyHandler(jobDesDetail?.company?.hashed_id)
                      }
                    >
                      {jobDesDetail?.company && jobDesDetail?.company?.name}
                    </a>
                  </p>
                  {jobDesDetail?.companySize?.length  > 0 && (
                    <div className="icon-text-job-requre">
                      <img src="/image/company-eployee.png" alt="" />
                      <p className="j-c-salary">
                        {" "}
                        {jobDesDetail?.companySize?.[0]?.title ? `Employees ${jobDesDetail.companySize[0].title}` : ''}
                      </p>
                    </div>
                  )}
                  {jobDesDetail?.salary_from && (
                    <div className="icon-text-job-requre">
                      <img src="/image/j-salary.png" alt="" />
                      <p className="j-c-salary">
                        {" "}
                        {jobDesDetail?.salary_from &&
                          jobDesDetail?.salary_from !== null &&
                          "Salary"}
                        {jobDesDetail?.salary_from &&
                          jobDesDetail?.salary_from !== null
                          ? jobDesDetail?.currency[0]?.title
                            ? ` ${jobDesDetail?.currency[0]?.title}`
                            : jobDesDetail?.currency?.title
                              ? jobDesDetail?.currency?.title
                              : jobDesDetail?.currency?.name
                                ? jobDesDetail?.currency?.name
                                : ""
                          : ""}
                        {jobDesDetail?.salary_from &&
                          ` ${jobDesDetail?.currency[0]?.title
                            ? formatSalary(
                              jobDesDetail?.salary_from,
                              jobDesDetail?.currency[0]?.title,
                              "",
                              false
                            )
                            : jobDesDetail?.currency?.title
                              ? formatSalary(
                                jobDesDetail?.salary_from,
                                jobDesDetail?.currency?.title,
                                "",
                                false
                              )
                              : formatSalary(
                                jobDesDetail?.salary_from,
                                jobDesDetail?.currency?.name,
                                "",
                                false
                              )
                          }`}{" "}
                        {jobDesDetail?.salary_to &&
                          ` - ${jobDesDetail?.currency[0]?.title
                            ? formatSalary(
                              jobDesDetail?.salary_to,
                              jobDesDetail?.currency[0]?.title,
                              jobDesDetail?.salary_range,
                              true
                            )
                            : jobDesDetail?.currency?.title
                              ? formatSalary(
                                jobDesDetail?.salary_to,
                                jobDesDetail?.currency?.title,
                                jobDesDetail?.salary_range,
                                true
                              )
                              : (jobDesDetail?.salary_to,
                                jobDesDetail?.currency?.title,
                                jobDesDetail?.salary_range,
                                true)
                          }`}
                        {/* <span className="time-p mbl-time-p"> per {jobDesDetail?.salary_range}</span> */}
                      </p>
                    </div>
                  )}

                  <div className="icon-text-job-requre">
                    <img src="/image/j-timing.png" alt="" />
                    <div className="job-find-list">
                      {jobDesDetail?.jobType?.length > 0 && (
                        <p className="j-c-salary">
                          {" "}
                          {jobDesDetail?.jobType &&
                            jobDesDetail?.jobType[0]?.name}{" "}
                        </p>
                      )}
                      <ul>
                        <li className="days-p">
                          {" "}
                          {jobDesDetail?.date
                            ? getDays(jobDesDetail?.date) > 1
                              ? getDays(jobDesDetail?.date) + " Days ago"
                              : getDays(jobDesDetail?.date) > 0
                                ? getDays(jobDesDetail?.date) + " Day ago"
                                : "Today"
                            : "21 Days ago"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="j-company-btn-apply j-company-btn-apply-mbl">
                {isEmailVerify && ADMIN.includes(user?.user_type) ? (
                  ""
                ) : (
                  
                    jobDesDetail?.is_applied !== 1 && (<div className="wish-cirle" style={{ marginLeft: "auto" }}>
                      <AddToWishlist
                        jobId={jobDesDetail?.id}
                        wishlist={jobDesDetail?.whishlist}
                        withoutTxt={true}
                      />
                    </div>)
                  
                  
                )}

                {isEmailVerify && ADMIN.includes(user?.user_type) ||
                  isEmailVerify && userCountry?.id !== jobDesDetail?.country?.id ? (
                  <></>
                ) : (
                  <div
                    className="apply-btn"
                    onClick={() => applyNowHandler()}
                    style={{ marginTop: "10px" }}
                  >
                    {/* <img className="apply-icon" alt="apply" src="/image/apply.png" /> */}
                    <p className="apply-head">
                      {jobDesDetail?.is_applied === 1
                        ? "Applied"
                        : toggleApplied
                          ? "Applied"
                          : "Apply now"}
                    </p>
                  </div>
                )}

                {hideQuesationBtn ? (
                  <></>
                ) : (
                  qaStatus === true && (
                    <button
                      className="apply-j-btn "
                      onClick={() => QuesationHandler()}
                    >
                      <img src="/image/interview-q.png" alt="" />
                      Sample interview questions
                    </button>
                  )
                )}

                {/* {!hideQuesationBtn ? (
                  <></>
                ) : (
                  <button
                    className={`apply-j-btn ${hideQuesationBtn ? "" : "apply-j-btn-mbl"
                      } `}
                    onClick={() => onDetailJobHandler()}
                  >
                    View Detailed Job Position
                  </button>
                )} */}

                {/* {isEmailVerify && ADMIN.includes(user?.user_type) ? (
                  <>
                   
                  </>
                ) : (
                  <AddToWishlist
                    jobId={jobDesDetail?.id}
                    wishlist={jobDesDetail?.whishlist}
                    withParentButton={true}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>

      
      {toggleEmptyResumesModal && (
        <ActionModal
          heading="Please upload/create a resume to apply."
          content=""
          closeModal={() => setToggleEmptyResumesModal(false)}
          modalArt='/image/warning-wishlist.png'
        ></ActionModal>
      )}

      {showApplyJobModal &&
        <ApplyJobSteps
          setSubmitData={(data) => {
            setSubmitData(data);
          }}
          submitData={submitData}
          handleSubmit={handleSubmit}
          applyingToJob={applyingToJob}
          show={showApplyJobModal}
          onHide={() => setShowApplyJobModal(false)}
        />
      }
    </div>
  );
};

export default JobHead;

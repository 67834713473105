import React, { useEffect, useState } from 'react'
import { Button, Form } from "react-bootstrap";
import APIPvtDB from "../../../../apiPvtD";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import ActionModal from '../../../Common/ActionModal/ActionModal';

const MessageChat = ({ loggedInID, commentDet, getCommentsDetail, pinnedLength, isRepling }) => {
  const [isReplied, setIsReplied] = useState(false)
  const [showMenu, setShowMenu] = useState(false);

  const [edit, setEdit] = useState(false);
  const [deleteChat, setDeleteChat] = useState(false);
  const [deleteChatData, setDeleteChatData] = useState(null);
  const [inputValue, setInputValue] = useState(false);
  useEffect(() => {
    if (commentDet?.user_id === loggedInID) {
      setIsReplied(true)
    }
  }, [commentDet])

  const pinComment = async (data) => {
    if (pinnedLength < 4 || data?.is_pinned === 1) {
      try {
        // const formData = new FormData();
        let formData = {
          "id": data?.id,
          "type": 1,
        }

        if (data?.type === 'reply') {
          formData.type = 2
        }
        await APIPvtDB.post(`/pin-unpin-comment-or-reply`, formData)
          .then((response) => {
            if (response?.data?.success) {
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error('A maximum of four messages can be pinned', {
        autoClose: 2000,
        closeOnClick: false,
      });
    }
  }

  const handleInputChange = (event) => {
    setInputValue(event?.target?.value);
  };

  const updateComment = async (data) => {
    if (data.type === 'reply') {
      try {
        const formData = {
          "reply_id": data?.id,
          "comment": inputValue
        }
        await APIPvtDB.post(`/update-reply`, formData)
          .then((response) => {
            if (response?.data?.success) {
              setInputValue('')
              setEdit(false)
              showSubMenu()
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const formData = {
          "comment_id": data?.id,
          "comments": inputValue
        }
        await APIPvtDB.post(`/update-candidate-comment`, formData)
          .then((response) => {
            if (response?.data?.result) {
              setInputValue('')
              setEdit(false)
              showSubMenu()
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  const dateTime = (data) => {
    const date = new Date(data);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  }

  const replyMsg = (data) => {
    isRepling(data)
  }

  const showSubMenu = () => {
    setShowMenu(!showMenu)
  };

  const editComment = (data) => {
    setEdit(true)
    setInputValue(data.comments)
  }

  const dialogDelete = (data) => {
    closeModal()
    setDeleteChatData(data)
  }
  const closeModal = () => {
    setDeleteChat(!deleteChat)
  }

  const deleteComment = async () => {
    showSubMenu()
    if (deleteChatData) {
      if (deleteChatData?.type !== "comment") {
        try {
          await APIPvtDB.delete(`/delete-reply/${deleteChatData?.id}`)
            .then((response) => {
              if (response?.data?.success) {
                closeModal()
                setDeleteChatData(null)
                setShowMenu(false);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const formData = {
            "comment_id": deleteChatData?.comment_id,
            "candidate_id": deleteChatData?.user_id,
            "object_type": 'job',
            "object_id": deleteChatData?.id
          }
          await APIPvtDB.post(`/remove-comment`, formData)
            .then((response) => {
              if (response?.data?.result) {
                closeModal()
                setDeleteChatData(null)
                setShowMenu(false);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }

    }
  }

  return (
    <div className={`single-chat ${isReplied ? 'replied' : ''}`}>
      <div className='chit-chat'>
        {!edit ? (
          <>
            <div className='pin-chat'>
              <div className='dd-listitem'>
                {
                  isReplied && (
                    <>
                      <button className="btn p-0" onClick={showSubMenu}>
                        <img src="/image/ellipses.png" alt="éllipses" />
                      </button>
                      <div className={showMenu ? "cv-cions active" : "cv-cions"}>
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setShowMenu(false);
                          }}
                        >
                          <ul>
                            <li onClick={() => editComment(commentDet)}>Edit</li>
                            <li onClick={() => dialogDelete(commentDet)}>Delete</li>
                          </ul>
                        </OutsideClickHandler>
                      </div>
                    </>
                  )
                }

              </div>
              <img src={commentDet?.is_pinned ? '/image/chat-pinned.png' : '/image/chat-pin.png'} className='cursor-pointer' onClick={() => pinComment(commentDet)} />
            </div>
            <div className='avatar'>
              <img src={commentDet?.user?.avatar_link || '/image/client-img.png'} />
            </div>
            <div className='chit-box'>
              {
                commentDet?.type === 'reply' && (
                  <div className={`reply-msg-sec mb-2 ${commentDet?.type === 'reply' && !isReplied ? 'mt-4' : ''}`}>
                    <div className='content-sec'>
                      <h4>{commentDet?.first_name} {commentDet?.last_name}</h4>
                      <p>{commentDet?.comment_text}</p>
                    </div>
                  </div>
                )
              }
              {!isReplied && (<h5>{commentDet?.user?.first_name} {commentDet?.user?.last_name}</h5>)}
              <p>{commentDet?.comments} </p>
              {
                !isReplied && (
                  <p className='reply' onClick={() => replyMsg(commentDet)}>
                    <img src='/image/reply-msg.png' />
                    reply
                  </p>
                )
              }
            </div>
          </>
        ) : <>
          <Form
            className="d-flex justify-content-between w-100 flex-column bg-white p-3"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group
              className="w-100"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                className="w-100 note-edit-input"
                placeholder="Type your note here..."
                size="lg"
                value={inputValue}
                onChange={handleInputChange}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            <div className="d-flex mt-3 justify-content-end">
              <Button
                className="me-3 px-3 py-2 edit-btn"
                variant="dark"
                onClick={() => { setEdit(false); showSubMenu() }}
              >
                Cancel
              </Button>
              <Button
                className="border-muted px-3 py-2 edit-btn"
                variant="outline-dark"
                onClick={() => updateComment(commentDet)}
              >
                Save
              </Button>
            </div>
          </Form>
        </>}
      </div>
      <p>{dateTime(commentDet?.created_at)}</p>
      {
        deleteChat && (
          <ActionModal
            closeModal={closeModal}
            modalArt='/image/warning-wishlist.png'
            heading='Double-Check'
            content='Are you sure you want to delete?'>
            <button className="btn btn-black" onClick={() => closeModal()}>No, keep it.</button>
            <button className="btn btn-outline-black" onClick={() => deleteComment()}>Yes, delete it.</button>
          </ActionModal>
        )
      }
    </div>
  )
}

export default MessageChat
import React, { useEffect, useState } from 'react'
import './index.css'
import StagesWizard from './stagesWizard';
import { useSelector } from 'react-redux';
import API from '../../../../api';

const InterviewStages = () => {
  const [intSteps, setIntSteps] = useState([])
    const { user } = useSelector((state) => state.auth);

  useEffect(()=>{
    getInterviewSteps();
  },[])


  const getInterviewSteps = async () => {
    try {
      await API.get(`/job-board/get-interview-steps?user_id=${user?.id}`).then((res) => {
        if (res?.data.result) {
          let updatedResult = res?.data.data.map(item => ({
            ...item, id: item.id.toString()
          }))
          setIntSteps(updatedResult)
        }
      })
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <div className="page-space bg-clr-admin">
        <div className="page-title-heading mb-4">
          <h2>Create interview stages</h2>
        </div>
        <StagesWizard data={intSteps} invokeGetListing={getInterviewSteps} isAdmin={true} />
      </div>
    </>
  )
}

export default InterviewStages
import { PARSING_STATUS } from "../constants";

export const getProgressBarWidth = (status) => {
  switch (status) {
    case PARSING_STATUS.PENDING:
    case PARSING_STATUS.PROCESS:
      return "60%";
    case PARSING_STATUS.COMPLETED:
    case PARSING_STATUS.COMPLETE:
    case PARSING_STATUS.SUCCESS:
      return "100%";
    case PARSING_STATUS.FAILED:
      return "0%";
    default:
      return "0%";
  }
};

export const getProgressBarColorClass = (status) => {
  switch (status) {
    case PARSING_STATUS.PENDING:
    case PARSING_STATUS.PROCESS:
      return "bg-inter";
    case PARSING_STATUS.COMPLETED:
    case PARSING_STATUS.COMPLETE:
    case PARSING_STATUS.SUCCESS:
      return "bg-ad";
    case PARSING_STATUS.FAILED:
      return "bg-lower";
    default:
      return "bg-lower";
  }
};

export const getProgressTxtColor = (status) => {
  switch (status) {
    case PARSING_STATUS.PENDING:
    case PARSING_STATUS.PROCESS:
      return "color-inter";
    case PARSING_STATUS.COMPLETED:
    case PARSING_STATUS.COMPLETE:
    case PARSING_STATUS.SUCCESS:
      return "color-ad";
    case PARSING_STATUS.FAILED:
      return "color-lower";
    default:
      return "color-lower";
  }
};

export const getColor = (value) => {
  if (value >= 70) {
    return "#0AB84D";
  } else if (value >= 40 && value < 70) {
    return "#FFA033";
  } else {
    return "#E15C52";
  }
};
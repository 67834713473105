import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "../../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../../components/Common/Admin/ShowRows/ShowRows";
import TableError from "../../../../components/Common/Admin/TabelError/TableError";
import {
  getBulkResumeReqDetailDataAsync,
  setResumeCandidateId,
  setResumeCandidateTab,
} from "../../../../redux/slices/bulkResumeCanDetailSlice";
import "./index.css";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import {
  getProgressBarColorClass,
  getProgressBarWidth,
  getProgressTxtColor,
} from "../../../../utils/progressbarFn";
import {
  BULK_REQ_DETAIL_SORT,
  EmptyRecord,
  SORT_DIRECTION,
} from "../../../../constants";

const BulkResumeReqDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { processId } = useSelector((state) => state.bulkResumeCanDetail);
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState("");
  const [processDetails, setProcessDetails] = useState({});
  const [bulkResumeData, setBulkResumeData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [status, setStatus] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navHandler = () => {
    navigate("/bulk-resume-analysis");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onStatusClean = () => {
    setSelectedStatus({
      label: "",
      value: null,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  };

  const onSortByHandler = (value) => {
    if (sortBy === value) {
      setSortDirection((prevDirection) =>
        prevDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC
      );
    } else {
      setSortDirection(SORT_DIRECTION.DESC);
    }
    setSortBy(value);
  };

  /*****************************/

  const getReqDetailData = async () => {
    if (processId !== null) {
      try {
        dispatch(
          getBulkResumeReqDetailDataAsync({
            process_id: processId,
            search: search,
            status: selectedStatus?.value,
            currentPage: currentPage,
            perPage: perPage,
            sortBy: sortBy,
            sortDirection: sortDirection,
          })
        )
          .then(async (response) => {
            const res = response?.payload;

            if (res?.success === true) {
              const { process_details, resumes, dropdowns, last_page } =
                res?.data;

              setProcessDetails(process_details);
              setBulkResumeData(resumes);
              setStatus(dropdowns?.status_data);
              setTotalPages(last_page);

              if (
                resumes?.length === 0 &&
                search === "" &&
                selectedStatus?.value === null
              )
                setMsg("Polycareers AI is processing your request.");
              else if (
                resumes?.length === 0 &&
                (search !== "" || selectedStatus?.value !== null)
              )
                setMsg("Please make another selection. No record found.");
              else {
                if (resumes?.length !== 0) setMsg("");
              }
            }

            setLoader(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getReqDetailData();
  }, [selectedStatus, search, currentPage, perPage, sortBy, sortDirection]);

  useEffect(() => {
    setLoader(true);
    getReqDetailData();
    setLoader(false);
  }, []);

  return (
    <div classNameName="bg-clr-admin">
      <div className="page-space bg-clr-admin">
        <div className="form-heading-prom">
          <img
            src="/image/form-back-arrow.png"
            alt=""
            onClick={() => navHandler()}
          />
          <h2>Request detail</h2>
        </div>

        {loader ? (
          <div className="loader-div">
            <span
              className="spinner-border spinner-border"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <>
            <div className="two-card-admin-request">
              <div className="card-left-request">
                <div className="first-row-request-bulk">
                  <div className="row-list-request">
                    <div className="top-part-img-row">
                      <img src="/image/job-title-admin.png" alt="" />
                      <p className="name-image-left">File name: </p>
                    </div>
                    <div className="right-content-request-admin">
                      {processDetails?.file_name
                        ? processDetails?.file_name
                        : ""}
                    </div>
                  </div>
                  <div className="row-list-request">
                    <div className="top-part-img-row">
                      <img src="/image/file-name-admin.png" alt="" />
                      <p className="name-image-left">Job title: </p>
                    </div>
                    <div className="right-content-request-admin">
                      {processDetails?.job_title
                        ? processDetails?.job_title
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="seacond-row-request-bulk">
                  <div className="row-list-request">
                    <div className="left-part-img-ad">
                      <img src="/image/resume-bulk-admin.png" alt="" />
                      <p className="name-image-left-below">
                        Number of resumes:{" "}
                      </p>
                    </div>
                    <div className="right-content-request-admin">
                      {processDetails?.no_of_resumes
                        ? processDetails?.no_of_resumes
                        : "0"}
                    </div>
                  </div>
                  <div className="row-list-request">
                    <div className="left-part-img-ad">
                      <img src="/image/Process-time.png" alt="" />
                      <p className="name-image-left-below">
                        Estimated Process Time:{" "}
                      </p>
                    </div>
                    <div className="right-content-request-admin">
                      {processDetails?.estimated_process_time_min
                        ? processDetails?.estimated_process_time_min
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="third-row-request-bulk">
                  <div className="row-list-request">
                    <div className="left-part-img-ad">
                      <img src="/image/resume-bulk-admin.png" alt="" />
                      <p className="name-image-left-below">
                        Resumes completed:{" "}
                      </p>
                    </div>
                    <div className="right-content-request-admin color-ad">
                      {processDetails?.completed_resumes
                        ? processDetails?.completed_resumes
                        : "0"}
                    </div>
                  </div>
                  <div className="row-list-request">
                    <div className="left-part-img-ad">
                      <img src="/image/Process-time.png" alt="" />
                      <p className="name-image-left-below">
                        Process start time:{" "}
                      </p>
                    </div>
                    <div className="right-content-request-admin">
                      {processDetails?.process_start_time
                        ? processDetails?.process_start_time
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="third-row-request-bulk">
                  <div className="row-list-request">
                    <div className="left-part-img-ad">
                      <img src="/image/resume-bulk-admin.png" alt="" />
                      <p className="name-image-left-below">
                        Resumes in error:{" "}
                      </p>
                    </div>
                    <div className="right-content-request-admin  color-lower">
                      {processDetails?.error_resumes
                        ? processDetails?.error_resumes
                        : "0"}
                    </div>
                  </div>
                  <div className="row-list-request">
                    <div className="left-part-img-ad">
                      <img src="/image/Process-time.png" alt="" />
                      <p className="name-image-left-below">
                        Process end time:{" "}
                      </p>
                    </div>
                    <div className="right-content-request-admin">
                      {processDetails?.process_ended_at
                        ? processDetails?.process_ended_at
                        : ""}
                    </div>
                  </div>

                  <div className="row-list-request">
                    <div className="left-part-img-ad">
                      <img src="/image/Process-status.png" alt="" />
                      <p className="name-image-left-below">Process status: </p>
                    </div>
                    <div
                      className={`name-image-left-below-w ${getProgressTxtColor(
                        processDetails?.process_status?.toLowerCase()
                      )}`}
                    >
                      {processDetails?.process_status
                        ? processDetails?.process_status
                        : ""}
                    </div>
                  </div>

                  <div className="row-list-request">
                    <div className="left-part-img-ad">
                      <img src="/image/Process-time.png" alt="" />
                      <p className="name-image-left-below">
                        Total Process time:{" "}
                      </p>
                    </div>
                    <div className="right-content-request-admin">
                      {processDetails?.total_process_minutes
                        ? processDetails?.total_process_minutes
                        : ""}
                    </div>
                  </div>
                  <div className="row-list-request">
                      <div className="left-part-img-ad">
                        <img src="/image/date-admin.png" alt="" />
                        <p className="name-image-left-below">Date created:{" "} </p>
                      </div>
                      <div className="right-content-request-admin">                    
                        {processDetails?.date_created ? getCurrentFormattedDate(processDetails?.date_created) : "" }
                      </div>
                    </div>  
                </div>
              </div>
            </div>
            <div className="filter-search-bar">
              <div className="two-drop-role">
                <div className="col-left-adminfilter">
                  <p className="filter-label-admin">Resume status</p>
                  <div className="admin-status-filter">
                    <div
                      id="dropdown-boxId2"
                      className="company-form-dropdownBox1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-filter-admin">
                        <p
                          className={` ${
                            selectedStatus?.label !== "" && "text-filter-select"
                          }`}
                        >
                          {" "}
                          {selectedStatus?.label === ""
                            ? "Status"
                            : selectedStatus?.label}
                        </p>
                        {selectedStatus?.label === "" ? (
                          <img src="/image/admin-d.png" alt="" />
                        ) : (
                          <div
                            id="dropdown-boxId2"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src="/image/cross-port.png"
                              alt=""
                              onClick={() => onStatusClean()}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {status?.length > 0 && (
                      <div className="promotion-status-dropdown">
                        <ul className="list-job-title-company">
                          {status?.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                onStatusClick(item?.label, item?.value);
                              }}
                            >
                              {item?.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-right-searcgadmin">
                <div className="search-admin">
                  <img src="/image/job-search.png" alt="" />
                  <input
                    type="text"
                    name="search"
                    id="jon-search-box"
                    placeholder="Search"
                    className="form-font-f"
                    onChange={(e) => {
                      onSearchChange(e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <ShowRows
              perPage={perPage}
              onChangePerPageHandler={onChangePerPageHandler}
            />

            <div className="department-table-imge">
              <div className="col-user-table">
                <div className="table-responsive">
                  <table className="depart-table-left">
                    <thead className="tb-heading-user">
                      <tr>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Resume filename
                          </div>
                        </th>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Candidate name{" "}
                            <img
                              className="arrow-icon icon-cursor"
                              src={
                                sortBy ===
                                  BULK_REQ_DETAIL_SORT.CANDIDATE_NAME &&
                                sortDirection === SORT_DIRECTION.DESC
                                  ? "/image/bars-arrow-down.png"
                                  : "/image/bars-arrow-up.png"
                              }
                              alt=""
                              onClick={() =>
                                onSortByHandler(
                                  BULK_REQ_DETAIL_SORT.CANDIDATE_NAME
                                )
                              }
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Resume status{" "}
                            <img
                              className="arrow-icon icon-cursor"
                              src={
                                sortBy ===
                                  BULK_REQ_DETAIL_SORT.PROCESS_STATUS &&
                                sortDirection === SORT_DIRECTION.DESC
                                  ? "/image/bars-arrow-down.png"
                                  : "/image/bars-arrow-up.png"
                              }
                              alt=""
                              onClick={() =>
                                onSortByHandler(
                                  BULK_REQ_DETAIL_SORT.PROCESS_STATUS
                                )
                              }
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Date processed{" "}
                            <img
                              className="arrow-icon icon-cursor"
                              src={
                                sortBy ===
                                  BULK_REQ_DETAIL_SORT.DATE_PROCESSED &&
                                sortDirection === SORT_DIRECTION.DESC
                                  ? "/image/bars-arrow-down.png"
                                  : "/image/bars-arrow-up.png"
                              }
                              alt=""
                              onClick={() =>
                                onSortByHandler(
                                  BULK_REQ_DETAIL_SORT.DATE_PROCESSED
                                )
                              }
                            />
                          </div>
                        </th>

                        <th scope="col" className="th-size-seven">
                          <div className="th-admin-heading">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tb-user">
                      {bulkResumeData?.length > 0 &&
                        bulkResumeData?.map((item, index) => (
                          <tr>
                            <td className="">{item?.resume_filename}</td>
                            <td>
                              {EmptyRecord.includes(item?.candidate_name) ? (
                                <div className="dash-td-por" />
                              ) : (
                                item?.candidate_name
                              )}
                            </td>

                            <td>
                              {item?.process_status !== null &&
                                item?.process_status}
                              <div className="progress">
                                <div
                                  className={`progress-bar progress-bar-color ${getProgressBarColorClass(
                                    item?.process_status?.toLowerCase()
                                  )}`}
                                  role="progressbar"
                                  style={{
                                    width: getProgressBarWidth(
                                      item?.process_status?.toLowerCase()
                                    ),
                                  }}
                                  aria-valuenow={parseInt(
                                    getProgressBarWidth(
                                      item?.process_status?.toLowerCase()
                                    )
                                  )}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td>
                            <td>
                              {getCurrentFormattedDate(item?.date_processed)}
                            </td>
                            <td>
                              <div className="four-resume-img">
                                <div className="img-text-tooltip">
                                  <img
                                    src="/image/resume-bulk-admin.png"
                                    alt=""
                                    className="show-tooltip icon-cursor"
                                    onClick={() => {
                                      if (item?.candidate_id !== null) {
                                        dispatch(
                                          setResumeCandidateId(
                                            item?.candidate_id
                                          )
                                        );
                                        dispatch(
                                          setResumeCandidateTab("resume")
                                        );
                                        navigate("/bulk-resume-ai-analysis");
                                      }
                                    }}
                                  />
                                  <div className="tooltip-jd-resume">
                                    Resume
                                  </div>
                                </div>
                                <div className="img-text-tooltip">
                                  <img
                                    src="/image/ai-admin.png"
                                    alt=""
                                    className="show-tooltip2 icon-cursor"
                                    onClick={() => {
                                      if (item?.candidate_id !== null) {
                                        dispatch(
                                          setResumeCandidateId(
                                            item?.candidate_id
                                          )
                                        );
                                        dispatch(
                                          setResumeCandidateTab(
                                            "candidate-evaluation"
                                          )
                                        );
                                        navigate("/bulk-resume-ai-analysis");
                                      }
                                    }}
                                  />
                                  <div className="tooltip-request-analysis-res">
                                    AI analysis
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {bulkResumeData?.length > 0 && (
                  <TablePagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                )}
                <TableError msg={msg} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BulkResumeReqDetail;

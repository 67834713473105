import React, { useEffect, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';

const ProfileSection = ({ sectionType, editMethod, description, heading, tooltip, defaultText }) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(description);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const sectionEdit = (type, description) => {
    setEditable(!editable)
    editMethod(type, value)
  }

  useEffect(() => {
    if (description)
      setValue(description);
  }, [description])

  return (
    <div className='profile-edit-body'>
      {(editMethod && !editable) && (
        <button className="btn btn-transparent edit-btn-profile" onClick={() => setEditable(!editable)}>
          <img src="/image/pen.png" alt="" />
        </button>
      )}
      <h3>{heading}
        {tooltip && <OverlayTrigger
          placement='top'
          overlay={
            // delay={{ show: 250, hide: 4000000 }}
            <Tooltip id='tooltip-top' className="large-tooltip">
              {tooltip}
            </Tooltip>
          }
        >
          <img src='/image/info.png' alt='info' className='ms-1' />
        </OverlayTrigger>}
      </h3>
      {
        editable ? (
          <>
            <Form.Group>
              <Form.Control as="textarea" rows={3} value={value} onChange={handleChange} maxLength={350} />
              <p className='text-end'>{value ? value.length : '0'}/350</p>
            </Form.Group>
            <hr />
            <div className='edit-footer'>
              <button className="btn btn-outline-black btn-ai d-none">
                ✨ Rewrite with AI</button>
              <div className='action-edit'>
                <button className="btn btn-black" onClick={() => setEditable(!editable)}>Cancel</button>
                <button className="btn btn-outline-black" onClick={() => sectionEdit(sectionType)}>Save</button>
              </div>
            </div>
          </>
        ) : (
          <p className={!value ? 'opacity-70' : ''}>{value ? value : defaultText}</p>
        )
      }
    </div >
  )
}

export default ProfileSection
import React, { useState } from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom';
import GuageChart from './GuageChart';

const PreScreeningResult = () => {
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState("");
  const data = {
    labels: ['Wrong', 'Correct'],
    datasets: [
      {
        label: 'Overall Result',
        data: [20, 80], // 65% filled, 35% empty
        borderWidth: 5,
        backgroundColor: ['#ECECEC', '#009FB5'], // Progress bar colors
        cutout: '75%', // This creates the gauge/doughnut shape
        circumference: 240, // Makes it a half-circle
        rotation: -120, // Starts at the top
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        display: function (context) {
          return context.dataset.data[context.dataIndex] > 15;
        },
        font: {
          weight: "600",
          size: 28,
        },
      },
      legend: {
        display: false,
        position: "bottom",
        align: "center",
        labels: {
          padding: 30,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 20,
          font: {
            size: 13,
            weight: 200,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      
    },
    rotation: -90, // Start angle for the chart (half-circle)
    circumference: 180, // Makes it a gauge (half-circle)
  };
  return (
    <>
      <div className="page-space bg-clr-admin">
        <div className="page-title-heading align-items-center">
          <div className='flex-start-center'>
            <img src='/image/form-back-arrow.png' className='me-2 cursor-pointer' onClick={() => navigate(-1)} />
            <h2 className='m-0'>Pre-screening questions</h2>
          </div>
        </div>
        <div className='job-position mt-4'>
          <img src="/image/bag-icon.png" alt='img' />
          {/* <h3 className='m-0'>{jobTitle}</h3> */}
          <h3 className='m-0'>title</h3>
        </div>
        <div className='drag-sec'>
          <div className='row m-0'>
            <div className='col-lg-3 col-md-4'>
              <div className='card'>
                <div className='card-body position-relative'>
                  <div className='text-center mb-3'>
                    <h3 className='m-0 fw-700'>Overall result</h3>
                  </div>
                  <GuageChart data={data} options={options} />
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#009FB5',
                          borderRadius: '50%',
                          marginRight: '5px',
                        }}
                      ></div>
                      <p style={{fontWeight: 700,
                          color: '#767676',}}>Correct <span style={{color: '#000'}}>4</span></p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#E15C52',
                          borderRadius: '50%',
                          marginRight: '5px',
                        }}
                      ></div>
                      <p style={{fontWeight: 700,
                          color: '#767676',}}>Wrong <span style={{color: '#000'}}>1</span></p>
                    </div>
                  </div>
                  <div className='test-percent'>
                    <img src='/image/testResult.png' alt='testResult' />
                    <p>80%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-9 col-md-8'>
              <div className='card'>
                <div className='card-body'>9/8</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreScreeningResult
import React, { useState, Fragment, useEffect, useRef } from 'react'
import OutsideClickHandler from "react-outside-click-handler";
import MessageChat from './MessageChat';
import * as Yup from "yup";
import ContentModal from "../../../../components/Common/ContentModal/ContentModal";
import useAblyHook from '../../../../utils/useAblyHook';
import PinnedChat from './PinnedChat';
import API from "../../../../api";
import APIPvtDB from "../../../../apiPvtD";
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import setDateTime from '../../../../utils/setDateTime';
import "./CanDetailMessages.css";
import InvitePeopleModal from '../../../Common/InvitePeopleModal/InvitePeopleModal';


const CanDetailMessages = ({ jobDetailData, candidateData, candidateDetailData }) => {

  // Use the custom hook for Ably
  const { channel, error } = useAblyHook(
      `team-messaging.${candidateDetailData?.account_id}.${jobDetailData?.id}`
  );
  const { channel: jobChannel, error: jobError } = useAblyHook(
    `job-members.${jobDetailData?.id}`
  );

  const [toggleShareListModel, settoggleShareListModel] = useState(false);
  const [pinnedChatToogle, setPinnedChatToogle] = useState(false);
  const [replyMode, setReplyMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [pinnedLength, setPinnedLength] = useState('');
  const [replyData, setReplyData] = useState('');
  const [toggleEmojiPicker, setToggleEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);

  const addRemoveCand = () => {
    settoggleShareListModel(!toggleShareListModel);
  };

  const openPinnedChat = () => {
    setPinnedChatToogle(!pinnedChatToogle);
  };
  const { user } = useSelector((state) => state.auth);

  const [allCompanyUser, setAllCompanyUser] = useState([]);
  const [selectCompanyUser, setSelectCompanyUser] = useState([]);
  const prevCountRef = useRef();
  const [allCompanyUserHide, setCompanyUserHide] = useState(true);
  const [commentsDetail, setCommentsDetail] = useState(null);
  const [commentMsg, setCommentMsg] = useState([]);
  const [userName, setUserName] = useState('');

  const formik = useFormik({
    initialValues: {
      campanyUser: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      campanyUser: Yup.mixed().test(
        "campanyUser",
        "Please select the people to add members list",
        (value) =>
          !!(Array.isArray(selectCompanyUser))
      ),
    }),
    onSubmit: async (data, { resetForm }) => {
      let selectCompanyUsersId = selectCompanyUser?.map((item) => item.id);
      if (selectCompanyUsersId?.length < 1) {
        selectCompanyUsersId = ['']
      }
      AddMemberToMsg(selectCompanyUsersId);
    },
  });

  useEffect(() => {
    const getData = setTimeout(() => {
      try {
        if (userName) {
          const formData = new FormData();
          user !== null && formData.append("my_id", user?.id);
          user !== null && formData.append("company_id", user?.company_id);
          // user !== null && formData.append("my_id", 5);
          // user !== null && formData.append("company_id", 1);
          formData.append("search_word", userName);

          API.post(`/job-board/auto-search-company-user`, formData)
            .then((response) => {
              if (response?.status === 200 || response?.status === 201) {
                let res = response?.data;

                setAllCompanyUser(res?.company_users);

                if (res?.company_users?.length > 0) {               
                  setCompanyUserHide(true);
                }
                else {
                  setCompanyUserHide(false);
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000)

    return () => clearTimeout(getData)
  }, [userName])

  const AddMemberToMsg = async (users) => {
    try {
      // const formData = new FormData();
      const formData = {
        "job_id": jobDetailData?.id,
        "user_ids": users
      }
      await APIPvtDB.post(`/add-members-to-job`, formData)
        .then((response) => {
          if (response?.data?.success) {
            addRemoveCand()
            if (selectCompanyUser?.length < prevCountRef?.current) {
              toast.success('Members removed successfully', {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            if (selectCompanyUser?.length > prevCountRef?.current) {
              toast.success(response?.data?.message, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
          } else {
            addRemoveCand()
            toast.error(response?.data?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  const onCompanyUserCancel = (curentCompanyUser) => {
    if(selectCompanyUser?.some(item => item?.id !== user?.id)){
    setSelectCompanyUser(prevData => prevData?.filter(item => item?.id !== curentCompanyUser?.id))
    }
  };

  const onCompanyUserClick = (curentCompanyUser) => {
      const idExists = selectCompanyUser?.some(item => item?.id === curentCompanyUser?.id);
      if (!idExists) {
        setSelectCompanyUser([...selectCompanyUser, curentCompanyUser]);
    }
  };


  const isCompanyUserFieldValid = () =>
    !!(
      formik.touched["campanyUser"] &&
      formik.errors["campanyUser"] &&
      selectCompanyUser?.length < 1
    );

  const getCompanyUserErrorMessage = () => {
    return (
      isCompanyUserFieldValid() && (
        <p className="error-msg">{formik.errors["campanyUser"]}</p>
      )
    );
  };

  const getCommentsDetail = async () => {
    try {
      // const formData = new FormData();
      const formData = {
        "object_type": 'job',
        "candidate_id": candidateDetailData?.account_id,
        "object_id": jobDetailData.id
      }
      await APIPvtDB.post(`/get-candidate-comments-new`, formData)
        .then((response) => {
          setCommentsDetail(response?.data?.data)
          setCommentMsg(response?.data?.data?.comments)
          setPinnedLength(response?.data?.data?.pinned_comments_and_replies)
          setSelectCompanyUser(response?.data?.data?.members)
          prevCountRef.current = response?.data?.data?.members?.length;
          setLoading(false)
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setLoading(true)
    getCommentsDetail()
  }, [])
  useEffect(() => {

  }, [setCommentMsg])

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const enterMsg = async () => {
    if (!replyMode) {
      try {
        const formData = {
          "user_id": user.id,
          "object_type": 'job',
          "candidate_id": candidateDetailData?.account_id,
          "object_id": jobDetailData?.id,
          "comments": inputValue
        }
        await APIPvtDB.post(`/add-candidate-comment-new`, formData)
          .then((response) => {
            if (response?.data?.result) {
              setInputValue('')
            }
            else {
              toast.error(response?.data?.message, {
                autoClose: 2000,
                closeOnClick: false,
              });
              setInputValue('')
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const formData = {
          "user_id": user?.id,
          "comment_id": replyData?.id,
          "content": inputValue
        }
        await APIPvtDB.post(`/reply-comment`, formData)
          .then((response) => {
            if (response?.data?.result) {
              setInputValue('')
              setReplyMode(!replyMode)
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }


  const commentReply = (data) => {
    setReplyMode(!replyMode)
    setReplyData(data)
  }

  const toggleEmojiPickerHandler = () => {
    setToggleEmojiPicker((current) => !current);
    scrollToBottom();
  };
  const scrollToBottom = () => {
    if (modalRef.current) {
      modalRef.current.scrollTop = modalRef.current.scrollHeight;
    }
  };

  const addEmojiToInput = (e) => {
    setInputValue((current) => current + e.native);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      enterMsg()
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [toggleEmojiPicker, commentMsg]);


useEffect(() => {
  if (jobChannel) {   
    const handleChannelMessage = (message) => {   
      if (message?.data?.data) {
        setSelectCompanyUser(message?.data?.data);
        getCommentsDetail();
       }
    };
    jobChannel.subscribe("members", handleChannelMessage);
    return () => {
      jobChannel.unsubscribe("members", handleChannelMessage);
    };
  }
}, [jobChannel]);


  useEffect(() => {
    if (channel) {   
      const handleChannelMessage = (message) => {
        if (message?.data?.data) {
          if(selectCompanyUser?.some(item => item?.id === user?.id)) {
            setCommentMsg(message?.data?.data);
            setPinnedLength(message?.data?.data?.pinned_comments_and_replies);
          }       
         }
      };

      channel.subscribe("messages", handleChannelMessage);

      return () => {
        channel.unsubscribe("messages", handleChannelMessage);
      };
    }
  }, [channel, selectCompanyUser]);



  return (
    <div className="tab-content ex1-content" id="ex1-content" >
      <div
        className="tab-pane fade show active"
        id="ex1-tabs-1"
        role="tabpanel"
        aria-labelledby="ex1-tab-1"
      >
        <div className="tabs-content-jour">
          <p className="content-heading-jour">Team messages</p>
          <div className='comments-sec'>
            <div className='notes-box'>
              <div className="header-bar-comment">
                <div className="clinet-profile size-w-d">
                  <img src={commentsDetail?.user?.avatar_link ? commentsDetail?.user?.avatar_link : '/image/client-img.png'} alt="" />
                  <div className="clint-info">
                    <p className="name-clinet">{commentsDetail?.user?.first_name} {commentsDetail?.user?.last_name}</p>
                    <p className="clinet-rank">{commentsDetail?.user?.role?.title}</p>
                  </div>
                </div>
                <button className='btn btn-outline-black' onClick={addRemoveCand}>Add/remove <span className='d-md-inline d-none'>members</span></button>
              </div>
              {loading ?
                <div className='chat-model-comment-box'>
                  <div className="loader-div">
                    <span
                      className="spinner-border spinner-border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                </div> : <div className={`chat-model-comment-box ${commentsDetail?.pinned_comments_and_replies?.length ? 'pinned' : ''}`}>
                  <div className='chat-extras-info'>
                    <div className='cand-inque'>
                      <h3>{jobDetailData?.name} <small>({commentsDetail?.members?.length} users)</small> </h3>
                      <ul>
                        {
                          commentsDetail?.members.map((item, index) => {
                            return (
                              <li key={index}>
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    // delay={{ show: 250, hide: 4000000 }}
                                    <Tooltip id='tooltip-top'>
                                      {item?.first_name} {item?.last_name}
                                    </Tooltip>
                                  }
                                >
                                  <img src={item?.avatar_link ? item?.avatar_link : '/image/client-img.png'} />
                                </OverlayTrigger>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                    {
                      commentsDetail?.pinned_comments_and_replies?.length > 0 && (
                        <div className='cand-inque'>
                          <h3 onClick={openPinnedChat}>
                            <button className='btn btn-outline-black me-2 px-md-3 text-center'>
                              <img src='./image/chat-pinned.png' />
                            </button>
                            {commentsDetail?.pinned_comments_and_replies?.length} Pinned
                          </h3>
                          {
                            pinnedChatToogle && (
                              <div className='pin-toogle'>
                                <div className='chat-dd'>
                                  {
                                    commentsDetail?.pinned_comments_and_replies.map((item) => {
                                      return (
                                        <PinnedChat
                                          getCommentsDetail={getCommentsDetail}
                                          data={item}
                                          loggedInID={user?.id} />
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            )}
                        </div>
                      )
                    }
                  </div>
                  <div className={`position-relative chat-summary`} ref={modalRef}>
                    <div className={`all-chat-comment-m ${replyMode ? 'replyMode' : ''}`}>
                      {commentMsg?.map((item, index) => {
                        return (
                          <Fragment>
                            <div className="comment-model-title">
                              <hr />
                              <div className="chat-date-b">
                                <h1>{setDateTime(item?.day)}</h1>
                              </div>
                            </div>
                            <div className='chat-slot'>
                              {item?.comments?.map((itm, ind) => {
                                return (
                                  <MessageChat
                                    commentDet={itm}
                                    loggedInID={user?.id}
                                    getCommentsDetail={getCommentsDetail}
                                    pinnedLength={pinnedLength?.length}
                                    isRepling={commentReply}
                                  />
                                )
                              })}
                            </div>
                          </Fragment>
                        )
                      })}
                    </div>
                  </div>
                </div>}

              <div className="chat-model-footer">
                {
                  replyMode && (
                    <div className='reply-msg-sec'>
                      <div className='content-sec'>
                        <h4>{replyData?.user?.first_name} {replyData?.user?.last_name}</h4>
                        <p>{replyData?.comments}</p>
                      </div>
                      <button className='btn' onClick={() => setReplyMode(!replyMode)}>
                        <img src='./image/close-icon.png' />
                      </button>
                    </div>
                  )
                }
                <div className="chat-comment-f">
                  <div
                    className="emoji-container"
                  // style={{
                  //   display: "flex",
                  //   alignContent: "center",
                  //   justifyContent: "center",
                  // }}
                  >
                    {toggleEmojiPicker === true ? (
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setToggleEmojiPicker(false);
                        }}
                      >
                        <Picker
                          data={data}
                          theme="light"
                          onEmojiSelect={(e) => addEmojiToInput(e)}
                        />
                      </OutsideClickHandler>
                    ) : null}
                  </div>
                  <div className="chat-form">
                    <input
                      type="text"
                      name=""
                      id="comment-f"
                      placeholder="Type here..."
                      onChange={handleInputChange}
                      value={inputValue}
                      onKeyDown={handleKeyPress}
                      autoComplete='off'
                    />
                    <img
                      src="/image/ssmile.png"
                      alt=""
                      onClick={() => toggleEmojiPickerHandler()}
                    />
                  </div>

                  <div className="send-comment icon-cursor">
                    <button onClick={enterMsg} disabled={!inputValue.length}>
                      <img src="/image/send-chat.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        toggleShareListModel && (
          <InvitePeopleModal
          toggleShareListModel={toggleShareListModel}
          closeModal={addRemoveCand}
          formik={formik}
          allCompanyUser={allCompanyUser}
          allCompanyUserHide={allCompanyUserHide}
          onCompanyUserClick={onCompanyUserClick}
          selectCompanyUser={selectCompanyUser}
          getCompanyUserErrorMessage={getCompanyUserErrorMessage}
          onCompanyUserCancel={onCompanyUserCancel}
          userName={userName}
          setUserName={setUserName}
        />
        )
      }
    </div >
  )
}

export default CanDetailMessages
import React from "react";
import Banner from "../../components/Home/Banner/Banner";
import CompanyCard from "../../components/Home/CompanyCard/CompanyCard";
import JobSection from "../../components/Home/JobSection/JobSection";
import HowItWorkSection from "../../components/Home/HowItWorkSection/HowItWorkSection";
import WorldConnect from "../../components/Common/WorldConnect/WorldConnect";
import CookiesSection from "../../components/Home/CookiesSection/CookiesSection";
import LoginModel from "../../components/Common/LoginModel/LoginModel";

const Home = () => {
  return (
    <>
      <Banner />
      <span>
        {" "}
        <CompanyCard />{" "}
      </span>
      <JobSection />
      <div id="how_it_work">
        <HowItWorkSection />
      </div>
      <WorldConnect />
      <CookiesSection />
      <LoginModel />
    </>
  );
};

export default Home;

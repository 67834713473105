import React, { useEffect, useState } from "react";
import ContentModal from "../ContentModal/ContentModal";
import "./InvitePeopleModal.css";

const InvitePeopleModal = ({
  closeModal,
  formik,
  allCompanyUser,
  allCompanyUserHide,
  onCompanyUserClick,
  selectCompanyUser,
  getCompanyUserErrorMessage,
  onCompanyUserCancel,
  userName,
  setUserName,
}) => {
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (userName !== "" && allCompanyUser?.length === 0) {
      setMsg("User not found in the system.");
    } else {
      setMsg(""); // Clear the message otherwise
    }
  }, [allCompanyUser, userName]);

  console.log("msg", allCompanyUser);

  return (
    <ContentModal closeModal={closeModal}>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body">
          <h3 className="mt-0">Add member</h3>
          <div className="crea-list-m text-start w-100">
            <div className="label-field">
              <label>Invite people to access the chat</label>
              <div className={`share-list-feild`}>
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1 custom-tag-input"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin ">
                    <input
                      type="text"
                      className="form-font-list-share"
                      name="campanyUser"
                      autoComplete="off"
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Invite people"
                    />
                    <img
                      src="/image/admin-d.png"
                      alt=""
                      className="hide-tick"
                    />
                  </div>
                </div>
                {allCompanyUser?.length > 0 && (
                  <div
                    className={`admin-status-dropdown-frm ${
                      allCompanyUserHide && "job-dropdown-hide"
                    }`}
                  >
                    <ul className="list-share-drop">
                      {allCompanyUser.map((j, i) => (
                        <li key={i} onClick={() => onCompanyUserClick(j)}>
                          {`${j?.first_name} ${j?.last_name}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {msg !== "" && <p className="error-msg">{msg}</p>}

              {selectCompanyUser?.length > 0 && (
                <div className="tags-share">
                  <ul className="tags-share-list">
                    {selectCompanyUser.map((j, i) => (
                      <li key={i}>
                        {`${j?.first_name} ${j?.last_name}`}
                        <img
                          src="/image/cross.png"
                          alt=""
                          onClick={() => onCompanyUserCancel(j)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn btn-black" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="btn btn-outline-black" type="submit">
            Save
          </button>
        </div>
      </form>
    </ContentModal>
  );
};

export default InvitePeopleModal;

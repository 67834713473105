import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  selectedJobTitileDD: {
    jobTitleId: null,
    jobTitleLabel: "",
  },
};

const canJourneySlice = createSlice({
  name: "canJourney",
  initialState,
  reducers: {
    setCanJourneyDropdown: (state, action) => {
      state.selectedJobTitileDD = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCanJourneyDropdown } = canJourneySlice.actions;

export default canJourneySlice.reducer;

// asynchronous function with createAsyncThunk

export const getCanJourneyDataAsync = createAsyncThunk(
  "cand/markNotifyAsReadData",
  async ({ company_id, job_id, date_applied, search, page, per_page }) => {
    let params = `?`;
    if (page !== null) {
      params += `page=${page}&`;
    }
    if (per_page !== null) {
      params += `per_page=${per_page}&`;
    }

    const formData = new FormData();

    company_id && formData.append("company_id", company_id);
    job_id && formData.append("job_id", job_id);
    date_applied && formData.append("date_applied", date_applied);
    search && formData.append("search", search);

    try {
      const response = await API.post(`/candidate-journey${params}`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

import { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import JobDetailSec from "./JobDetailSec";
import JobDetailRightSec from "./JobDetailRightSec";
import "./JobDescription.css";

const JobDescription = ({ jobDetail, qrCodeData }) => {
  let jobDesDetail = Object.assign({}, jobDetail[0]);

  const [recruiterNotes, setRecruiterNotes] = useState([""]);
  const [mustSkills, setMustSkills] = useState([""]);
  const [niceToHave, setNiceToHave] = useState([""]);
  const [interviewProcess, setInterviewProcess] = useState([""]);

  useEffect(() => {
    let rec = jobDetail[0]?.recruiter_notes;
    let skill = jobDetail[0]?.must_skills;
    let nice = jobDetail[0]?.nice_to_have;
    let interview = jobDetail[0]?.interview_process;
    setRecruiterNotes(rec);
    setMustSkills(skill);
    setNiceToHave(nice);
    setInterviewProcess(interview);
  }, [jobDetail]);

  return (
    <>
      {" "}
      <section className="job-description-sec">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="Job-Opportunities-row" style={{ padding: "0px" }}>
              <div
                className="Job-Opportunities-row-company"
                style={{ padding: "0px" }}
              >
                <div className="job-company-details">
                  {jobDesDetail?.description !== "" && (
                    <div className="job-heading-detail-sec">
                      <h5>Job summary</h5>
                      {ReactHtmlParser(jobDesDetail?.description)}
                    </div>
                  )}

                  {jobDesDetail?.responsibilities?.length > 0 && (
                    <JobDetailSec
                      label="Responsibilities"
                      description={jobDesDetail?.responsibilities}
                    />
                  )}

                  {jobDesDetail?.skills?.length > 0 && (
                    <JobDetailSec
                      label="Qualifications"
                      description={jobDesDetail?.skills}
                    />
                  )}
                  <span className="mbl-mice-sec">
                    {jobDesDetail?.must_skills?.length > 0 && (
                      <JobDetailSec
                        label="Must have skills"
                        description={jobDesDetail?.must_skills}
                      />
                    )}
                  </span>

                  <span className="mbl-mice-sec">
                    {jobDesDetail?.nice_to_have?.length > 0 && (
                      <JobDetailSec
                        label="Nice to have"
                        description={jobDesDetail?.nice_to_have}
                      />
                    )}
                  </span>

                  {jobDesDetail?.benefits?.length > 0 && (
                    <JobDetailSec
                      label="Benefits"
                      description={jobDesDetail?.benefits}
                    />
                  )}

                  {jobDesDetail?.working_hrs?.length > 0 && (
                    <JobDetailSec
                      label="Working hours"
                      description={jobDesDetail?.working_hrs}
                    />
                  )}

                  {jobDesDetail?.recruiter_notes !== "null" && (
                    <div className="recruite-note-box">
                      <h5>Additional insights</h5>
                      <p style={{ whiteSpace: "pre-line" }}>
                        {ReactHtmlParser(jobDesDetail?.recruiter_notes)}
                      </p>
                    </div>
                  )}
                </div>
                <div className="company-job-announcements">
                  <div className="job-announcements-col">
                    <div className="job-company-right">
                      {jobDesDetail?.jobCities?.length > 0 && (
                        <div className="job-location-title">
                          <p className="company-j-info-l">Job location </p>
                          <ul className="job-location-list">
                            {jobDesDetail?.jobCities.map((item, i) => (
                              <li>{item?.name}</li>
                            ))}
                          </ul>
                          <div className="company-right-col"></div>
                        </div>
                      )}                      
                      {jobDesDetail?.reportingPerson?.length > 0 && (
                        <>
                          {" "}
                          <JobDetailRightSec
                            label="Reporting to"
                            value={jobDesDetail?.reportingPerson}
                          />
                          <div className="company-right-col"></div>
                        </>
                      )}

                      {jobDesDetail?.workMethodology?.length > 0 && (
                        <JobDetailRightSec
                          label="Work methodology"
                          value={jobDesDetail?.workMethodology}
                        />
                      )}
                      <span className="nice-desktop-sec">
                        {jobDesDetail?.must_skills?.length > 0 && (
                          <>
                            <div className="company-right-col"></div>
                            <div className="j-com-dev-info">
                              <p className="company-j-info-l">
                                Must have skills
                              </p>
                              <div className="skill-list-j">
                                {ReactHtmlParser(jobDesDetail?.must_skills)}
                              </div>
                            </div>
                          </>
                        )}
                      </span>
                      {jobDesDetail?.tags?.length > 0 && (
                        <>
                          <div className="company-right-col"></div>
                          <div className="job-skills-detail-col">
                            {jobDesDetail?.tags.map((i) => (
                              <button>{i?.name?.trim()}</button>
                            ))}
                          </div>
                        </>
                      )}
                      <span className="nice-desktop-sec">
                        {jobDesDetail?.nice_to_have !== null && jobDesDetail?.nice_to_have?.length > 0 && (
                          <>
                            <div className="company-right-col"></div>
                            <div className="j-com-dev-info">
                              <p className="company-j-info-l">Nice to have</p>
                              <div className="skill-list-j">
                                {ReactHtmlParser(jobDesDetail?.nice_to_have)}
                              </div>
                            </div>
                          </>
                        )}
                      </span>
                      {jobDesDetail?.interview_steps?.length > 0 && (
                        <>
                          <div className="company-right-col"></div>
                          <div className="j-com-dev-info">
                            <p className="company-j-info-l">
                            Interview stages
                            </p>
                            <div className="skill-list-j">
                              <ul className="ints-steps">
                              {jobDesDetail?.interview_steps?.map((val, id) => {
                                return(
                                  <li key={id}>{val?.interview_step_name}</li>
                                )
                              })}
                              </ul>
                            </div>
                          </div>
                        </>
                      )}

                      {qrCodeData !== null && (
                        <>
                          <div className="company-right-col"></div>
                          <div className="j-com-dev-info qr-code-img">
                            <p className="company-j-info-l ">Share this job</p>
                            <img src={qrCodeData} alt="" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobDescription;

import React, { useRef, useEffect } from 'react';
import { Chart, ArcElement, Tooltip, Legend, registerables } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

// Register necessary components from Chart.js
Chart.register(...registerables, ArcElement, Tooltip, Legend);

const GuageChart = ({data, options}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;
    
    chart.update();
  }, []);
  return (
    <>
      <Doughnut ref={chartRef} data={data} options={options} />
    </>
  )
}

export default GuageChart
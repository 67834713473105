export const BASE_API = `https://dev.polycareers.com/backend/public/api/v1`;

export const BASE_API_ADMIN = `https://dev.polycareers.com/backend/public/api/v1/admin/v1`;


export const BASE_API_DYNAMIC = `https://${window.location.host}/backend/public/api/v1`;

export const BASE_API_ADMIN_DYNAMIC = `https://${window.location.host}/backend/public/api/v1/admin/v1`;



export const ABLY_API_KEY_QA = `wlZG-A.wUJYRQ:htmueIX-pvjjOdZNYMurp6GJ4CKo5oxT7ZzNPC0iOZw`;

export const ABLY_API_KEY_DEV = `jRmxaQ.Jg6eiQ:5WBJsOMKuAQ8td7YrkOY3218Zaj3tCNmAk9ioR4xxic`;




// export const BASE_API = `https://demo.workbridgetalent.ca/backend-files/public/api/v1`;

// export const BASE_API_ADMIN = `https://demo.workbridgetalent.ca/backend-files/public/api/v1/admin/v1`;


